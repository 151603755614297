<div class="overlay"></div>


<nav class="navbar navbar-expand-md navbar-light bg-light main-menu" style="box-shadow:none; background: #fffc00 !important;">
    <div class="container">

        <button type="button" id="sidebarCollapse" (click)="testing()" class="btn btn-link d-block d-md-none">
            <i class="fa fa-menu icon-single"></i>
        </button>

        <a class="navbar-brand" href="#">
            <h4 class="font-weight-bold"><img src="../../assets/img/logo.png">
            </h4>
        </a>

        <ul class="navbar-nav ml-auto d-block d-md-none">
            <li class="nav-item">
                <a class="btn btn-link" href="#"><i class="fa fas-cart icon-single" routerLink="/productdetail"
                        routerLinkActive="active"></i> <span class="badge badge-danger">3</span></a>
            </li>
        </ul>

        <div class="collapse navbar-collapse infoctrl">
            <div class="form-inline my-2 my-lg-0 mx-auto infoctrl">
                <input class="form-control infoctrl" id="infoctrl" autocomplete="off" type="search" placeholder="What are you looking for?" name="searchText" (input)="getItems($event)" [(ngModel)]="searchText" (ngModelChange)="onSearchChange($event)" aria-label="Search">
                <!-- <button class="btns my-2 my-sm-0" type="button" (click)="openSearch()"><i class="fa fa-search btnseach"></i></button> -->
                <div class="autocom-box" id="info">
                    <!-- here list are inserted from javascript -->
                    <li *ngFor="let item of items">
                        <div class="search-item" data-prod="pr_inuk3ilznwg" *ngIf="item.category_parent_id!=null" [routerLink]="['/category',item.category_parent_id,item.category_parent ]" (click)="dismiss()">

                            <div class="product-click-container" data-url="/kebabs/purani-dilli-ke-mutton-seekh-kebab-pr_inuk3ilznwg" data-text="Purani Dilli ki Mutton Seekh Kebab" data-position="1">

                                <div class="product-card">
                                    <i class="fa fa-search icnclass"> </i>
                                    <div class="product-info">
                                        <div class="product-name-description">
                                            {{ item.product_name }}
                                        </div>

                                        <div class="weight-price-action">
                                        </div>

                                    </div>
                                    <ion-icon class="icnclasslast" name="arrow-undo-outline"></ion-icon>
                                </div>
                            </div>
                        </div>


                        <div class="search-item" data-prod="pr_inuk3ilznwg" *ngIf="item.category_parent_id==null&&item.category!=null" [routerLink]="[ '/category',item.category,item.category_name ] " (click)="dismiss()">

                            <div class="product-click-container" data-url="/kebabs/purani-dilli-ke-mutton-seekh-kebab-pr_inuk3ilznwg" data-text="Purani Dilli ki Mutton Seekh Kebab" data-position="1">

                                <div class="product-card">
                                    <i class="fa fa-search icnclass"> </i>
                                    <div class="product-info">
                                        <div class="product-name-description">
                                            {{ item.product_name }}
                                        </div>

                                        <div class="weight-price-action">
                                        </div>

                                    </div>
                                    <ion-icon class="icnclasslast" name="arrow-undo-outline"></ion-icon>
                                </div>
                            </div>
                        </div>
                        <div class="search-item" data-prod="pr_inuk3ilznwg" *ngIf="item.category_parent_id==null&&item.category==null" [routerLink]="['/product-details',item.id,item.product_name ]" (click)="dismiss()">

                            <div class="product-click-container" data-url="/kebabs/purani-dilli-ke-mutton-seekh-kebab-pr_inuk3ilznwg" data-text="Purani Dilli ki Mutton Seekh Kebab" data-position="1">

                                <div class="product-card">
                                    <i class="fa fa-search icnclass"> </i>
                                    <div class="product-info">
                                        <div class="product-name-description">
                                            {{ item.product_name }}
                                        </div>

                                        <div class="weight-price-action">
                                        </div>

                                    </div>
                                    <ion-icon class="icnclasslast" name="arrow-undo-outline"></ion-icon>
                                </div>
                            </div>
                        </div>
                    </li>
                    <h4 class="err" *ngIf="items==null">We couldn't find any matches!</h4>
                    <hr>
                    <div *ngIf="first_time&&fill_category!=''">
                        <h6>Explore by Category</h6>
                        <div class="row">
                            <div class="col-md-2" *ngFor="let cat of fill_category">
                                <div *ngIf="cat.category_image!=null" style="text-align: center;">
                                    <a *ngIf="cat.parent==null" [routerLink]="['/category',cat.id,cat.category_name ]">
                                        <img [src]="img_link+cat.category_image" class="search_cat">
                                        <div class="cat_name tpname"><span> {{ cat.category_name }}</span></div>
                                    </a>
                                    <a *ngIf="cat.parent!=null" [routerLink]="['/subCategory',cat.parent,cat.id,cat.category_name ]">
                                        <img [src]="img_link+cat.category_image" class="search_cat">
                                        <div class="cat_name tpname"><span> {{ cat.category_name }}</span></div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ul class="navbar-nav">

                <!-- <li class="nav-item ml-md-3">
                   
                    <div class="jsx-127861163 userWrapper">
                        <div class="jsx-312600705 container" (click)="min()"><a class="jsx-312600705 trigger userText"><span
                                    id="dd_header_signInOrUp" class="jsx-312600705 userText">Sign In or Sign Up</span><i
                                    class="fa fa-caret-down "></i></a>
                            <div class="jsx-312600705 dropdownCtr" *ngIf="show1">
                                <div class="jsx-312600705 signInContainer">
                                    <p id="txt_header_returnCus" class="jsx-312600705">Returning Customer</p><button
                                        id="btn_header_signin" class="jsx-312600705 dropdownBtn" routerLink="/login" routerLinkActive="active">Sign In</button>
                                </div>
                                <div class="jsx-312600705 signUpContainer">
                                    <p class="jsx-312600705">Don't have an account?</p><a
                                        class="jsx-312600705 linkTextSignup" routerLink="/signup" routerLinkActive="active">Sign Up</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </li> -->



                <li class="nav-item ml-md-3">

                    <div class="jsx-127861163 userWrapper">
                        <div class="jsx-312600705 container" *ngIf="!login_status" (click)="min()"><a class="jsx-312600705 trigger userText"><span id="dd_header_signInOrUp"
                                    class="jsx-312600705 userText"><a routerLink="/login" style="color:black">Sign In</a> or <a routerLink="signup" style="color:black">Sign Up</a></span>
                            </a>
                            <div class="jsx-312600705 dropdownCtr" *ngIf="show1">




                            </div>

                        </div>
                        <div class="jsx-312600705 container" *ngIf="login_status" (click)="min()"><a class="jsx-312600705 trigger userText"><span id="dd_header_signInOrUp"
                            class="jsx-312600705 userText">My Account</span><i
                            class="fa fa-caret-down "></i></a>
                            <div class="jsx-312600705 dropdownCtr" *ngIf="show1">


                                <div class="jsx-312600705 userLinksCtr">
                                    <a class="jsx-312600705 link" routerLink="profile/orders"><i class="fa fa-file-text"></i>Orders</a>
                                    <a class="jsx-312600705 link" routerLink="profile/addresses"> <i class="fa fa-book "></i>Addresses</a>
                                    <!-- <button class="jsx-312600705 link"><i class="fa fa-credit-card "></i>Payment</button>
                                    <button class="jsx-312600705 link"><i class="fa fa-undo"></i>Returns</button>
                                    <button class="jsx-312600705 link"><i class="fa fa-exchange"></i>Claims</button>
                                    <button class="jsx-312600705 link"><i class="fa fa-cog "></i>Preferences</button> -->
                                    <a class="jsx-312600705 link" routerLink="profile/profile"><i class="fa fa-user "></i>Profile</a>
                                    <a class="jsx-312600705 link" routerLink="wishlist"><i class="fa fa-heart "></i>Wishlist</a></div>
                                <div class="jsx-312600705 signUpContainer">
                                    <a class="jsx-312600705 linkTextSignup" (click)="logout()">Sign Out</a>
                                </div>

                            </div>

                        </div>




                    </div>
                </li>






                <li class="nav-item">
                    <div class="jsx-127861163 userWrapper">
                        <div class="jsx-312600705 container"><a class="jsx-312600705 trigger"><span
                                    id="dd_header_signInOrUp" class="jsx-312600705 userTexts">|</span></a>

                        </div>
                    </div>
                </li>
                <li class="nav-item">
                    <div class="jsx-127861163 userWrapper">
                        <div class="jsx-312600705 container"><a class="jsx-312600705 trigger userText" routerLink="/cart"><span
                                    id="dd_header_signInOrUp" class="jsx-312600705 userText" 
                                    routerLinkActive="active">Cart</span><i class="fa fa-shopping-cart "></i>
                                    <!-- <i class="cart_count_footer"><span class="count_arg_footer">{{ count }}</span></i> -->
                                    <div class="circle">{{ count }}</div>
                                </a>


                        </div>
                    </div>
                </li>
            </ul>
        </div>

    </div>
</nav>

<nav class="navbar navbar-expand-md navbar-light bg-light sub-menu">
    <div class="container-fluid">
        <div class="collapse navbar-collapse" id="navbar">

            <div class="andro_category-mm">
                <div class="andro_category-mm-header" (click)="toggle()">
                    <h6> ALL Categories <i class="fa fa-caret-down" style="color: black !important;"></i></h6>
                </div>
                <div class="andro_category-mm-body" *ngIf="show">
                    <ul>
                        <li class="andro_category-mm-item andro_category-mm-item-has-children" *ngFor="let cat of category"><a [routerLink]="['/category',cat.id,cat.category_name]"> {{ cat.category_name }} </a>
                            <ul class="andro_category-mm-2-cols">
                                <li>
                                    <div class="andro_category-mm-banner"><img [src]="img_link+cat.category_image" alt="megamenu img">
                                        <div class="andro_category-mm-banner-desc">
                                            <h6>{{ cat.category_name }}</h6>
                                            <p>{{ cat.category_description }}</p>
                                        </div>
                                    </div>
                                    <ul>
                                        <!-- <li *ngFor="let scat of getCat(cat.id)"> <a [routerLink]="['/subCategory',cat.id,scat.id,scat.category_name ]">{{ scat.category_name }}</a>
                                        </li> -->
                                        <!-- <li> <a href="/themes/react/organista/">Dairy</a> </li>
                                        <li> <a href="/themes/react/organista/">Vegan Dairy</a> </li>
                                        <li> <a href="/themes/react/organista/">Meats &amp; Fish</a> </li>
                                        <li> <a href="/themes/react/organista/">Breads &amp; Cereal</a> </li>
                                        <li> <a href="/themes/react/organista/">Honey</a> </li>
                                        <li> <a href="/themes/react/organista/">Jam &amp; Spreads</a> </li>
                                        <li> <a href="/themes/react/organista/">Legumes</a> </li>
                                        <li> <a href="/themes/react/organista/">Oils</a> </li> -->
                                    </ul>
                                    <!-- <ul>
                                        <li> <a href="/themes/react/organista/">Beans</a> </li>
                                        <li> <a href="/themes/react/organista/">Vegan Food</a> </li>
                                        <li> <a href="/themes/react/organista/">Distillates</a> </li>
                                        <li> <a href="/themes/react/organista/">Eggs</a> </li>
                                        <li> <a href="/themes/react/organista/">Snacks</a> </li>
                                        <li> <a href="/themes/react/organista/">Syrups</a> </li>
                                        <li> <a href="/themes/react/organista/">Pickles</a> </li>
                                        <li> <a href="/themes/react/organista/">Wines &amp; Spirit</a> </li>
                                    </ul> -->
                                </li>
                            </ul>
                        </li>
                        <!-- <li class="andro_category-mm-item"> <a href="/themes/react/organista/"> Keto</a> </li>
                        <li class="andro_category-mm-item andro_category-mm-item-has-children"><a href="/themes/react/organista/"> Baskets </a>
                            <ul class="andro_category-mm-2-cols">
                                <li>
                                    <div class="andro_category-mm-banner"><img src="https://k.nooncdn.com/cms/pages/20201020/79a2e38e27583a1a5db92d21097f3b8e/en_mb-books-01.png" alt="megamenu img">
                                        <div class="andro_category-mm-banner-desc">
                                            <h6>Baskets</h6>
                                            <p>Hand made baskets for your organic goods</p>
                                        </div>
                                    </div>
                                    <ul>
                                        <li> <a href="/themes/react/organista/">Vegetables &amp; Fruits</a>
                                        </li>
                                        <li> <a href="/themes/react/organista/">Dairy</a> </li>
                                        <li> <a href="/themes/react/organista/">Vegan Dairy</a> </li>
                                        <li> <a href="/themes/react/organista/">Meats &amp; Fish</a> </li>
                                        <li> <a href="/themes/react/organista/">Breads &amp; Cereal</a> </li>
                                    </ul>
                                    <ul>
                                        <li> <a href="/themes/react/organista/">Honey</a> </li>
                                        <li> <a href="/themes/react/organista/">Jam &amp; Spreads</a> </li>
                                        <li> <a href="/themes/react/organista/">Legumes</a> </li>
                                        <li> <a href="/themes/react/organista/">Oils</a> </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li class="andro_category-mm-item"> <a href="/themes/react/organista/"> Baby &amp; Kids
                                Care</a> </li>
                        <li class="andro_category-mm-item"> <a href="/themes/react/organista/"> Home Care</a>
                        </li>
                        <li class="andro_category-mm-item"> <a href="/themes/react/organista/"> Supplements</a>
                        </li>
                        <li class="andro_category-mm-item"> <a href="/themes/react/organista/"> Beauty Care</a>
                        </li>
                        <li class="andro_category-mm-item andro_category-mm-item-has-children andro_category-mm-item-expand">
                            <a href="/themes/react/organista/"> More Categories</a>
                            <ul class="andro_category-mm-2-cols">
                                <li>
                                    <div class="andro_category-mm-banner"><img src="https://k.nooncdn.com/cms/pages/20201020/79a2e38e27583a1a5db92d21097f3b8e/en_mb-stationery-01.png" alt="megamenu img">
                                        <div class="andro_category-mm-banner-desc">
                                            <h6>More Categories</h6>
                                            <p>Explore more categories that we offer</p>
                                        </div>
                                    </div>
                                    <ul>
                                        <li> <a href="/themes/react/organista/">Vegetables &amp; Fruits</a>
                                        </li>
                                        <li> <a href="/themes/react/organista/">Dairy</a> </li>
                                        <li> <a href="/themes/react/organista/">Vegan Dairy</a> </li>
                                        <li> <a href="/themes/react/organista/">Meats &amp; Fish</a> </li>
                                        <li> <a href="/themes/react/organista/">Breads &amp; Cereal</a> </li>
                                    </ul>
                                    <ul>
                                        <li> <a href="/themes/react/organista/">Honey</a> </li>
                                        <li> <a href="/themes/react/organista/">Jam &amp; Spreads</a> </li>
                                        <li> <a href="/themes/react/organista/">Legumes</a> </li>
                                        <li> <a href="/themes/react/organista/">Oils</a> </li>
                                    </ul>
                                </li>
                            </ul>
                        </li> -->
                    </ul>
                </div>
            </div>

            <ul class="navbar-nav mx-auto">

                <li class="nav-item">
                    <a class="nav-link" routerLink="/home" [routerLinkActive]="['is-active']" href="#">Home <span
                            class="sr-only">(current)</span></a>
                </li>


                <li class="nav-item " *ngFor="let cat of category| slice:0:11; let i=index">
                    <a class="nav-link" [routerLinkActive]="['is-active']" [routerLink]="[ '/category',cat.id,cat.category_name ] ">
                        <span class="cat_name">{{ cat.category_name }}</span>
                    </a>
                    <!-- <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a class="dropdown-item" routerLink="/productdetail" routerLinkActive="active" href="#">Delivery
                            Information</a>
                        <a class="dropdown-item" href="#">Privacy Policy</a>
                        <a class="dropdown-item" href="#">Terms & Conditions</a>
                    </div> -->
                </li>

                <!-- <li class="nav-item dropdown" *ngFor="let cat of category">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Electronics
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a class="dropdown-item" routerLink="/productdetail" routerLinkActive="active" href="#">Delivery
                            Information</a>
                        <a class="dropdown-item" href="#">Privacy Policy</a>
                        <a class="dropdown-item" href="#">Terms & Conditions</a>
                    </div>
                </li> -->






                <!-- <li class="nav-item">
                    <a class="nav-link" href="#">Contact</a>
                </li> -->
            </ul>
        </div>
    </div>
</nav>

<div class="search-bar d-block d-md-none">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="form-inline mb-3 mx-auto">
                    <input class="form-control" type="search" placeholder="Search for products..." (click)="openSearch()" name="searchText" [(ngModel)]="searchText" (ngModelChange)="onSearchChange($event)" type="text" aria-label="Search">
                    <button class="btn btn-success" type="button" (click)="openSearch()"> <i class="fa fa-search" ></i></button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Sidebar -->
<nav id="sidebar">
    <div class="sidebar-header">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-10 pl-0">
                    <a class="btn btn-primary" href="#"><i class="fa fas-user-circle mr-1"></i> Log In</a>
                </div>

                <div class="col-2 text-left">
                    <button type="button" id="sidebarCollapseX" class="btn btn-link">
                        <i class="fa fa-x icon-single"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <ul class="list-unstyled components links">
        <li class="active">
            <a href="#"><i class="fa fa-home mr-3"></i> Home</a>
        </li>
        <li>
            <a href="#"><i class="fa fa-carousel mr-3"></i> Products</a>
        </li>
        <li>
            <a href="#"><i class="fa fa-book-open mr-3"></i> Schools</a>
        </li>
        <li>
            <a href="#"><i class="fa fa-crown mr-3"></i> Publishers</a>
        </li>
        <li>
            <a href="#pageSubmenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle"><i
                    class="fa fa-help-circle mr-3"></i>
                Support</a>
            <ul class="collapse list-unstyled" id="pageSubmenu">
                <li>
                    <a href="#">Delivery Information</a>
                </li>
                <li>
                    <a href="#">Privacy Policy</a>
                </li>
                <li>
                    <a href="#">Terms & Conditions</a>
                </li>
            </ul>
        </li>
        <li>
            <a href="#"><i class="fa fa-phone mr-3"></i> Contact</a>
        </li>
    </ul>

    <h6 class="text-uppercase mb-1">Categories</h6>
    <ul class="list-unstyled components mb-3">
        <li>
            <a href="#">Category 1</a>
        </li>
        <li>
            <a href="#">Category 1</a>
        </li>
        <li>
            <a href="#">Category 1</a>
        </li>
        <li>
            <a href="#">Category 1</a>
        </li>
        <li>
            <a href="#">Category 1</a>
        </li>
        <li>
            <a href="#">Category 1</a>
        </li>
    </ul>

    <ul class="social-icons">
        <li><a href="#" target="_blank" title=""><i class="fa fal-facebook-square"></i></a></li>
        <li><a href="#" target="_blank" title=""><i class="fa fal-twitter"></i></a></li>
        <li><a href="#" target="_blank" title=""><i class="fa fal-linkedin"></i></a></li>
        <li><a href="#" target="_blank" title=""><i class="fa fal-instagram"></i></a></li>
    </ul>

</nav>