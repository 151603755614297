import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
openModal=true;

 public config_url="https://flublet.prod-app.in/";
//  public config_url="https://ecomadmin.cydeztechnologies.com/";
  public address='';
  public pincode='';
  public net_status;
  constructor(private router: Router) {


  }


  chack_netStatus()
  {
    if (!navigator.onLine) {
     //return this.net_status=false;
      this.router.navigate(['/neterror']);
      }
      if (navigator.onLine) {
      //  return  this.net_status=true;
       // this.router.navigate(['/']);
        }
  }
}
