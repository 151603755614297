import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { ProductdetailsComponent } from './productdetails/productdetails.component';
import { CartComponent } from './cart/cart.component';
import { CarouselComponent } from './carousel/carousel.component';
import { ProfileComponent } from './profile/profile.component';
import { LoginComponent } from './login/login.component';
import { RegistrationComponent } from './registration/registration.component';
import { FooterComponent } from './footer/footer.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { RouteReuseStrategy } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { AuthService } from './interceptors/auth.service';
import { CategoryComponent } from './category/category.component';
import { SuccessComponent } from './success/success.component';
import { SizeChartComponent } from './size-chart/size-chart.component';
import { ShippingComponent } from './shipping/shipping.component';
import { SubcategoryComponent } from './subcategory/subcategory.component';
import { OrdersComponent } from './orders/orders.component';
import { PaymentComponent } from './payment/payment.component';
import { OrderSuccessComponent } from './order-success/order-success.component';
import { WishlistComponent } from './wishlist/wishlist.component';
import { SearchComponent } from './search/search.component';
import { OwlModule } from 'ngx-owl-carousel';
import { NgImageSliderModule } from "ng-image-slider";
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { AgmCoreModule } from '@agm/core';
import { ReversePipe } from './reverse.pipe';
import { AboutComponent } from './about/about.component';
import { ShippingPolicyComponent } from './shipping-policy/shipping-policy.component';
import { ReturnComponent } from './return/return.component';
import { TermsComponent } from './terms/terms.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

// import { AlertComponent } from './alert/alert.component';

// import { CommonModule } from '@angular/common';
// import { SubCategoryComponent } from './sub-category/category.component';
// import { OrdersComponent } from './orders/orders.component';
// import { PlaceholderComponent } from './placeholder/placeholder.component';
// import { AddressComponent } from './address/address.component';
// import { OrderhistoryComponent } from './orderhistory/orderhistory.component';
// import { Profile2Component } from './profile2/profile2.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    ProductdetailsComponent,
    CartComponent,
    CarouselComponent,
    ProfileComponent,
    LoginComponent,
    RegistrationComponent,
    FooterComponent,
    CategoryComponent,
    SuccessComponent,
    SizeChartComponent,
    ShippingComponent,
    SubcategoryComponent,
    OrdersComponent,
    PaymentComponent,
    OrderSuccessComponent,
    WishlistComponent,
    SearchComponent,
    ReversePipe,
    AboutComponent,
    ShippingPolicyComponent,
    ReturnComponent,
    TermsComponent,
    ForgotPasswordComponent,
   
    // AlertComponent
    // CategoryComponent,
    // ProductComponent,
    // CheckoutComponent,
    // PaymentComponent,
    // DeliveryComponent,
    // SearchComponent,
    // MapsComponent,

   
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CarouselModule,
    CommonModule ,
    OwlModule,
    NgImageSliderModule,
    Ng2SearchPipeModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBaz2SEa3J1UFz0wStoO5A8TgGLpTIpD90',
      libraries: ['places']
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
