<section class="pro">

    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-3">
                <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <a class="nav-link " [ngClass]="{ 'active':activeTab==='profile'}" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">Profile</a>

                    <a class="nav-link" [ngClass]="{ 'active':activeTab==='orders'}" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false">Order</a>
                    <a class="nav-link" [ngClass]="{ 'active':activeTab==='addresses'}" id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-settings" role="tab" aria-controls="v-pills-settings" aria-selected="false">Addresses</a>
                </div>
            </div>
            <div class="col-sm-9">
                <div class="tab-content" id="v-pills-tabContent">
                    <div class="tab-pane fade  " [ngClass]="{ 'show active':activeTab==='profile'}" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">

                        <form class="services" [formGroup]="userForm" (ngSubmit)="userEdit()">
                            <div class="sc-1oq2l73-1 jgkOFL">
                                <h1 data-qa="lbl_sectionTitles_General Information" class="sc-1oq2l73-2 jfESjV">General Information
                                </h1>
                            </div>

                            <div class="row">
                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Name</label>
                                        <input type="text" class="form-control inputtav" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Name" name="username" id="username" formControlName="username" [class.is-invalid]="userForm.get('username').invalid && userForm.get('username').touched"
                                            [(ngModel)]="saveUser.username" required>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Email</label>
                                        <input type="text" class="form-control inputtav" aria-describedby="emailHelp" placeholder="Email" name="email" id="email" formControlName="email" [class.is-invalid]="userForm.get('email').invalid && userForm.get('email').touched" [(ngModel)]="saveUser.email"
                                            required>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Preferred language</label>
                                        <select class="form-control inputtav">
                                            <option>English</option>
                                        </select>
                                    </div>
                                </div>
                            </div>


                            <!-- <div class="sc-1c0aqcs-1 ifCmQE"><button data-qa="btn_disabled_Save" width="190px" class="dyoqji-0 jzxnON" type="submit"><span data-qa="btn_Save"
                                        class="dyoqji-1 jAsdLe">Save</span></button></div> -->
                            <div class="jsx-278401895 jsx-3349621030 cartCtaWrapper"><button aria-label="Checkout Now" class="jsx-278401895 jsx-3349621030 ripple">Save</button></div>
                        </form>

                        <form class="services">
                            <div class="sc-1oq2l73-1 jgkOFL">
                                <h1 data-qa="lbl_sectionTitles_General Information" class="sc-1oq2l73-2 jfESjV">Security
                                </h1>
                            </div>
                            <div class="row">
                                <span>Click here to  </span> <button class="btn btn-primary otpbtn"> GET OTP </button><span> to change password ( <i class="notei">Otp will send to your Registered Mobile Number</i> )</span>

                                <div class="col-sm-12 newhr">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Enter OTP</label>
                                        <input type="Email" class="form-control inputtav" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="OTP">
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">New Password</label>
                                        <input type="password" class="form-control inputtav" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="New Password">
                                    </div>
                                </div>

                            </div>


                            <!-- <div class="sc-1c0aqcs-1 ifCmQE"><button data-qa="btn_disabled_Save" width="190px" class="dyoqji-0 jzxnON"><span data-qa="btn_Save" class="dyoqji-1 jAsdLe">Change
                                        Password</span></button></div> -->
                            <div class="jsx-278401895 jsx-3349621030 cartCtaWrapper"><button aria-label="Checkout Now" class="jsx-278401895 jsx-3349621030 ripple">Change Password</button></div>
                        </form>
                    </div>

                    <div class="tab-pane fade" [ngClass]="{ 'show active':activeTab==='orders'}" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">


                        <div class="container-fluid mb-5">
                            <div class="row">
                                <div class="col-lg-12 mx-auto">
                                    <!-- List group-->
                                    <ul class="list-group shadow">
                                        <!-- list group item-->
                                        <div *ngFor="let order of orders">
                                            <li class="list-group-item " *ngIf="order.status!='added_to_cart'">

                                                <div class="order-sec">
                                                    <h6>Order ID : #ORD{{ order.id }} </h6>
                                                    <p>Order Date : {{ order.order_date }} </p>
                                                    <div class="order-sec1">
                                                        {{ order.status }}
                                                    </div>
                                                </div>



                                                <app-orders [order_id]="order.id"></app-orders>



                                            </li>
                                        </div>



                                    </ul>
                                    <!-- End -->
                                </div>
                            </div>
                        </div>




                    </div>
                    <div class="tab-pane fade" [ngClass]="{ 'show active':activeTab==='addresses'}" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">


                        <div class="sc-12mmu2k-3 jFsdqV">
                            <div class="gzl3e0-0 iuAcxp">
                                <div class="gzl3e0-1 dFKjde">Add your address</div>
                                <!-- <label class="switch">
                                    <input type="checkbox" checked>
                                    <span class="slider round"></span>
                                  </label> -->
                            </div>
                        </div>

                        <!-- <div class="sc-12mmu2k-1 hVCRve">
                            <h2>Add a New Address</h2>
                        </div> -->
                        <div class="mapz" width="100%" height="450">
                            <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
                                <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true" (dragEnd)="markerDragEnd($event)"></agm-marker>
                            </agm-map>
                        </div>
                        <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15594341.573181843!2d56.78132035201805!3d17.401591122059987!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2sin!4v1606551082619!5m2!1sen!2sin"
                            width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> -->

                        <form class="services" [formGroup]="userForm" (ngSubmit)="userEdit()">
                            <!-- <div class="sc-1oq2l73-1">
                                <h1 data-qa="lbl_sectionTitles_General Information" class="sc-1oq2l73-2 jfESjV">Add a New Address
                                </h1>
                            </div> -->
                            <!-- <div class="row">
                                <div class="col-sm-6">
                                    <div class="sc-1xu65d2-8 eUhNPT">
                                        <div class="sc-1xu65d2-36 eifKQN"><span class="sc-1xu65d2-7 kGqYAv">Location
                                                Information</span><img src="https://a.nooncdn.com/s/app/com/common/images/flags/ae.svg" alt="ae_flag" class="sc-1xu65d2-37 XirUg"></div>
                                        <div class="sc-1xu65d2-9 fKaQMe">
                                            <div class="sc-1xu65d2-10 GwuTh">
                                                <div class="sc-1xu65d2-11 cvRqDg">
                                                    <div class="sc-1xu65d2-12 floEZT"><img src="https://a.nooncdn.com/s/app/com/noon/images/address_collection_indicator.svg" alt="set_on_map" width="22" height="22">&nbsp;&nbsp;<span>Locker</span></div>
                                                    <div class="sc-1xu65d2-13 haJAJS">
                                                        <span>{{ add }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->

                            <div class="row">
                                <div class="col-sm-6">
                                    <label>First Name *</label>
                                    <input type="text" class="form-control" name="username" id="username" formControlName="username" [class.is-invalid]="userForm.get('username').invalid && userForm.get('username').touched" [(ngModel)]="saveUser.username" required>
                                </div>
                                <div class="col-sm-6">
                                    <label>Last Name </label>
                                    <input type="text" class="form-control" name="last-name" required="">
                                </div>
                            </div>
                            <label>Country / Region </label>
                            <input type="text" class="form-control" name="country" value="{{ country }}" required="">
                            <label>Address *</label>
                            <input type="text" class="form-control" name="address" id="address" formControlName="address" [class.is-invalid]="userForm.get('address').invalid && userForm.get('address').touched" [(ngModel)]="saveUser.address" required>
                            <div class="row">
                                <div class="col-sm-6">
                                    <label>Postcode / ZIP *</label>
                                    <input type="text" class="form-control" name="postcode" id="postcode" formControlName="postcode" [class.is-invalid]="userForm.get('postcode').invalid && userForm.get('postcode').touched" [(ngModel)]="saveUser.postcode" required>
                                </div>
                            </div>


                            <!-- <div class="sc-1c0aqcs-1 ifCmQE"><button (click)="saveAddress()" [disabled]="userForm.invalid" data-qa="btn_disabled_Save" width="190px" class="dyoqji-0 jzxnON"><span data-qa="btn_Save" class="dyoqji-1 jAsdLe">Save
                                        Address</span></button></div> -->
                            <div class="jsx-278401895 jsx-3349621030 cartCtaWrapper"><button aria-label="Checkout Now" (click)="saveAddress()" [disabled]="userForm.invalid" class="jsx-278401895 jsx-3349621030 ripple">Save Address</button></div>
                        </form>




                    </div>
                </div>

            </div>
        </div>
    </div>
</section>
