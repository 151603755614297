<div class="container-fluid">
    <div class="row">
        <aside class="col-lg-9">
            <div class="card">
                <div class="table-responsive">
                    <!-- {{ cart }}some thing -->
                    <div *ngIf="!cart||cart==''" class="noItem">
                        <div><img src="../../assets/img/emptycart.png"></div>
                        Oops! Your Cart is empty!
                        <p>Looks like you haven't added anything to your cart yet</p>
                        <button routerLink="/home" class="jsx-278401895 jsx-3349621030 ripple">Shop Now</button>
                    </div>
                    <table class="table table-borderless table-shopping-cart" *ngIf="cart&&cart!=''">
                        <thead class="text-muted">
                            <tr class="small text-uppercase">
                                <th scope="col">Product</th>
                                <th scope="col" width="120">Quantity</th>
                                <th scope="col" class="text-right d-none d-md-block" width="200"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let crt of cart; let i=index">
                                <td>
                                    <figure class="itemside align-items-center">
                                        <div class="aside">
                                            <a [routerLink]="[ '/product-details',crt.productId,crt.product_name ] "> <img [src]="img_link+crt.image" class="img-sm"></a>
                                        </div>
                                        <figcaption class="info">
                                            <!-- <span class="text-muted small">Emerald</span> -->
                                            <br>
                                            <a [routerLink]="[ '/product-details',crt.productId,crt.product_name ] " class="title text-dark" data-abc="true">{{ crt.product_name}}</a>
                                            <p>{{ crt.preference }} : {{ crt.variance }}</p>
                                            <!-- <p class="text-muted small">Delivered by Sun, Nov 15 </p> -->

                                        </figcaption>
                                    </figure>
                                </td>
                                <td>
                                    <!-- <select class="form-control">
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                    </select>  -->
                                    {{ getCount(crt) }}
                                </td>

                                <td class="text-right d-none d-md-block">
                                    <div class="price-wrap"> <var class="price">₹ {{ crt.price }}</var>
                                        <!-- <small class="text-muted">
                                    $9.20 each </small>  -->
                                    </div>
                                </td>
                                <td>
                                    <button class="btn btn-danger"> <i class="fa fa-trash" (click)="removeProduct1(crt)"></i>  </button>
                                </td>
                            </tr>




                        </tbody>
                    </table>
                </div>
            </div>
        </aside>
        <aside class="col-lg-3">

            <div class="card">
                <div class="card-body price_section">
                    <!-- <form>
                        <div class="form-group"> <label>Have coupon?</label>
                            <div class="input-group"> <input type="text" class="form-control coupon" name="" placeholder="Coupon code"> <span class="input-group-append"> <button
                                        class="btn btn-primary btn-apply coupon">Apply</button> </span> </div>
                        </div>
                    </form> -->
                    <dl class="dlist-align">
                        <dt>Total price:</dt>
                        <dd class="text-right ml-10">₹ {{ sum }}</dd>
                    </dl>
                    <dl class="dlist-align">
                        <dt>Discount:</dt>
                        <dd class="text-right text-danger ml-10">₹ 0.00</dd>
                    </dl>
                    <dl class="dlist-align">
                        <dt>Shipping Charge:</dt>
                        <dd class="text-right text-danger ml-10">₹ {{ scharge }}</dd>
                    </dl>
                    <dl class="dlist-align">
                        <dt>Total:</dt>
                        <dd class="text-right text-dark b ml-10"><strong>₹ {{ sum+scharge }}</strong></dd>
                    </dl>
                    <hr>
                    <span id="err"></span>
                    <div *ngIf="cart&&cart!=''" class="jsx-278401895 jsx-3349621030 cartCtaWrapper"><button (click)="checkout()" aria-label="Checkout Now" class="jsx-278401895 jsx-3349621030 ripple">Checkout Now</button></div>
                </div>
            </div>
        </aside>
    </div>
</div>