<section class="osu">
    <div class="container">
        <div class="col-sm-12">
            <img src="https://k.nooncdn.com/cms/pages/20200906/4e64d8510aa963f37e996de09e2bc803/en_nonloyal-checkout.png" width="100%">
        </div>
        <br>
        <div class="card">
            <div class="jsx-3226915270 successHero">
                <div class="jsx-3226915270 successCol">
                    <p class="jsx-3226915270 successHeroIntro">Thank you for your order !</p>
                    <p class="jsx-3226915270 successHeroMessage">You'll receive an email once your order is confirmed. </p>
                </div>
                <div class="jsx-3226915270 successCol continueButton"><button aria-label="Continue Shopping" class="jsx-3898435964 ripple primary   " routerLink="/home">Continue Shopping<div class="jsx-3898435964 active"></div>
                </button></div>
            </div>
        </div>
        <br>
        <div class="col-sm-12">
            <img src="https://k.nooncdn.com/cms/pages/20210328/01667714f4948a182e94e1c24affe0b4/en_citi-01.jpeg" width="100%">
        </div>
    </div>

</section>