import { Component, OnInit } from '@angular/core';
import { CategoryService } from '../services/category.service';
import { Router,ActivatedRoute, ParamMap } from '@angular/router';
import { ConfigService } from '../services/config.service';
import { ProductsService } from '../services/products.service';
import { CartService } from '../services/cart.service';

@Component({
  selector: 'app-subcategory',
  templateUrl: './subcategory.component.html',
  styleUrls: ['./subcategory.component.css']
})
export class SubcategoryComponent implements OnInit {
  customOptionsSecond: any = {
    loop: true,
    margin: 10,
    autoplay:false,
    responsiveClass: true,
    
    navText: ['<i class="ion-ios-arrow-left"></i>', '<i class="ion-ios-arrow-right"></i>'],
    responsive: {
      0: {
       items: 1
     },
      480: {
       items: 2
     },
      940: {
       items: 3
     }
    },
   nav: true,
   
  }
  oder_details="";
  cart_item_id=[];
  category:any;
  sub_category:any;
  private sub:any;
   cat_id;
   pcat_id;
   name;
   pincode;
   products:any;
   products_cat:any;
   products_org:any;
   products_brand:any;
   products_review:any;
   products_price:any;
   products_discount:any;
   brands:any;
   price_min;
   price_max;
   brand_arr=[];
   discount;
   review;
   price_status=0;
   discount_status=0;
   review_status=0;
   added;
addedno;
  addBtn;
  addedBtn;
  product=[];
  productbefore=[];
  updatedqty=1;
   p_id;
   cart:any;


  nextcat;
  nextcatname;
  cat;
  cat1;
  prevcat;
  prevcatname;

  constructor(private _category:CategoryService,
    private route: ActivatedRoute,
    private router:Router,
    private _config:ConfigService,
    private _product:ProductsService,private _cart:CartService
    
    ) { }
    img_link=this._config.config_url.slice(0, -1);
  ngOnInit(): void {
    this._config.chack_netStatus();
    if(localStorage.getItem('location')!==null)
    {
     this.pincode=localStorage.getItem('location');
    }

    this._product.getAllProducts().subscribe((data)=>{
      this.products=JSON.parse(JSON.stringify(data));
    })
    this.sub = this.route.params.subscribe(params => {
      this.cat_id = params['id']; 
      this.pcat_id = params['pid']; 
      this.name = params['name']; 

      this._product.getProducts(this.cat_id).subscribe((data)=>{
        this.products_cat=JSON.parse(JSON.stringify(data));
        this.products_org=this.products_cat;
        console.log(this.products_cat);
      })

      this.cat=JSON.parse(localStorage.getItem('category'));
      this.cat1=JSON.parse(localStorage.getItem('category'));
      for(let i=0;i<this.cat.length;i++)
{
  if(this.cat[i].id==this.pcat_id)
  {
    console.log(this.cat[i+1].id+"categories list");
    this.nextcat=this.cat[i+1].id;
    this.nextcatname=this.cat[i+1].category_name;
    if(i>=0)
    {
      this.prevcat=this.cat[i-1].id;
      this.prevcatname=this.cat[i-1].category_name;
    }
    
  }
  // if(i==(this.cat.length-1))
  // {
  //   this.cat=this.cat1;
  // }
}



    this.category=JSON.parse(localStorage.getItem('category'));
    this.cart_item_id=this._cart.getAddedItems();
    if(  this.cart_item_id == null){
      this.cart_item_id=[];
    }
    //  this._category.getSubCategories(this.cat_id).subscribe((data)=>{
    //   this.sub_category==JSON.parse(JSON.stringify(data));

    //     // let cat = data[0]['SubCategory'];
      
    //     // this.sub_category = cat; 
        
    // })

    this._category.getSubCategories(this.cat_id).subscribe((data)=>{
      this.sub_category=JSON.parse(JSON.stringify(data));
    })


   });




  }

//   addProduct(product)
//   {
//     if (localStorage.getItem("user_id") === null) {
//       this._cart.addtoCart(product);
//       this.addBtn="1";
   
      
//     }
//     else
//     {
//       this._cart.addtoCart(product);
//       this._cart.addtoCartLoggedIn(product).subscribe((data)=>{
//         this.addBtn="1";
    
        
//         // this.category=JSON.parse(JSON.stringify(data));
//     });
//     }
    
//     let currentUrl = this.router.url;
//     this.router.routeReuseStrategy.shouldReuseRoute = () => false;
//     this.router.onSameUrlNavigation = 'reload';
//     this.router.navigate([currentUrl]);


// }


addQty(product)
  {
  
    if (localStorage.getItem("user_id") === null) {
     this.updatedqty= this._cart.addtoCartQty(product);
      // alert("item added to cart" + qty);
    }
    else
    {
      this.updatedqty= this._cart.addtoCartQty(product);
      this._cart.addtoCartLoggedInQty(product).subscribe((data)=>{
      
        // this.category=JSON.parse(JSON.stringify(data));
    });
    }
    


}

removeQty(product)
  {
   
    if (localStorage.getItem("user_id") === null) {
      this.updatedqty=this._cart.removetoCartQty(product);
    
    }
    else
    {
      this.updatedqty= this._cart.removetoCartQty(product);
      this._cart.addtoCartLoggedIn(product).subscribe((data)=>{
      
        // this.category=JSON.parse(JSON.stringify(data));
    });
    }
    


}

check_availability(pin_array) {
  var arr = [];
  for (var i = 0; i < pin_array.length; i++) {
    arr.push(pin_array[i].pincode);
  }
  if ((arr.indexOf(this.pincode) > -1)) {
    return true;
  }
  else {
    return false;
  }
}


itemShow(item)
{
  
    console.log("I am Left")
  
    if(this.nextcatname)
  {
    this.router.navigate(['/category/'+this.nextcat+'/'+this.nextcatname]);
  }

}
nextItem()

{
  console.log("swipe worked mee"+this.nextcatname);
  if(this.nextcatname)
  {
    this.router.navigate(['/category/'+this.nextcat+'/'+this.nextcatname]);
  }
  
}
prevItem()

{
  console.log("swipe worked yoo"+this.prevcatname);
  if(this.prevcatname)
  {
    this.router.navigate(['/category/'+this.prevcat+'/'+this.prevcatname]);
  }
  
}

addWishlist(product)
      {
        // alert("called me");
        if(this._cart.addtoWishlist(product,0,0)==0)
          {
            // alert('Item already in your wishlist');
            let msg="Item removed from your wishlist";
            document.getElementById("snackbar").innerHTML=msg;
            var x = document.getElementById("snackbar");
            x.className = "show";
           setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
          }
        else 
        // (this._cart.addtoWishlist(product,0)==1)
          {
            let msg="Product Added to wishlist"
            this.addBtn = "1";
            document.getElementById("snackbar").innerHTML=msg;
            var x = document.getElementById("snackbar");
            x.className = "show";
           setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
          }
      }

      getWish(pro)
      {
        if(localStorage.getItem("wishlist_products"))
        {
        let sel = JSON.parse(localStorage.getItem("wishlist_products")).filter(
          (products) => products.productId === pro.id
        );
        if(sel!=""&&sel!=null)
        {
          return 1;
        }
        else
        {
          return 0;
        }
      }
      else
      {
        return 0;
      }
      }


      getBrandCount(id)
      {
        let count=0;
        for(let i=0;i<this.products_cat.length;i++)
        {
          if(this.products_cat[i].brand_name==id)
          {
            count++
          }
        }
        return count;
      }
      getBrandProducts(event)
      {
        //this.brand_arr[0]=1;
        console.log(event.target.value);
        if ( event.target.checked ) {
          var val=event.target.value;
          this.brand_arr.push(val);

         
      }
      else
      {
        var val=event.target.value;
        for(let i=0;i<this.brand_arr.length;i++)
        {
          if(this.brand_arr[i]==val)
          {
            this.brand_arr.splice(i, 1); 
          }
        }
          
      }
      console.log(this.brand_arr);
        this.applyFilter();
      }
      getPriceProducts(min,max,event)
      {
        
        var elems = document.getElementsByClassName("pricefilter") as HTMLCollectionOf<HTMLElement>;
        var index = 0, length = elems.length;
    for ( ; index < length; index++) {
        elems[index].style.fontWeight = "200";
       
    }
        if(this.price_min==min&&this.price_max==max)
        {
          event.target.style.fontWeight=200;
          this.price_min="";
          this.price_max="";
        }
        else
        {
          event.target.style.fontWeight=600;
          this.price_min=min;
          this.price_max=max;
        }
        console.log(min);
        console.log(max);
        this.applyFilter();

      }
      getPriceProductsNoRange(min,event)
      {
        if(this.price_min==min)
        {
          event.target.style.fontWeight=200;
          this.price_min="";
          this.price_max="";
        }
        else
        {
          event.target.style.fontWeight=600;
          this.price_min=min;
          this.price_max=0;
        }
        var elems = document.getElementsByClassName("pricefilter") as HTMLCollectionOf<HTMLElement>;
        //  elems = document.querySelectorAll(".pricefilter");
      
    var index = 0, length = elems.length;
    for ( ; index < length; index++) {
        elems[index].style.fontWeight = "200";
       
    }
        
        if(this.price_min==min)
        {
          event.target.style.fontWeight=200;
        }
        else
        {
          event.target.style.fontWeight=600;
        }
       
       
        console.log(min);
        this.applyFilter();

      }
      getDiscountProducts(dis,event)
      {
        var elems = document.getElementsByClassName("discountfilter") as HTMLCollectionOf<HTMLElement>;
        var index = 0, length = elems.length;
    for ( ; index < length; index++) {
        elems[index].style.fontWeight = "200";
       
    }
        if(this.discount==dis)
        {
          this.discount="";
          event.target.style.fontWeight=200;
        }
        else
        {
          this.discount=dis;
          event.target.style.fontWeight=600;
        }
       
        this.applyFilter();
      }
      getReviewProducts(rev,event)
      {
        var elems = document.getElementsByClassName("reviewfilter") as HTMLCollectionOf<HTMLElement>;
        var index = 0, length = elems.length;
    for ( ; index < length; index++) {
        elems[index].style.fontWeight = "200";
        // elems[index].style.color = "#212529";
       
    }
 
    
        if(this.review==rev)
        {
          event.target.style.fontWeight=200;
          this.review="";
        }
        else
        {
          event.target.style.fontWeight=600;
          this.review=rev;
          // event.target.style.color = "#0084ff";
        }
       
        this.applyFilter();
      }

      applyFilter()
      {
        this.products_cat=this.products_org;
        let ext="";
        if(this.price_min)
        {
          this.products_cat = this.products_cat.filter(
            book => book.sales_rate >= this.price_min);
            console.log(this.products_cat+"filter ##########")
          
        
        }
        if(this.price_max)
        {
          this.products_cat = this.products_cat.filter(
            book => book.sales_rate <= this.price_max);
            console.log(this.products_cat+"filter ##########")
         
          
        
        }
        if(this.discount)
        {
          this.products_cat = this.products_cat.filter(
            book => book.offer > this.discount);
            console.log(this.products_cat+"filter ##########")
         
          
        
        }
        if(this.review)
        {
          this.products_cat = this.products_cat.filter(
            book => book.get_avg_rating >= this.review);
            console.log(this.products_cat+"filter ##########")
         
          
        
        }
        if(this.brand_arr.length>0)
        {
          this.products_cat = this.products_cat.filter(
            book => this.brand_arr.includes(book.brand_name));
            console.log(this.products_cat+"filter ##########")
         
          
        
        }
       
        
      }
}
