import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { Router,ActivatedRoute, ParamMap } from '@angular/router';
import { ProductsService } from '../services/products.service';
import { CartService } from '../services/cart.service';
import { ConfigService } from '../services/config.service';
import { OrderService } from '../services/order.service';
import { AuthService } from '../services/auth.service';
import { MapsAPILoader } from '@agm/core';
import { FormBuilder, Validators } from '@angular/forms';
declare var google;
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  delUser={address:'',xCordinate:'',yCordinate:'',postcode:''};
  saveUser={username:'',email:'',postcode:'',address:''};
  // saveUser={username:'',email:''};
  country;
  private sub:any;
  type;
  title: string = 'AGM project';
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  pincode: string;
  private geoCoder;
  savedAddr;
add;
  @ViewChild('search')
  public searchElementRef: ElementRef;


  email;
  username;
  orders:any;
  customer:any;
  count=0;
  order_id: string ;
  activeTab="profile";
  constructor( private mapsAPILoader: MapsAPILoader,private fb:FormBuilder,
    private ngZone: NgZone,private _product:ProductsService,private route: ActivatedRoute,private router:Router,private _cart:CartService,private _config:ConfigService,private _order:OrderService) {
      this.latitude = 0;
		this.longitude = 0;
    }

    userForm=this.fb.group(
      {

        username:['',[Validators.required]],
        email:['',[Validators.required]],
        postcode:['',[Validators.required]],
        address:['',[Validators.required]]
      }
     )
  ngOnInit() {
    this._config.chack_netStatus();

    this.sub = this.route.params.subscribe(params => {
      this.activeTab = params['name'];


    })

    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;
    });
    this.email=localStorage.getItem('email');

    this._cart.getCartForLoggedUser(localStorage.getItem('user_id')).subscribe((data)=>{
      this.orders=JSON.parse(JSON.stringify(data));
      console.log('====================================');
      console.log(this.orders,">>>>>>>>>>?????");
      console.log('====================================');
      for(let i=0;i<this.orders.length;i++)
      {
        if(this.orders[i].status=="Ordered")
        {
          this.count++;
        }
      }
    });


    if(localStorage.getItem('user_id')!=null)
    {
      this._order.customerData(localStorage.getItem('user_id')).subscribe((data)=>{
       console.log("workded");
       console.log(JSON.parse(JSON.stringify(data)));
        this.savedAddr=JSON.parse(JSON.stringify(data));
        this.savedAddr=this.savedAddr[0];
        console.log(this.savedAddr);
        this.saveUser.username=this.savedAddr['username'];
        this.saveUser.email=this.savedAddr['email'];
        this.saveUser.postcode=this.savedAddr['postcode'];
        this.saveUser.address=this.savedAddr['address'];
        // this.delUser.email=this.savedAddr['email'];
        // this.delUser.username=this.savedAddr['username'];

      });

    }
  }
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }


  markerDragEnd($event) {
    console.log($event);
    this.latitude = $event.latLng.lat();
    this.longitude = $event.latLng.lng();
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude) {
    this.delUser.xCordinate=latitude;
    this.delUser.yCordinate=longitude;
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      console.log(results);
      console.log(status);
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;

            console.log(results);
            for (var i = 0; i < results[0].address_components.length; i++) {
              var types = results[0].address_components[i].types;
              this.add=results[0].formatted_address;
              this.country=results[0].address_components[3].long_name;
              this.saveUser.address=results[0].formatted_address;

              console.log(this.add + "this is formatedaaddr @@@@@@@@@@@@@");
              for (var typeIdx = 0; typeIdx < types.length; typeIdx++) {
                if (types[typeIdx] == 'postal_code') {
                  this.pincode = results[0].address_components[i].short_name;
                  this.saveUser.postcode=results[0].address_components[i].short_name;
                  //console.log(results[0].address_components[i].long_name);
                  // console.log(results[0].address_components[i].short_name);
                  // localStorage.setItem('location', results[0].address_components[i].short_name);
                  // this._router.navigate([""]).then(() => {
                  //   window.location.reload();
                  // });

                }
              }
            }

          // alert(this.address);
          // this.ngOnInit();
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  setAddress()
  {
localStorage.setItem('location',this.pincode);
// this.ngOnInit();
this.router.navigate([""]).then(() => {
  window.location.reload();
});
this.router.navigate(['']);
  }
  saveAddress()
  {
    this.delUser.address=this.add;
    this.delUser.postcode=this.pincode;
    localStorage.setItem("location", this.pincode);
    console.log(this.delUser.xCordinate);
    console.log(this.delUser);
          this._cart.addAddressUser(this.delUser).subscribe((res) =>
          {
           //alert("address saved");
          //  this.router.navigate(['/payment']);

          },err=>
          {
            console.log(err);
            alert("Error");
          })
  }
  userEdit()
  {
    this._cart.saveEditUser(this.saveUser).subscribe((res) =>
    {
     alert("User Data saved");
    //  this.router.navigate(['/payment']);

    },err=>
    {
      console.log(err);
      alert("Error");
    })
  }

}
