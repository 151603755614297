<section class="log"></section>
<div class="container">
    <div class="login-form mx-auto d-block w-100">
        <div class="page-header text-center">
            <small id="finalSuccess"></small>
            <!-- <h6>Welcome back!</h6> -->
            <h6></h6>
            <h1 class="logh1"><strong>Reset Password
			</strong></h1>
        </div>
        <!-- <div class="text-center mb-30">
            Don’t have an account?<a routerLink="/signup"> Sign up</a>
        </div> -->
        <div class="errmain">
            <small class="err" id="err">  </small>
        </div>
        <form method="post" class="form-validate" _lpchecked="1">
            <div class="form-group">
                <div class="control-label">
                    <label id="username-lbl" for="username" class="required invalid">Phone Number<span class="star"> *</span></label>
                </div>
                <div class="controls">
                    <input name="username" id="username" value="" class="validate-username required form-control invalid jsx" #phonenumber size="25" required="required" aria-required="true" autofocus="" aria-invalid="true" type="text" required>
                    <div>
                        <small class="text-danger" id="phoneError"></small>

                    </div>
                </div>
            </div>
            <button aria-label="Sign In" class="jsx-3452973619 base ripple primary uppercase fullWidth" (click)="generateOtp(phonenumber)">Generate OTP</button>
            <div class="form-group">
                <div class="control-label">
                    <label id="password-lbl" for="password" class="required">OTP<span class="star"> *</span></label>

                </div>
                <div class="controls">
                    <input name="password" id="password" value="" class="validate-password required form-control jsx" size="25" #otp maxlength="99" required="required" aria-required="true" type="text" required>
                    <div>
                        <small class="text-danger" id="errorotp"></small>

                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="control-label">
                    <label id="password-lbl" for="password" class="required">Enter New Password<span class="star"> *</span></label>

                </div>
                <div class="controls">
                    <input name="newpassword" id="newpassword" value="" class="validate-password required form-control jsx" size="25" #otp maxlength="99" required="required" aria-required="true" type="password" #newpassword required>
                    <div>
                        <small class="text-danger" id="errornewpassword"></small>

                    </div>
                </div>
            </div>

            <button aria-label="Sign In" class="jsx-3452973619 base ripple primary uppercase fullWidth" (click)="resetPassword(otp,newpassword)">Reset Password</button>

        </form>

    </div>
</div>