import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { from } from 'rxjs';
import { HomeComponent } from './home/home.component';
import { ProductdetailsComponent } from './productdetails/productdetails.component';
import { CartComponent } from './cart/cart.component';
import { ProfileComponent } from './profile/profile.component';
import { LoginComponent } from './login/login.component';
import { RegistrationComponent } from './registration/registration.component';
import { CategoryComponent } from './category/category.component';
import { SuccessComponent } from './success/success.component';
import { SizeChartComponent } from './size-chart/size-chart.component';
import { ShippingComponent } from './shipping/shipping.component';
import { SubcategoryComponent } from './subcategory/subcategory.component';
import { PaymentComponent } from './payment/payment.component';
import { OrderSuccessComponent } from './order-success/order-success.component';
import { WishlistComponent } from './wishlist/wishlist.component';
import { SearchComponent } from './search/search.component';
import { AboutComponent } from './about/about.component';
import { ShippingPolicyComponent } from './shipping-policy/shipping-policy.component';
import { ReturnComponent } from './return/return.component';
import { TermsComponent } from './terms/terms.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';



const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'productdetail', component: ProductdetailsComponent },
  { path: 'cart', component: CartComponent },
  { path: 'profile/:name', component:ProfileComponent},
  { path: 'login', component:LoginComponent},
  { path: 'signup', component:RegistrationComponent},
  { path: 'success', component:SuccessComponent},
  { path: 'size-chart', component:SizeChartComponent},
  { path: 'shipping', component:ShippingComponent},
  { path:'subCategory/:pid/:id/:name',component:SubcategoryComponent},
  { path:'payment',component:PaymentComponent},
  { path:'order-success',component:OrderSuccessComponent},
  { path:'wishlist',component:WishlistComponent},
  { path:'search/:id',component:SearchComponent},
  { path:'size-chart',component:SizeChartComponent},
  // {
  //   path: 'subCategory/:pid/:id/:name', component: SubCategoryComponent
  // }
  // ,
  {
    path: 'category/:id/:name', component: CategoryComponent
  }
  ,
  {
    path: 'product-details/:id/:name', component: ProductdetailsComponent
  },
  { path:'about',component:AboutComponent},
  { path:'shipping_policy',component:ShippingPolicyComponent},
  { path:'return',component:ReturnComponent},
  { path:'terms_of_use',component:TermsComponent},
  { path:'forgot-password',component:ForgotPasswordComponent}


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
