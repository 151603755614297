import { Component, OnInit } from '@angular/core';
import { CategoryService } from '../services/category.service';
import { Router,ActivatedRoute, ParamMap } from '@angular/router';
import { ConfigService } from '../services/config.service';
import { ProductsService } from '../services/products.service';
import { CartService } from '../services/cart.service';
const slideOpts = {
  slidesPerView: 1,
  coverflowEffect: {
    rotate: 50,
    stretch: 0,
    depth: 100,
    modifier: 1,
    slideShadows: true,
  },}
@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
  countsub = 0;
  customOptionsSecond: any = {
    loop: true,
    margin: 10,
    autoplay:false,
    responsiveClass: true,
    
    
    navText: ['<i class="ion-ios-arrow-left"></i>', '<i class="ion-ios-arrow-right"></i>'],
    responsive: {
      0: {
       items: 1
     },
      480: {
       items: 2
     },
      940: {
       items: 3
     }
    },
   nav: true,
   
  }
  oder_details="";
  cart_item_id=[];
  category:any;
  sub_category:any;
  private sub:any;
   cat_id;
   name;
   pincode;
   products:any;
   products_org:any;
   products_brand:any;
   products_review:any;
   products_price:any;
   products_discount:any;
   brands:any;
   added;
addedno;
  addBtn;
  addedBtn;
  product=[];
  productbefore=[];
  updatedqty=1;
  nextcat;
  nextcatname;
  cat;
  cat1;
  prevcat;
  prevcatname;
   p_id;
   cart:any;
   catIndex=0;
   leftval=0;

   dummyarray=[1,2,3,4];
   catLoadflag=false;
   catCount=0;
   catLoadCount=0;

   prodLoadflag=false;
   prodCount=0;
   prodLoadCount=0;

   price_min;
   price_max;
   brand_arr=[];
   discount;
   review;
   price_status=0;
   discount_status=0;
   review_status=0;
   constructor(private _category:CategoryService,
    private route: ActivatedRoute,
    private router:Router,
    private _config:ConfigService,
    private _product:ProductsService,private _cart:CartService
    
    ) { }
    img_link=this._config.config_url.slice(0, -1);
  ngOnInit(): void {

    

    this._config.chack_netStatus();

    if(localStorage.getItem('location')!==null)
    {
     this.pincode=localStorage.getItem('location');
    }
    this.sub = this.route.params.subscribe(params => {
      this.cat_id = params['id']; 
      this.name = params['name']; 

        this._product.getCatProducts(this.cat_id).subscribe((data)=>{
        this.products=JSON.parse(JSON.stringify(data));
        this.products_org=JSON.parse(JSON.stringify(data));
        this.products_brand=JSON.parse(JSON.stringify(data));
        this.products_review=JSON.parse(JSON.stringify(data));
        this.products_price=JSON.parse(JSON.stringify(data));
        this.products_discount=JSON.parse(JSON.stringify(data));
        this.prodCount=this.products.length;
console.log(this.products);
        this.brands=this.products.map(item => item.brand_name).filter((value, index, self) => self.indexOf(value) === index)
    })

    this.category=JSON.parse(localStorage.getItem('category'));
    this.cat=JSON.parse(localStorage.getItem('category'));
    this.cat1=JSON.parse(localStorage.getItem('category'));

 for(let i=0;i<this.cat.length;i++)
{
  if(this.cat[i].id==this.cat_id)
  {
    if(i<this.cat.length-1)
    {

    this.nextcat=this.cat[i+1].id;
    this.nextcatname=this.cat[i+1].category_name;
    this.catIndex=i;
    }
    if(i>0)
    {

   
    this.prevcat=this.cat[i-1].id;
    this.prevcatname=this.cat[i-1].category_name;
    this.catIndex=i;
  }
  }
}

    this.cart_item_id=this._cart.getAddedItems();
    if(  this.cart_item_id == null){
      this.cart_item_id=[];
    }
    this._category.getSubCategories(this.cat_id).subscribe((data)=>{
      this.sub_category=JSON.parse(JSON.stringify(data));
      this.catCount=this.sub_category.length;
    })


   });




  }
getCount(pro)
      {
        if(localStorage.getItem("products"))
        {
        let sel = JSON.parse(localStorage.getItem("products")).filter(
          (products) => products.productId === pro.id
        );
        if(sel!=""&&sel!=null)
        {
          return sel[0].qty;
        }
        else
        {
          return 0;
        }
      }
      else
      {
        return 0;
      }
      }

      check_cartStatus(id)
      {
        if(this.cart_item_id.includes(id))
        {
          
          return true;
        }
        else
        {
          
          return false;
        }
      }

      addWishlist(product)
      {
       
        if(this._cart.addtoWishlist(product,0,0)==0)
          {
            
            let msg="Item removed from your wishlist";
            document.getElementById("snackbar").innerHTML=msg;
            var x = document.getElementById("snackbar");
            x.className = "show";
           setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
          }
        else 
        // (this._cart.addtoWishlist(product,0)==1)
          {
            let msg="Product Added to wishlist"
            this.addBtn = "1";
            document.getElementById("snackbar").innerHTML=msg;
            var x = document.getElementById("snackbar");
            x.className = "show";
           setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
          }
      }
      getWish(pro)
      {
        if(localStorage.getItem("wishlist_products"))
        {
        let sel = JSON.parse(localStorage.getItem("wishlist_products")).filter(
          (products) => products.productId === pro.id
        );
        if(sel!=""&&sel!=null)
        {
          return 1;
        }
        else
        {
          return 0;
        }
      }
      else
      {
        return 0;
      }
      }

      getBrandCount(id)
      {
        let count=0;
        for(let i=0;i<this.products.length;i++)
        {
          if(this.products[i].brand_name==id)
          {
            count++
          }
        }
        return count;
      }
      getBrandProducts(event)
      {
        //this.brand_arr[0]=1;
        console.log(event.target.value);
        if ( event.target.checked ) {
          var val=event.target.value;
          this.brand_arr.push(val);

         
      }
      else
      {
        var val=event.target.value;
        for(let i=0;i<this.brand_arr.length;i++)
        {
          if(this.brand_arr[i]==val)
          {
            this.brand_arr.splice(i, 1); 
          }
        }
          
      }
      console.log(this.brand_arr);
        // this.products = this.products.filter(
        //   book => book.brand_name === id);
        //   console.log(this.products+"filter ##########")
        this.applyFilter();
      }
      getPriceProducts(min,max,event)
      {
        
        var elems = document.getElementsByClassName("pricefilter") as HTMLCollectionOf<HTMLElement>;
        var index = 0, length = elems.length;
    for ( ; index < length; index++) {
        elems[index].style.fontWeight = "200";
       
    }
        if(this.price_min==min&&this.price_max==max)
        {
          event.target.style.fontWeight=200;
          this.price_min="";
          this.price_max="";
        }
        else
        {
          event.target.style.fontWeight=600;
          this.price_min=min;
          this.price_max=max;
        }
     

        // this.products=this.products_price;
        console.log(min);
        console.log(max);
        // this.products = this.products.filter(
        //   book => book.sales_rate > min && book.sales_rate < max);
        //   console.log(this.products+"filter ##########")
        this.applyFilter();

      }
      getPriceProductsNoRange(min,event)
      {
        if(this.price_min==min)
        {
          event.target.style.fontWeight=200;
          this.price_min="";
          this.price_max="";
        }
        else
        {
          event.target.style.fontWeight=600;
          this.price_min=min;
          this.price_max=0;
        }
        var elems = document.getElementsByClassName("pricefilter") as HTMLCollectionOf<HTMLElement>;
        //  elems = document.querySelectorAll(".pricefilter");
      
    var index = 0, length = elems.length;
    for ( ; index < length; index++) {
        elems[index].style.fontWeight = "200";
       
    }
        
        if(this.price_min==min)
        {
          event.target.style.fontWeight=200;
        }
        else
        {
          event.target.style.fontWeight=600;
        }
       
        // this.products=this.products_price;
        console.log(min);
        this.applyFilter();

      }
      getDiscountProducts(dis,event)
      {
        var elems = document.getElementsByClassName("discountfilter") as HTMLCollectionOf<HTMLElement>;
        var index = 0, length = elems.length;
    for ( ; index < length; index++) {
        elems[index].style.fontWeight = "200";
       
    }
        if(this.discount==dis)
        {
          this.discount="";
          event.target.style.fontWeight=200;
        }
        else
        {
          this.discount=dis;
          event.target.style.fontWeight=600;
        }
       
        this.applyFilter();
      }
      getReviewProducts(rev,event)
      {
        var elems = document.getElementsByClassName("reviewfilter") as HTMLCollectionOf<HTMLElement>;
        var index = 0, length = elems.length;
    for ( ; index < length; index++) {
        elems[index].style.fontWeight = "200";
        // elems[index].style.color = "#212529";
       
    }
 
    
        if(this.review==rev)
        {
          event.target.style.fontWeight=200;
          this.review="";
        }
        else
        {
          event.target.style.fontWeight=600;
          this.review=rev;
          // event.target.style.color = "#0084ff";
        }
       
        this.applyFilter();
      }

      applyFilter()
      {
        this.products=this.products_org;
        let ext="";
        if(this.price_min)
        {
          this.products = this.products.filter(
            book => book.sales_rate >= this.price_min);
            console.log(this.products+"filter ##########")
          
        
        }
        if(this.price_max)
        {
          this.products = this.products.filter(
            book => book.sales_rate <= this.price_max);
            console.log(this.products+"filter ##########")
         
          
        
        }
        if(this.discount)
        {
          this.products = this.products.filter(
            book => book.offer > this.discount);
            console.log(this.products+"filter ##########")
         
          
        
        }
        if(this.review)
        {
          this.products = this.products.filter(
            book => book.get_avg_rating >= this.review);
            console.log(this.products+"filter ##########")
         
          
        
        }
        if(this.brand_arr.length>0)
        {
          this.products = this.products.filter(
            book => this.brand_arr.includes(book.brand_name));
            console.log(this.products+"filter ##########")
         
          
        
        }
       
        
      }
}
