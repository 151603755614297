import { Component, OnInit } from '@angular/core';
import { FormBuilder,Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { CartService } from '../services/cart.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
logUser={username:'',password:''};
oder_details = "";
err="";
cartpro;
loginpro;
orderset=false;
  constructor(private _auth:AuthService,private _router:Router,private fb:FormBuilder,private cartService:CartService) { }
  loginForm=this.fb.group(
    {
      username:['',[Validators.required]],
      password:['',[Validators.required]]
    
    })
  ngOnInit(): void {
    if(localStorage.getItem('err_sts'))
    {
    this.err=localStorage.getItem('err_sts');
    localStorage.setItem('err_sts','');
    }
    
  }

  loginUser() {
 
    this._auth.loginUser(this.logUser).subscribe(
      (res) => {
        if (res["token"] != "") {
          localStorage.setItem("user_id", res.user_id);
          localStorage.setItem("username", this.logUser.username);
          localStorage.setItem("token", res.token);
          localStorage.setItem("email", this.logUser.username);
          if (localStorage.getItem("products") != null) {
           
              
              
              var tot=0;
              let ct = JSON.parse(localStorage.getItem("products")).length;
              let subtotal=0;
            for (let i = 0; i < ct; i++) {
              var extra='[';
                var qty = parseInt(JSON.parse(localStorage.getItem("products"))[i].qty) + 0;
                var id = JSON.parse(localStorage.getItem("products"))[i].productId;
                var name = JSON.parse(localStorage.getItem("products"))[i].product_name;
                var image = JSON.parse(localStorage.getItem("products"))[i].image;
                var price = parseInt(JSON.parse(localStorage.getItem("products"))[i].price);
                var varianceOrg = JSON.parse(localStorage.getItem("products"))[i].variance;
                var preference = JSON.parse(localStorage.getItem("products"))[i].preference;
                var delivery_charge = JSON.parse(localStorage.getItem("products"))[i].delivery_charge;
                
                // var order_id = JSON.parse(localStorage.getItem("products"))[i].order_id;
                subtotal = subtotal + price;
                let storageProducts = JSON.parse(localStorage.getItem('products'));
                var vendor = JSON.parse(localStorage.getItem("products"))[i].vendor;
                tot=tot+subtotal;
                
               extra=extra+'{ "productId":'+id+',"productQuantity":'+qty+',"productPrice": '+price+',"netWeight":1,"variance":"'+varianceOrg+'","preference":"'+preference+'","delivery_charge":"'+delivery_charge+'" }';
                 
               extra=extra+']';
               var response = JSON.parse(extra);

               this.cartService.addtoCartOnLogin(tot,vendor,response).subscribe((data) => {
 
                 var productfromLocal = JSON.parse(localStorage.getItem("products"));
 
                 for (let i = 0;i < JSON.parse(localStorage.getItem("products")).length;i++) {
                   for (let j = 0; j < data[0].orderdetails.length; j++) {
                  
                     
                     if (
                       productfromLocal[i].productId ==
                       JSON.stringify(data[0].orderdetails[j]["product_id"])
                     ) {
                      
                       let storageProducts = JSON.parse(
                         localStorage.getItem("products")
                       );
                       let name = productfromLocal[i].product_name;
                       let image = productfromLocal[i].image;
                       let price = productfromLocal[i].price;
                       let qty = productfromLocal[i].qty;
                       let Net_weight = productfromLocal[i].Net_weight;
                       let order_detail_id = data[0].orderdetails[j]["id"];
                       let order_id = data[0].id;
                       let subtotal = productfromLocal[i].subtotal;
                       let unit = productfromLocal[i].unit;
                       let vendor = productfromLocal[i].vendor;
                       let variance = productfromLocal[i].variance;
                       let preference = productfromLocal[i].preference;
                       let delivery_charge = productfromLocal[i].delivery_charge;
                      this.loginpro = storageProducts.filter(
                        (product) =>
                          product.productId !== productfromLocal[i].productId
                      );
                      this.loginpro.push({
                         productId: JSON.stringify(
                           data[0].orderdetails[j]["product_id"]
                         ),
                         product_name: name,
                         image: image,
                         price: price,
                         qty: qty,
                         subtotal: subtotal,
                         vendor: vendor,
                         Net_weight: Net_weight,
                         unit: unit,
                         order_detail_id: order_detail_id,
                         order_id: order_id,
                         veriance:variance,
                         preference:preference,
                         delivery_charge:delivery_charge
                       });
                       localStorage.setItem(
                         "products",
                         JSON.stringify(this.loginpro)
                       );
                     } else {
                       console.log("not matched");
                     }
                   }
                 }

                 this.orderset=true;
 
                 //order detail id setting part ends
               });
        
            } 
        } 
        else
        {
      
        }
           
      }
      setTimeout(() => {
      
        // this._router.navigate(["/home"]);
        window.location.href="/home";
        // window.location.reload();
      }
      , 5000);
    },
    err=>
    {
      document.getElementById('err').innerHTML="Invalid Username or Password";
    }
    );
 
  }


 

}
