<section aria-label="Main content" role="main" class="product-detail">

    <div class="main-sec">
        <div class="shadow">
            <div class="_cont detail-top">
                <div class="cols">
                    <div class="left-col">

                        <owl-carousel [options]="{items: 1, nav: false, navigation: true,dots:true,autoplay:true,loop:true}" [items]="products" [carouselClasses]="['owl-theme', 'row', 'sliding']">

                            <div class="single_slider d-flex align-items-center" *ngIf="product_single.product_image1">
                                <div class="container">
                                    <div class="row">
                                        <img [src]="img_link+product_single.product_image1">

                                    </div>
                                </div>

                            </div>
                            <div class="single_slider d-flex align-items-center" *ngIf="product_single.product_image2">
                                <div class="container">
                                    <div class="row">
                                        <img [src]="img_link+product_single.product_image2">
                                    </div>
                                </div>
                            </div>
                            <div class="single_slider d-flex align-items-center" *ngIf="product_single.product_image3">
                                <div class="container">
                                    <div class="row">
                                        <img [src]="img_link+product_single.product_image3">
                                    </div>
                                </div>
                            </div>

                        </owl-carousel>


                    </div>

                    <div class="right-col">
                        <h1 itemprop="name">{{ product_single.product_name }}</h1>
                        <div class="star-rating" *ngIf="product_single.get_avg_rating!=null">
                            <span *ngFor="let u of uprate">
                            <input type="radio" id="5-stars" name="rating" />
                            <label for="5-stars" class="star">&#9733;</label>
                        </span>
                            <span *ngFor="let d of downrate">
                            <input type="radio" id="5-stars" name="rating" />
                            <label for="5-stars" class="star strsel">&#9733;</label>
                        </span>

                        </div>
                        <p class="review" data-toggle="modal" data-target="#myModal1" *ngIf="review_count!=0">{{ review_count }} reviews</p>
                        <!-- <button id="openModalButton" >Open Modal</button> -->
                        <!-- review modal -->
                        <div id="myModal1" class="modal fade" role="dialog">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-body modalbodyreview">
                                        <button type="button" class="close closebtntop" data-dismiss="modal">&times;</button>
                                        <div class="main_div">
                                            <div class="">
                                                <div class="cart_gallery">
                                                    <div class="cart_close">
                                                        <div class="cart_text heade">

                                                            <h5 class="reviewHead">Customer Reviews ({{ review_count }})</h5>

                                                        </div>
                                                        <div class="reviewDiv">
                                                            <div *ngFor="let rev of review">
                                                                <div class="sinrev" *ngIf="rev.comment!=null">

                                                                    <div class="row mainreview">
                                                                        <!-- {{ rev | json }} -->
                                                                        <div class="col-md-5">
                                                                            <!-- {{ rev.username }} -->
                                                                        </div>
                                                                        <div class="col-md-7">

                                                                            <div class="star-rating reviewrate" *ngIf="rev.rating!=null">

                                                                                <span *ngFor="let u of myarr;let i=index">
                                                                            <input *ngIf="i<rev.rating" type="radio" id="5-stars" name="rating" />
                                                                            <label *ngIf="i<rev.rating" for="5-stars" class="star">&#9733;</label>

                                                                            <input *ngIf="i>=rev.rating" type="radio" id="5-stars" name="rating" />
                                                                            <label *ngIf="i>=rev.rating" for="5-stars" class="star strsel">&#9733;</label>
                                                                           </span>
                                                                                <!-- <span *ngFor="let d of 5-rev.rating">
                                                                            <input type="radio" id="5-stars" name="rating" />
                                                                            <label for="5-stars" class="star strsel">&#9733;</label>
                                                                        </span> -->

                                                                            </div>

                                                                        </div>
                                                                        <div class="cmtsection">
                                                                            <p>
                                                                                {{ rev.comment }}
                                                                            </p>
                                                                        </div>
                                                                        <div class="row" style="display: contents;">
                                                                            <div class="col-md-8 cmtsectiondate">
                                                                                <!-- <div class="img"> <img class="userpic" src="../../assets/img/user.png"></div> -->
                                                                                <!-- <div class="img"> -->
                                                                                <p class="uname">{{ rev.username }} | 04/06/2021</p>
                                                                                <!-- </div> -->
                                                                            </div>
                                                                            <div clss="col-md-2 ">
                                                                                <img class="lik" src="../../assets/img/like.png">
                                                                            </div>
                                                                            <div clss="col-md-2">
                                                                                <img class="lik" src="../../assets/img/dislike.png">
                                                                            </div>
                                                                        </div>

                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <form id="AddToCartForm">

                                                                <div class="btn-and-quantity-wrap">
                                                                    <div class="btn-and-quantity abcpop">
                                                                        <div data-dismiss="modal" class="buy popupbtn" (click)="addProductBuy(product_single)" quickbeam="add-to-cart" *ngIf="getCount(product_single)
                                                                        <1&&checkStock(product_single)">
                                                                            <span id="wishText">  Buy Now</span>
                                                                        </div>
                                                                        <div data-dismiss="modal" class="wish popupbtn" (click)="addWishlist(product_single)" quickbeam="add-to-cart">
                                                                            <span id="wishText">  Wishlist</span>
                                                                        </div>
                                                                        <div data-dismiss="modal" class="spinner" *ngIf="getCount(product_single)>0">
                                                                            <span class="btn minus" data-id="2721888517" (click)="removeQty(product_single)"></span>

                                                                            <span class="q">{{ getCount(product_single) }}</span>
                                                                            <span></span>
                                                                            <span class="btn plus" (click)="addQty(product_single)" data-id="2721888517"></span>
                                                                        </div>

                                                                        <div data-dismiss="modal" id="AddToCart" class="popupbtn" (click)="addProduct(product_single)" *ngIf="getCount(product_single)
                                                                        <1&&checkStock(product_single)" quickbeam="add-to-cart">
                                                                            <span id="AddToCartText">Add to Cart</span>
                                                                        </div>
                                                                        <div data-dismiss="modal" id="AddToCart" class="errno" *ngIf="getCount(product_single)
                                                                        <1&&!checkStock(product_single)" quickbeam="add-to-cart">
                                                                            <span id="AddToCartText">Out of Stock</span>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <button type="button" class="btn btn-default alrt clsbtn" data-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- review modal Ends -->
                        <!-- Modal -->
                        <div id="myModal" class="modal fade" role="dialog">
                            <div class="modal-dialog">

                                <!-- Modal content-->
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="main_div">


                                            <div class="">

                                                <div class="cart_gallery">
                                                    <div class="cart_close">
                                                        <div class="cart_text">
                                                            <h3>Size Chart</h3>
                                                            <!-- <hr> -->
                                                        </div>
                                                    </div>


                                                    <!-- {{ size_chart | json }} -->
                                                    <table class="tab" *ngIf="product_single.dress_sizechart">
                                                        <tr>
                                                            <th>Size</th>
                                                            <th>
                                                                Chest (in)
                                                            </th>
                                                            <th>Front Length (in)</th>
                                                            <th>Across (in)</th>
                                                        </tr>
                                                        <tr *ngFor="let size of size_chart">
                                                            <td>
                                                                {{ size["size"] }}
                                                            </td>
                                                            <td>
                                                                {{ size["Chest"] }}
                                                            </td>
                                                            <td>
                                                                {{ size["Front Lenth"] }}
                                                            </td>
                                                            <td> {{ size["Across SHoulder"] }}</td>
                                                        </tr>




                                                    </table>
                                                    <table class="tab" *ngIf="product_single.footware_sizechart">
                                                        <tr>
                                                            <th>Country</th>
                                                            <th>
                                                                Size
                                                            </th>
                                                            <th>Foot length</th>

                                                        </tr>
                                                        <tr *ngFor="let size of size_chartf">
                                                            <td>
                                                                {{ size["country"] }}
                                                            </td>
                                                            <td>
                                                                {{ size["size"] }}
                                                            </td>
                                                            <td>
                                                                {{ size["Foot_length"] }}
                                                            </td>

                                                        </tr>




                                                    </table>


                                                </div>



                                            </div>


                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div itemprop="offers">
                            <meta itemprop="priceCurrency" content="USD">
                            <link itemprop="availability">
                            <div class="price-shipping">
                                <div class="price" id="price-preview" quickbeam="price" quickbeam-price="800">
                                    ₹{{ product_single.sales_rate }}
                                </div>
                                <!-- <a *ngIf="find_charge(product_single.pincode)==0">Free shipping</a> -->
                                <!-- <a *ngIf="find_charge(product_single.pincode)!=0">+ {{ find_charge(product_single.pincode) }} shipping charge</a> -->
                                <a *ngIf="product_single.delivery_charge==0||product_single.delivery_charge==null||product_single.delivery_charge==''">Free shipping</a>
                                <a *ngIf="product_single.delivery_charge!=0&&product_single.delivery_charge!=null&&product_single.delivery_charge!=''">+ {{ product_single.delivery_charge }} shipping charge</a>
                            </div>
                            <div class="swatches">

                                <div class="swatch clearfix" data-option-index="1" *ngIf="(product_single.variance | json) != '{}'&&product_single.variance!=null&&(product_single.variance | json) != '[]'">

                                    <div class="header">SELECT SIZE</div>


                                    <span #sizeError id="errss"></span>
                                    <div class="s1g5h5-0 liBlNh row" *ngFor="let vari of product_single.variance">
                                        <div class="s1g5h5-1 cqMWqX" *ngFor="let vari1 of vari.name?.split(',') ;  let i=index">
                                            <button type="button" class="size_btn" (click)="changeSize(vari1)" class="{{vari1==orgvr ? 'sactive' : ''}}">{{ vari1 }}</button>
                                        </div>

                                    </div>
                                </div>
                                <div class="guide" *ngIf="(product_single.variance | json) != '{}'&&product_single.variance!=null&&(product_single.variance | json) != '[]'">
                                    <a data-toggle="modal" data-target="#myModal">
                                        <div class="header_chart">SIZE CHART <i class="arrow right"></i></div>
                                    </a>
                                </div>


                            </div>

                            <form id="AddToCartForm">

                                <div class="btn-and-quantity-wrap">
                                    <div class="btn-and-quantity">
                                        <div class="buy" (click)="addProductBuy(product_single)" quickbeam="add-to-cart" *ngIf="getCount(product_single)
                                        <1&&checkStock(product_single)">
                                            <span id="wishText">  Buy Now</span>
                                        </div>
                                        <div class="wish" (click)="addWishlist(product_single)" quickbeam="add-to-cart">
                                            <span id="wishText">  Wishlist</span>
                                        </div>
                                        <div class="spinner" *ngIf="getCount(product_single)>0">
                                            <span class="btn minus" data-id="2721888517" (click)="removeQty(product_single)"></span>

                                            <span class="q">{{ getCount(product_single) }}</span>
                                            <span></span>
                                            <span class="btn plus" (click)="addQty(product_single)" data-id="2721888517"></span>
                                        </div>

                                        <div id="AddToCart" (click)="addProduct(product_single)" *ngIf="getCount(product_single)
                                        <1&&checkStock(product_single)" quickbeam="add-to-cart">
                                            <span id="AddToCartText">Add to Cart</span>
                                        </div>
                                        <div id="AddToCart" class="errno" *ngIf="getCount(product_single)
                                        <1&&!checkStock(product_single)" quickbeam="add-to-cart">
                                            <span id="AddToCartText">Out of Stock</span>
                                        </div>

                                    </div>
                                </div>
                            </form>
                            <div class="Address-switcher-container">

                                <div class="Address-address-box Address-pincode-input Address-pdp-box">
                                    <input type="text" placeholder="Enter a PIN code" id="availcheck" autocomplete="off" #pincode name="pincode">
                                    <button type="submit" class="Address-address-button" (click)="check_availability(product_single.pincode,pincode.value)" style="color: #086fcf">
                                        CHECK
                                    </button>
                                </div>
                                <small class="err" id="err"></small>
                                <br>
                                <small class="err1" id="err1"></small>
                            </div>
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Info</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Highlights</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Return Policy</a>
                                </li>
                            </ul>
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">{{ product_single.product_description }}</div>
                                <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">{{ product_single.highlights }}</div>
                                <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">{{ product_single.return_policy }}</div>
                            </div>
                            <div class="social-sharing-btn-wrapper">
                                <span id="social_sharing_btn">Share</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <section class="ad" *ngIf="product_single.banner1!=''&&product_single.banner1!=null">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <img [src]="img_link+product_single.banner1" width="100%">
                </div>
            </div>
        </div>
    </section>

    <div class="container-fluid">
        <div class="row bnr">
            <div class="col-md-12">
                <div class="container csheader">
                    <header class="jsx-3400163498 hd">
                        <!-- <h3 class="jsx-3400163498">Recommended For You</h3> -->
                        <h1>Recommended For You</h1>
                    </header>
                </div>
                <owl-carousel class="rec" [options]="{items: 6, dots: true, navigation: true}" [items]="products" [carouselClasses]="['owl-theme', 'row', 'sliding']">
                    <div class="col-lg-12 slider_ed1" *ngFor="let pro of products">
                        <div class="thumb-wrapper">
                            <div class="img-box">
                                <img [src]="img_link+pro.product_image1 " [routerLink]="[ '/product-details',pro.id,pro.product_name ] " class="img-responsive img-fluid imgg" alt="">
                            </div>
                            <div class="jsx-2061857635 discountFulfilmentRow">
                                <div class="jsx-2061857635 discountFulfilmentContent">
                                    <div class="jsx-2061857635 fulfilmentContainer">
                                        <div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="thumb-content">
                                <span class="items-names" [routerLink]="[ '/product-details',pro.id,pro.product_name ] ">{{ pro.product_name }}
                                
                                </span>

                                <div class="jsx-193278340 container" *ngIf="getWish(pro)<1">
                                    <img class="wishlist" (click)="addWishlist(pro)" src="../../assets/img/wishblack.png" />
                                    <!-- <img src="https://k.nooncdn.com/s/app/2019/noon-bigalog/458fc09acbbb401ca3536a0aa1221bc4e73c7bff/static/images/noon-express-en.png" alt="noon-express" class="jsx-193278340 fbn"> -->
                                </div>
                                <div class="jsx-193278340 container" *ngIf="getWish(pro)>0">
                                    <img class="wishlist" (click)="addWishlist(pro)" src="../../assets/img/wishred.png" />
                                    <!-- <img src="https://k.nooncdn.com/s/app/2019/noon-bigalog/458fc09acbbb401ca3536a0aa1221bc4e73c7bff/static/images/noon-express-en.png" alt="noon-express" class="jsx-193278340 fbn"> -->
                                </div>
                                <p class="jsx-4251264678 price">
                                    <span class="jsx-4251264678 sellingPrice">
                                        <span>
                                            <span class="currency medium">₹</span>
                                    <span class="value">{{ pro.sales_rate }}</span></span>
                                    </span>
                                    <span class="jsx-4251264678 preReductionPrice">
                                        <span>
                                            <span class="currency null"> ₹</span>
                                    <span class="value">{{ pro.price }}</span>
                                    </span>

                                    </span>
                                    <span class="jsx-2467480612 discountTag"><span
                                        class="jsx-2467480612 bg"></span><span class="jsx-2467480612 ">{{ pro.offer }}% Off</span></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </owl-carousel>
            </div>
        </div>
    </div>
    <section class="ad" *ngIf="product_single.banner2!=''&&product_single.banner2!=null">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <img [src]="img_link+product_single.banner2" width="100%">
                </div>
            </div>
        </div>
    </section>
    <div class="abc" id="snackbar"></div>


</section>