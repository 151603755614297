import { PaymentService } from './../services/payment.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { CartService } from '../services/cart.service';
import { CategoryService } from '../services/category.service';
import { ConfigService } from '../services/config.service';
import { ProductsService } from '../services/products.service';
import { WindowRefService } from '../window-ref.service';
// import {RazorpayWrapper} from '../utils/razorpay/wrapper';
declare const RazorpayCheckout:any;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
  countsub = 0;
  countdelivery=0;
  paymentAmount:number=333;
  currency:string='INR';
  currencyIcon:string='₹';
  payment_selection="";
  rzp1:any;
  codavailable=true;
  razorePay = false;
  cod = false;


  // razor_key:string='rzp_live_j7MK958PScCHGh';
  // razor_key:string='rzp_test_JtPKiwSMjSHAwi';
  razor_key:string = 'rzp_live_j7MK958PScCHGh';
  oder_details="";
  vis=false;
  delUser={address:''};
  productbefore=[];
  products=[];
  product_single:any;
  private sub:any;
  p_id;
  name;
  category:any;
  pincode;
  added;
  addedno;
  addBtn;
  addedBtn;
  cart:any;
  cart_item_id=[];
  updatedqty=1;
  sum=0;
  constructor(private _product:ProductsService,
    private _auth:AuthService,
    private route: ActivatedRoute,
    private router:Router,
    private _category:CategoryService,
    private _cart:CartService,
    private fb:FormBuilder,
    private _config:ConfigService,
    private winRef: WindowRefService,
    private paymentService:PaymentService
    ) { }
  img_link=this._config.config_url.slice(0, -1);
  ngOnInit(): void {
    this._config.chack_netStatus();
    if(localStorage.getItem('location')!==null)
    {
     this.pincode=localStorage.getItem('location');
    }

    this.cart=JSON.parse(localStorage.products);
    for (let i=0;i<this.cart.length;i++)
    {
      this.sum=this.sum+this.cart[i].subtotal;
      if(this.cart[i].delivery_charge!=null&&this.cart[i].delivery_charge!='')
      {
        this.countdelivery=this.countdelivery+parseInt(this.cart[i].delivery_charge);
      }
    }

    this.sub = this.route.params.subscribe(params => {
    this._product.getAllProducts().subscribe((data)=>{
    this.products=JSON.parse(JSON.stringify(data));
    });
   });
this.category=JSON.parse(localStorage.getItem('category'));
JSON.parse(localStorage.getItem('category'));

this.category=JSON.parse(localStorage.getItem('category'));
console.log()
  this.cart_item_id=this._cart.getAddedItems();
  if(  this.cart_item_id == null){
    this.cart_item_id=[];
  }
console.log( this.cart_item_id+"this is cart id");

this._category.getCategories().subscribe((data) => {
  this.category = JSON.parse(JSON.stringify(data));
});
// this.setCOD();
  }


check_availability(pin_array)
{
  var arr = [];
for (var i = 0; i < pin_array.length; i++) {
    arr.push(pin_array[i]);
}
if((arr.indexOf(this.pincode) > -1))
{
  return true;
}
else
{
  return false;
}


}
addQty(product)
  {

    if (localStorage.getItem("user_id") === null) {
     this.updatedqty= this._cart.addtoCartQty(product);
    }
    else
    {
      this.updatedqty= this._cart.addtoCartQty(product);
      this._cart.addtoCartLoggedInQty(product).subscribe((data)=>{

        // this.category=JSON.parse(JSON.stringify(data));
    });
    }
}

removeQty(product)
  {
    if(this.getCount(product)>1)
    {

    if (localStorage.getItem("user_id") === null) {
      this.updatedqty=this._cart.removetoCartQty(product);

    }
    else
    {
      this.updatedqty= this._cart.removetoCartQty(product);
      this._cart.addtoCartLoggedIn(product).subscribe((data)=>{
    });
    }

  }
  else
  {
    let storageProducts = JSON.parse(localStorage.getItem('products'));
    if(storageProducts.filter(product1 => product1.productId == product.id))
    {
    let removeproget = storageProducts.filter(product1 => product1.productId == product.id);
		let myId=removeproget[0].order_detail_id;
    this._cart.removeCartLogin(myId).subscribe((data) => { });
    this.removeProduct(product);
    }
  }

}

removeProduct(product) {
  // debugger;
  if (localStorage.getItem('user_id')) {
    let storageProducts = JSON.parse(localStorage.getItem('products'));
    let removeproget = storageProducts.filter(product1 => product1.productId == product.id);
    let myId=removeproget[0].order_detail_id;
    this._cart.removeCartLogin(myId).subscribe((data) => { });

  }
  this._cart.removeCartFromHome(product).subscribe((data) => {
    this.ngOnInit();
    let d = data;
  });
  this._cart.getCartCountSub().subscribe((data) => {

    this.countsub = JSON.parse(JSON.stringify(data));
  });
  this.ngOnInit();
}




changeOrderStatus()
{
  var arr={};



     var tot=0;
    let ct = JSON.parse(localStorage.getItem("products")).length;
    let subtotal=0;
    var order_id;
  for (let i = 0; i < ct; i++) {

      var qty = parseInt(JSON.parse(localStorage.getItem("products"))[i].qty) + 0;
      var price = parseInt(JSON.parse(localStorage.getItem("products"))[i].price);
       order_id = JSON.parse(localStorage.getItem("products"))[i].order_id ;
      if(qty==0)
      {
        qty=1;
      }
      subtotal = subtotal + (price*qty);
      var vendor = JSON.parse(localStorage.getItem("products"))[i].vendor;
      tot=tot+subtotal;

      var arr={};
      this._cart.updateOrder("COD",false,tot,order_id,vendor).subscribe((data)=>{
       
        if(i==(ct-1))
        {
          console.log(localStorage.getItem('products')+"this is before remove section");
          localStorage.removeItem('products');
          window.location.href="/order-success";
        }
      });

     
  }

}
getCODData(crt)
{

  for(let i=0;i<this.products.length;i++)
  {
    console.log(this.products[i]);
    if(this.products[i].id==crt.productId)
    {
      console.log(this.products[i]);
      if(this.products[i].cod==false)
      {
        this.codavailable=false;
        this.payment_selection = "";

        return "Cash on Delivery not Available";
      }

      // if(this.products[i].delivery_days!=""||this.products[i].delivery_days!=null)
      // {
      // return "Delivery within "+this.products[i].delivery_days + " days";
      // }
    }
  }

}
  getCount(pro)
      {
        if(localStorage.getItem("products"))
        {
        let sel = JSON.parse(localStorage.getItem("products")).filter(
          (products) => products.productId === pro.productId
        );
        if(sel!=""&&sel!=null)
        {
          return sel[0].qty;
        }
        else
        {
          return 0;
        }
      }
      else
      {
        return 0;
      }
      }
      check_cartStatus(id)
      {
        if(this.cart_item_id.includes(id))
        {

          return true;
        }
        else
        {

          return false;
        }
      }



      payWithRazor() {
        let total = (this.sum+this.countdelivery)*100;
        console.log('====================================');
        console.log(total,"newwww");
        console.log('====================================');
        this.paymentService.getOrderDetails(this.countdelivery).subscribe((data:any)=>{
         let options = {
            "key": this.razor_key,
            "amount": total,
            "currency": "INR",
            "name": "Flubet",
            "description": "Flubet Payment",
            "image": '../../assets/img/logo01.png',
            "order_id":data.razorpay_id,
            "handler":  (response)=>{
              this.paymentService.succesHandler(response).subscribe((dtaa)=>{

                localStorage.removeItem('products');
                this.goNew();
              });
            },
            "prefill": {
              email:'',
                 contact: localStorage.getItem('username'),
               name: localStorage.getItem('username')
            },
            "theme": {
              color: '#3866de'
            },
            modal: {
              ondismiss: function () {
                alert('dismissed')
              }
            },
        };
            let ct = JSON.parse(localStorage.getItem("products")).length;
            let subtotal=0;
            var order_id;
                 var cancelCallback = function (error) {
            alert(error.description + ' (Error ' + error.code + ')');
          };

      this.rzp1 = new this.winRef.nativeWindow.Razorpay(options);
          this.rzp1.open();

        })

      }
goNew()
{
  window.location.href="/order-success";
}

      setRazor()
      {
        console.log('setting rezore');
        this.razorePay = true;
        this.payment_selection=="RAZOR";

        document.getElementById("payerror").innerHTML="";
        document.getElementById('paymentsel').style.border = "3px solid #3866df";
        document.getElementById('paymentselcod').style.border = "none";
      }
      setCOD()
      {
        this.cod = true;
        this.payment_selection="COD";
        document.getElementById("payerror").innerHTML="";
        document.getElementById('paymentsel').style.border = "none";
        document.getElementById('paymentselcod').style.border = "3px solid #3866df";
      }
      pay()
      {
        console.log('====================================');
        console.log(this.payment_selection);
        console.log('====================================');
        //No value in payment_selection
      //  if(this.payment_selection=="RAZOR")
      //@ author akhil
      if(this.razorePay==true)
        {
          document.getElementById("payerror").innerHTML="";
          this.payWithRazor();
        }
        else if(this.cod==true)
        {
          document.getElementById("payerror").innerHTML="";
          this.changeOrderStatus();
        }
        else
        {
          document.getElementById("payerror").innerHTML="* Select a payment method";
        }
      }
}
