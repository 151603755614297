import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router,ActivatedRoute, ParamMap } from '@angular/router';
import { CartService } from '../services/cart.service';
import { FormBuilder,Validators } from '@angular/forms';
import { ConfigService } from '../services/config.service';
import { ProductsService } from '../services/products.service';
import { OrderService } from '../services/order.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {
  login_status = false;
  countsub = 0;
  countdelivery = 0;
  cart:any;
  count=0;
  oder_details="";
  productbefore=[];
  products:any;
  product_single:any;
  product_single1:any;
  private sub:any;
   p_id;
   name;
   category:any;
   pincode;

   added;
   addedno;
     addBtn;
     addedBtn;
     cart_item_id=[];
     sum=0;
     savedAddr;

     orderId="";
     scharge=0;

  updatedqty = 1;
  constructor(private _product:ProductsService,
    private router:Router,
    private _cart:CartService,
    private fb:FormBuilder, 
    private _config:ConfigService,private _order:OrderService

    ) { }
  img_link=this._config.config_url.slice(0, -1);
  ngOnInit() {
    this._config.chack_netStatus();
    if (localStorage.getItem("user_id") === null) {
			this.login_status = false;
		} else {
			this.login_status = true;
		}
    this.cart=JSON.parse(localStorage.products);
    if(this.cart)
    {
      for(let i=0;i<this.cart.length;i++)
      {
        console.log(this.cart.length);
        this._product.getSingleProducts(this.cart[i].productId).subscribe((data)=>{
          let product_s=JSON.parse(JSON.stringify(data));
          this.product_single1= product_s[0];
          console.log(this.product_single1);
          if(this.product_single1.delivery_charge!=0&&this.product_single1.delivery_charge!=null&&this.product_single1.delivery_charge!='')
          {
            console.log(this.product_single1.delivery_charge);
            this.scharge=this.scharge+parseFloat(this.product_single1.delivery_charge);
          }

        })
      }
    }
    if(localStorage.getItem('location')!==null)
    {
     this.pincode=localStorage.getItem('location');
    }

    this._product.getAllProducts().subscribe((data)=>{

      this.products=JSON.parse(JSON.stringify(data));
     
    
      
  });
  this.countsub=0;
  this.countdelivery=0;
  this.sum=0;
  for (let i=0;i<this.cart.length;i++)
  {
   
    this.countsub=this.countsub+(this.cart[i].price*this.cart[i].qty);
    this.sum=this.sum+(this.cart[i].price*this.cart[i].qty);
  if(this.cart[i].delivery_charge!=null&&this.cart[i].delivery_charge!='')
  {
    console.log(this.cart[i].delivery_charge+"1 time");
    this.countdelivery=this.countdelivery+parseInt(this.cart[i].delivery_charge);
  }
  
   
  }

if(localStorage.getItem('user_id')!=null)
{
  this._order.customerData(localStorage.getItem('user_id')).subscribe((data)=>{
    this.savedAddr=JSON.parse(JSON.stringify(data));
    this.savedAddr=this.savedAddr[0];
        
  });

}

this.cart_item_id = this._cart.getAddedItems();
    if (this.cart_item_id == null) {
      this.cart_item_id = [];
    }

  }
  removeProduct(product)
  {
    // this._cart.removeCart(product);
  
    this._cart.removeCart(product).subscribe((data)=>{
    
    this.cart=JSON.parse(JSON.stringify(data));
    this.count=JSON.parse(localStorage.products).length;
   
    this._cart.getCartCountSub().subscribe((data) => {

      this.countsub = JSON.parse(JSON.stringify(data));
      this._cart.getCartCountDelivery().subscribe((data) => {

        this.countdelivery = JSON.parse(JSON.stringify(data));
      });
    });
    
  });
  
  this.ngOnInit();
  }
  addQty(product) {
    if(this.getCount(product)>0)
    {
    if (localStorage.getItem("user_id") === null) {
      this.updatedqty = this._cart.addtoCartQtyCart(product);
     
      
    } else {
      this.updatedqty = this._cart.addtoCartQtyCart(product);
     
      this._cart.addtoCartLoggedInQtyFromCart(product).subscribe((data) => {
      });
    }
    this.ngOnInit();
      this._cart.getCartCountSub().subscribe((data) => {

        this.countsub = JSON.parse(JSON.stringify(data));
        this._cart.getCartCountDelivery().subscribe((data) => {

          this.countdelivery = JSON.parse(JSON.stringify(data));
          this.ngOnInit();
        });
        this.ngOnInit();
      });
      
    }
  }
  removeQty(product) {
    if(this.getCount(product)>1)
    {
    if (localStorage.getItem("user_id") === null) {
      this.updatedqty = this._cart.removetoCartQtyCart(product);

    }
    else {
      this.updatedqty = this._cart.removetoCartQtyCart(product);
      this._cart.removetoCartLoggedInQtyFromCart(product).subscribe((data) => {
      });
    }
    this.ngOnInit();
    this._cart.getCartCountSub().subscribe((data) => {

			this.countsub = JSON.parse(JSON.stringify(data));
      this._cart.getCartCountDelivery().subscribe((data) => {

        this.countdelivery = JSON.parse(JSON.stringify(data));
        this.ngOnInit();
      });
       this.ngOnInit();
		});
  }
  else
  {
    
    let storageProducts = JSON.parse(localStorage.getItem('products'));
    if(storageProducts.filter(product1 => product1.productId == product.id))
    {
    let removeproget = storageProducts.filter(product1 => product1.productId == product.id);
		let myId=removeproget[0].order_detail_id;
    let myOId=removeproget[0].order_id;
    this._cart.removeOrder(myOId).subscribe((data) => { });
    this.removeProduct1(product);
    }
    this.ngOnInit();
    this._cart.getCartCountSub().subscribe((data) => {

			this.countsub = JSON.parse(JSON.stringify(data));
      this._cart.getCartCountDelivery().subscribe((data) => {

        this.countdelivery = JSON.parse(JSON.stringify(data));
        this.ngOnInit();
      });
      this.ngOnInit();
		});
  }
  }
  removeProduct1(product) {
		// debugger;
		if (localStorage.getItem('user_id')) {
      let storageProducts = JSON.parse(localStorage.getItem('products'));
      let removeproget = storageProducts.filter(product1 => product1.productId == product.productId);
      let myId=removeproget[0].order_detail_id;
		
      let myOId=removeproget[0].order_id;
			this._cart.removeOrder(myOId).subscribe((data) => { 

        this._cart.removeCart(product).subscribe((data) => {
          this._cart.getCartCountSub().subscribe((data) => {
    
            this.countsub = JSON.parse(JSON.stringify(data));
            this._cart.getCartCountDelivery().subscribe((data) => {

              this.countdelivery = JSON.parse(JSON.stringify(data));
              this.ngOnInit();
            });
            // this.ngOnInit();
          });
          // this.ngOnInit();
          let d = data;
        
          
          
        });
        this.ngOnInit();
      });
      // this.ngOnInit();
		}
    else
    {
      this._cart.removeCart(product).subscribe((data) => {
        this._cart.getCartCountSub().subscribe((data) => {
  
          this.countsub = JSON.parse(JSON.stringify(data));
          this._cart.getCartCountDelivery().subscribe((data) => {

            this.countdelivery = JSON.parse(JSON.stringify(data));
            this.ngOnInit();
          });
          // this.ngOnInit();
        });
        // this.ngOnInit();
        let d = data;
      
       
        
      });
      this.ngOnInit();
    }
	
		
 
	
	}


  getCount(pro)
      {
        if(localStorage.getItem("products"))
        {

     
        let sel = JSON.parse(localStorage.getItem("products")).filter(
          (products) => products.productId === pro.productId
        );
        if(sel!=""&&sel!=null)
        {
          return sel[0].qty;
        }
        else
        {
          return 0;
        }
      }
      else
      {
        return 0;
      }
      }

      check_cartStatus(id)
      {
        if(this.cart_item_id.includes(id))
        {
          
          return true;
        }
        else
        {
          
          return false;
        }
      }
      checkout()
      {
        if(this.login_status)
        {
          this.router.navigate(['/shipping']);
        }
        else
        {
          localStorage.setItem('err_sts','Login to place your order');
          this.router.navigate(['/login']);
          //document.getElementById('err').innerHTML="Login to place your order"
        }
      }


      
}
