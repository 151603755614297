import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from '../services/cart.service';
import { ConfigService } from '../services/config.service';
import { OrderService } from '../services/order.service';
import { ProductsService } from '../services/products.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {
  @Input() order_id;
  id;
userRate=0;
  order:any;
  product=[];
  orders_qty=[];
  orders_price=[];
  img_link=this._config.config_url.slice(0, -1);
  orders_netweight=[];
selectRate=0;
selectPid=0;
  orgvr;
orderId="";
oder_details="";
  added;
  addedno;
  addBtn;
  addedBtn;
currentpid;
  pcount=[];
  banners = [];
  productbefore = [];
  private sub: any;
  p_id;
  cart: any;
  cart_item_id = [];
  name;
  category: any;
  banner: any;
  updatedqty = 1;
  pincode;
  banner2;
  banner3;
  banner4;
  banner5;
  banner1_slider1;
  banner1_slider2;
  banner1_slider3;

 product_count=0;
  banner2_linkid;
  banner3_linkid;
  banner4_linkid;
  banner5_linkid;
  banner1_slider1_linkid;
  banner1_slider2_linkid;
  banner1_slider3_linkid;

  banner2_linkname;
  banner3_linkname;
  banner4_linkname;
  banner5_linkname;
  banner1_slider1_linkname;
  banner1_slider2_linkname;
  banner1_slider3_linkname;
  dummyarray=[1,2,3,4];
  countsub=0;
  upQty=[];
  login_status=false;
  constructor(private _cart:CartService,private _product:ProductsService,private _config:ConfigService,private _order:OrderService,private _router:Router) { }

  ngOnInit() {
    this._config.chack_netStatus();
    this.id=this.order_id;
    console.log('====================================');
    console.log(this.id,"????????????????????");
    console.log('====================================');
    this._cart.getSingleOrder(this.id).subscribe((data:any)=>{

      this.order=data;
     // this.product_single= product_s[0];
     // console.log(this.product_single);
     console.log("tbis is data",data);
     console.log(this.order[0].orderdetails);


     if(this.order[0].orderdetails.length>0)
     {

     for(let i=0;i<this.order[0].orderdetails.length;i++)
     {

       this._product.getSingleProducts(this.order[0].orderdetails[i].product_id).subscribe((data)=>{

        console.log('====================================');
        console.log(data);
        console.log('====================================');
         let product_s=JSON.parse(JSON.stringify(data));
         this.product.push(product_s[0]);
         console.log('====================================');
        console.log(this.product,"produ>>>??");
        console.log('====================================');

         this.orders_qty.push(this.order[0].orderdetails[i].product_qty);
         this.orders_price.push(this.order[0].orderdetails[i].product_price * this.order[0].orderdetails[i].product_qty);
         // this.orders_netweight.push(this.order[0].orderdetails[i].net_weight);






 })

 }
}

})


  }
  getRating(pid)
  {

    this.currentpid=pid;

    this._order.singleUserRating(pid,localStorage.getItem('user_id')).subscribe((data) => {
      let rateData=JSON.parse(JSON.stringify(data));
      this.userRate=rateData[0].rating;
      return this.userRate;
  });
  }



  addQty(product)
  {

    if (localStorage.getItem("user_id") === null) {
     this.updatedqty= this._cart.addtoCartQty(product);
      // alert("item added to cart" + qty);
    }
    else
    {
      this.updatedqty= this._cart.addtoCartQty(product);
      this._cart.addtoCartLoggedInQty(product).subscribe((data)=>{

        // this.category=JSON.parse(JSON.stringify(data));
    });
    }



}

removeQty(product)
  {
    if(this.getCount(product)>1)
    {

    if (localStorage.getItem("user_id") === null) {
      this.updatedqty=this._cart.removetoCartQty(product);

    }
    else
    {
      this.updatedqty= this._cart.removetoCartQty(product);
      this._cart.addtoCartLoggedIn(product).subscribe((data)=>{

        // this.category=JSON.parse(JSON.stringify(data));
    });
    }

  }
  else
  {
    let storageProducts = JSON.parse(localStorage.getItem('products'));
    if(storageProducts.filter(product1 => product1.productId == product.id))
    {
    let removeproget = storageProducts.filter(product1 => product1.productId == product.id);
    let myId=removeproget[0].order_detail_id;
    this._cart.removeCartLogin(myId).subscribe((data) => { });
    this.removeProduct(product);
    }
  }

}
removeProduct(product) {
  // debugger;
  if (localStorage.getItem('user_id')) {
    let storageProducts = JSON.parse(localStorage.getItem('products'));
    let removeproget = storageProducts.filter(product1 => product1.productId == product.id);
    let myId=removeproget[0].order_detail_id;
    // for (let i = 0; i < JSON.parse(localStorage.getItem('orderDetailsId')).length; i++) {
    // 	if (JSON.parse(localStorage.getItem('products')).productId == JSON.parse(localStorage.getItem('orderDetailsId'))[i].orderPid) {
    // 		myId = JSON.parse(localStorage.getItem('orderDetailsId'))[i].orderDetailsId;
    // 	}
    // }

    this._cart.removeCartLogin(myId).subscribe((data) => { });

  }
  this._cart.removeCartFromHome(product).subscribe((data) => {
    // let currentUrl = this.router.url;
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    // this.router.onSameUrlNavigation = 'reload';
    // this.router.navigate([currentUrl]);
    this.ngOnInit();
    let d = data;
    // debugger;
    // this.cart=JSON.parse(JSON.stringify(data));
    // this.count=JSON.parse(localStorage.products).length;


  });
  this._cart.getCartCountSub().subscribe((data) => {

    this.countsub = JSON.parse(JSON.stringify(data));
  });
  // this.router.navigate(["product-details/"+this.p_id+"/"+this.name]).then(() => {
  //   window.location.reload();
  // });
  // this.router.navigate(["product-details/"+this.p_id+"/"+this.name]);
  this.ngOnInit();

}


getCount(pro)
      {
        if(localStorage.getItem("products"))
        {
        let sel = JSON.parse(localStorage.getItem("products")).filter(
          (products) => products.productId === pro.id
        );
        if(sel!=""&&sel!=null)
        {
          return sel[0].qty;
        }
        else
        {
          return 0;
        }
      }
      else
      {
        return 0;
      }
      }



changeSize(val)
{
  this.orgvr=val;
}

check_cartStatus(id)
{
  if(this.cart_item_id.includes(id))
  {

    return true;
  }
  else
  {

    return false;
  }
}
addWishlist(product)
{
  // alert("called me");
  if(this._cart.addtoWishlist(product,0,0)==0)
    {
      // alert('Item already in your wishlist');
      let msg="Item already in your wishlist";
      document.getElementById("snackbar").innerHTML=msg;
      var x = document.getElementById("snackbar");
      x.className = "show";
     setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);

    }
  else
  // (this._cart.addtoWishlist(product,0)==1)
    {
      let msg="Product Added to wishlist"
      this.addBtn = "1";
      document.getElementById("snackbar").innerHTML=msg;
      var x = document.getElementById("snackbar");
      x.className = "show";
     setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
    }
}

selectRating(rate,pid)
{
  // this._order.addReview(rate,pid).subscribe((data) => {
  //   document.getElementById("openModalButton").click();
  // this.ngOnInit();

  // });
  this.selectRate=rate;
  this.selectPid=pid;

}
changeStatus(id,status)
{
  var arr={};
  // this._cart.updateOrderStatus( this.order,status);
    this._cart.updateOrderStatus( this.order,status).subscribe((data) => {

this.ngOnInit();

  });
}
addReview(val)
{
  console.log(val.value);
  if(this.selectRate==0||this.selectRate==null)
  {
    this.selectRate=this.userRate
  }
  if(this.selectPid==0||this.selectPid==null)
  {
    this.selectPid=this.currentpid
  }
  this._order.addReviewComment(this.selectRate,this.selectPid,val.value).subscribe((data) => {
    document.getElementById("openModalButton").click();
    window.location.reload();

  });

}
}
