import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { HttpParams } from "@angular/common/http";
import { ConfigService } from './config.service';
import { Subject } from 'rxjs';
import { formatDate } from '@angular/common';
import { Observable } from 'rxjs';
import { shareReplay, map } from 'rxjs/operators';
const CACHE_SIZE = 1;

@Injectable({
  providedIn: 'root'
})
export class CartService {
  private cacheOrder$: Observable<any>;
  total=0;
  delivery=0;
  pref;
  proget = [];
 progetwish = [];
   paramsctord: any;
  datas: any;
  removeproget;
  cart_item_id = [];
  simpleObservable = new Subject();
  simpleObservable$ = this.simpleObservable.asObservable();
  simpleObservableAddedItems = new Subject();
  simpleObservablecounteAddedItems$ = this.simpleObservableAddedItems.asObservable();
  simpleObservablecount = new Subject();
  simpleObservablecount$ = this.simpleObservablecount.asObservable();

  observableCategory = new Subject();
  observableCategory$ = this.observableCategory.asObservable();

  observableCategoryRight = new Subject();
  observableCategoryRight$ = this.observableCategoryRight.asObservable();

  simpleObservablecountsub = new Subject();
  simpleObservablecountsub$ = this.simpleObservablecountsub.asObservable();

  simpleObservablecountDelivery = new Subject();
  simpleObservablecountDelivery$ = this.simpleObservablecountDelivery.asObservable();
  private _addressUrl = "client_api/update/";
  constructor(private http: HttpClient, private _url: ConfigService, private link: ConfigService) { }
  addtoCart(product,order_detail_id,order_id) {
    let products = [];
    this.total = 0;
    if (localStorage.getItem('products')) {
      this.proget = JSON.parse(localStorage.getItem('products'));
    }
    let price=0;
    if(product.sales_rate!=null)
    {
      price=product.sales_rate;
    }
    else
    {
      price=product.price;
    }
    if(product.variance[0])
    {
      this.pref=product.variance[0].preference;
    }
    else
    {
      this.pref="";
    }
    this.proget.push({ 'productId': product.id, 'product_name': product.product_name, 'image': product.product_image1, 'price': price, 'qty': '1', 'subtotal': price, 'vendor': product.vendor, 'Net_weight': product.Net_weight, 'unit': product.unit, 'order_detail_id':order_detail_id,'order_id':order_id,'variance':product.varianceOrg,'preference':this.pref,'delivery_charge':product.delivery_charge  });
    localStorage.setItem('products', JSON.stringify(this.proget));
    this.simpleObservable.next(this.proget);
    let cnt = 0;
    for (let i = 0; i < JSON.parse(localStorage.getItem('products')).length; i++) {
      cnt = cnt + parseInt(JSON.parse(localStorage.getItem("products"))[i].qty);
    }
    this.simpleObservablecount.next(cnt);
   this.total = 0;
   this.delivery = 0;
    for (let i = 0; i < JSON.parse(localStorage.getItem('products')).length; i++) {
      // total = total + JSON.parse(localStorage.getItem("products"))[i].subtotal;
      this.total = this.total + (parseInt(JSON.parse(localStorage.getItem("products"))[i].price)*parseInt(JSON.parse(localStorage.getItem("products"))[i].qty));
    }
    for (let i = 0; i < JSON.parse(localStorage.getItem('products')).length; i++) {
      // total = total + JSON.parse(localStorage.getItem("products"))[i].subtotal;
      if(JSON.parse(localStorage.getItem("products"))[i].delivery_charge!=""&&JSON.parse(localStorage.getItem("products"))[i].delivery_charge!=null)
      {
      this.delivery = this.delivery + (parseInt(JSON.parse(localStorage.getItem("products"))[i].price)*parseInt(JSON.parse(localStorage.getItem("products"))[i].qty));
      }
    }
    this.simpleObservablecountsub.next(this.total);
    this.simpleObservablecountDelivery.next(this.delivery);
    return true;
  }


  addWishlisttoCart(product,order_detail_id,order_id) {
    let products = [];
    this.total = 0;
    if (localStorage.getItem('products')) {
      this.proget = JSON.parse(localStorage.getItem('products'));
    }
    let price=0;
    if(product.sales_rate!=null)
    {
      price=product.sales_rate;
    }
    else
    {
      price=product.price;
    }
    if(product.variance[0])
    {
      this.pref=product.variance[0].preference;
    }
    else
    {
      this.pref="";
    }
    this.proget.push({ 'productId': product.productId, 'product_name': product.product_name, 'image': product.product_image1, 'price': price, 'qty': '1', 'subtotal': price, 'vendor': product.vendor, 'Net_weight': product.Net_weight, 'unit': product.unit, 'order_detail_id':order_detail_id, 'order_id':order_id,'variance':product.varianceOrg,'preference':this.pref,'delivery_charge':product.delivery_charge  });
    localStorage.setItem('products', JSON.stringify(this.proget));
    this.simpleObservable.next(this.proget);
    let cnt = 0;
    for (let i = 0; i < JSON.parse(localStorage.getItem('products')).length; i++) {
      cnt = cnt + parseInt(JSON.parse(localStorage.getItem("products"))[i].qty);
    }
    this.simpleObservablecount.next(cnt);
    this.total = 0;
    this.delivery = 0;
    for (let i = 0; i < JSON.parse(localStorage.getItem('products')).length; i++) {
      // total = total + JSON.parse(localStorage.getItem("products"))[i].subtotal;
      this.total = this.total + (parseInt(JSON.parse(localStorage.getItem("products"))[i].price)*parseInt(JSON.parse(localStorage.getItem("products"))[i].qty));
    }
    for (let i = 0; i < JSON.parse(localStorage.getItem('products')).length; i++) {
      // total = total + JSON.parse(localStorage.getItem("products"))[i].subtotal;
      if(JSON.parse(localStorage.getItem("products"))[i].delivery_charge!=""&&JSON.parse(localStorage.getItem("products"))[i].delivery_charge!=null)
      {
      this.delivery = this.delivery + (parseInt(JSON.parse(localStorage.getItem("products"))[i].price)*parseInt(JSON.parse(localStorage.getItem("products"))[i].qty));
      }
    }
    this.simpleObservablecountsub.next(this.total);
    this.simpleObservablecountDelivery.next(this.delivery);
    return true;
  }

  addtoWishlist(product,order_detail_id,order_id) {
    let products = [];
    let total=1;
    if(localStorage.getItem('wishlist_products')==null)
    {
      total=0;
    }


    if(localStorage.getItem('wishlist_products')!=null)
    {
      if(JSON.parse(localStorage.getItem('wishlist_products')).length>0)
    {
    var match = JSON.parse(localStorage.getItem("wishlist_products")).filter(
      (item) => item.productId === product.id
    );
if(match.length>0)
    {

      let storageProducts = JSON.parse(localStorage.getItem('wishlist_products'));
      let filtered = storageProducts.filter(product1 => product1.productId !== product.id);
      localStorage.setItem('wishlist_products', JSON.stringify(filtered));
      return 0;
    }
    else
    {
      if (localStorage.getItem('wishlist_products')) {
        this.progetwish = JSON.parse(localStorage.getItem('wishlist_products'));
      }
      let price=0;
      if(product.sales_rate!=null)
      {
        price=product.sales_rate;
      }
      else
      {
        price=product.price;
      }

      this.progetwish.push({ 'productId': product.id, 'product_name': product.product_name, 'product_image1': product.product_image1, 'price': price  });
      localStorage.setItem('wishlist_products', JSON.stringify(this.progetwish));
      // this.addtoWishlistLogin(localStorage.getItem('user_id'),product.id);
         return 1;
    }

  }
  else
  {
    if (localStorage.getItem('wishlist_products')) {
      this.progetwish = JSON.parse(localStorage.getItem('wishlist_products'));
    }
    let price=0;
    if(product.sales_rate!=null)
    {
      price=product.sales_rate;
    }
    else
    {
      price=product.price;
    }

    this.progetwish.push({ 'productId': product.id, 'product_name': product.product_name, 'product_image1': product.product_image1, 'price': price });
    localStorage.setItem('wishlist_products', JSON.stringify(this.progetwish));
    // this.addtoWishlistLogin(localStorage.getItem('user_id'),product.id);
       return 1;
  }
  }
    else
    {


    if (localStorage.getItem('wishlist_products')) {
      this.progetwish = JSON.parse(localStorage.getItem('wishlist_products'));
    }
    let price=0;
    if(product.sales_rate!=null)
    {
      price=product.sales_rate;
    }
    else
    {
      price=product.price;
    }
    if(product.variance[0])
    {
      this.pref=product.variance[0].preference;
    }
    else
    {
      this.pref="";
    }
    this.progetwish.push({ 'productId': product.id, 'product_name': product.product_name, 'product_image1': product.product_image1, 'price': price});
    localStorage.setItem('wishlist_products', JSON.stringify(this.progetwish));
    // this.addtoWishlistLogin(localStorage.getItem('user_id'),product.id);
       return 1;
  }
  }
  addtoWishlistLogin(user_id,product_id)
  {
    return this.http.post(this._url.config_url + 'addWish_list?user_id=' + user_id+'&product_id='+product_id,'');
  }
  viewWishlistLogin(user_id)
  {
    return this.http.get<any>(this._url.config_url + 'wish_list_perUser?user_id='+user_id);
  }
  deleteWishlistLogin(user_id,product_id)
  {
    return this.http.get(this._url.config_url + 'delete_wish_list_perUser?user_id=' + user_id+'&product_id='+product_id);
  }

  getAddedItems() {
    if (localStorage.getItem("products") !== null) {


      let ct = JSON.parse(localStorage.getItem("products")).length;
      for (let i = 0; i < ct; i++) {
        this.cart_item_id.push(JSON.parse(localStorage.getItem("products"))[i].productId);

      }
      this.simpleObservableAddedItems.next(this.cart_item_id);
      return this.cart_item_id;
      // return this.simpleObservablecounteAddedItems$;
    }
    else {
      console.log("inside else");
      this.cart_item_id.push('no item');
      console.log(this.cart_item_id);
    }
  }


  addtoCartQtyCart(product) {
    let products = [];

    if (localStorage.getItem('products')) {
      this.proget = JSON.parse(localStorage.getItem('products'));
    }
    let ct = JSON.parse(localStorage.getItem("products")).length;
    let subtotal=0;
    this.removeproget=[];
    for (let i = 0; i < ct; i++) {
      if (JSON.parse(localStorage.getItem("products"))[i].productId == product.productId) {
        var qty = parseInt(JSON.parse(localStorage.getItem("products"))[i].qty) + 1;
        var id = JSON.parse(localStorage.getItem("products"))[i].productId;
        var name = JSON.parse(localStorage.getItem("products"))[i].product_name;
        var image = JSON.parse(localStorage.getItem("products"))[i].image;
        var price = parseInt(JSON.parse(localStorage.getItem("products"))[i].price);
        var order_detail_id = parseInt(JSON.parse(localStorage.getItem("products"))[i].order_detail_id);
        var order_id = parseInt(JSON.parse(localStorage.getItem("products"))[i].order_id);
        var preference = JSON.parse(localStorage.getItem("products"))[i].preference;
        var varianceOrg = JSON.parse(localStorage.getItem("products"))[i].variance;
        var delivery_charge = JSON.parse(localStorage.getItem("products"))[i].delivery_charge;

        // var subtotal = parseInt(JSON.parse(localStorage.getItem("products"))[i].subtotal) + price;
         subtotal = subtotal + (price*qty);
        let storageProducts = JSON.parse(localStorage.getItem('products'));
        var vendor = JSON.parse(localStorage.getItem("products"))[i].vendor;
        this.removeproget.push({ 'productId': id, 'product_name': name, 'image': image, 'price': price, 'qty': qty, 'subtotal': subtotal, 'vendor': vendor,'order_detail_id':order_detail_id,'order_id':order_id,'variance':varianceOrg,'preference':preference,'delivery_charge':delivery_charge });
      }
      else
      {
        var qty = parseInt(JSON.parse(localStorage.getItem("products"))[i].qty);
        var id = JSON.parse(localStorage.getItem("products"))[i].productId;
        var name = JSON.parse(localStorage.getItem("products"))[i].product_name;
        var image = JSON.parse(localStorage.getItem("products"))[i].image;
        var price = parseInt(JSON.parse(localStorage.getItem("products"))[i].price);
        var order_detail_id = parseInt(JSON.parse(localStorage.getItem("products"))[i].order_detail_id);
        var order_id = parseInt(JSON.parse(localStorage.getItem("products"))[i].order_id);
        var preference = JSON.parse(localStorage.getItem("products"))[i].preference;
        var varianceOrg = JSON.parse(localStorage.getItem("products"))[i].variance;
        var delivery_charge = JSON.parse(localStorage.getItem("products"))[i].delivery_charge;


        // var subtotal = parseInt(JSON.parse(localStorage.getItem("products"))[i].subtotal) + price;
         subtotal = subtotal + (price*qty);
        let storageProducts = JSON.parse(localStorage.getItem('products'));
        var vendor = JSON.parse(localStorage.getItem("products"))[i].vendor;
        this.removeproget.push({ 'productId': id, 'product_name': name, 'image': image, 'price': price, 'qty': qty, 'subtotal': subtotal, 'vendor': vendor,'order_detail_id':order_detail_id,'order_id':order_id,'variance':varianceOrg,'preference':preference,'delivery_charge':delivery_charge });
      }

    }
    // this.removeproget = JSON.parse(localStorage.getItem("products")).filter(
    //   (products) => products.productId !== product.productId
    // );

    localStorage.setItem('products', JSON.stringify(this.removeproget));
    this.simpleObservable.next(this.removeproget);
    this.total = 0;
    this.delivery = 0;
    for (let i = 0; i < JSON.parse(localStorage.getItem('products')).length; i++) {
      this.total = this.total + (parseInt(JSON.parse(localStorage.getItem("products"))[i].price)*parseInt(JSON.parse(localStorage.getItem("products"))[i].qty));
    }
    for (let i = 0; i < JSON.parse(localStorage.getItem('products')).length; i++) {
      // total = total + JSON.parse(localStorage.getItem("products"))[i].subtotal;
      if(JSON.parse(localStorage.getItem("products"))[i].delivery_charge!=""&&JSON.parse(localStorage.getItem("products"))[i].delivery_charge!=null)
      {
      this.delivery = this.delivery + (parseInt(JSON.parse(localStorage.getItem("products"))[i].price)*parseInt(JSON.parse(localStorage.getItem("products"))[i].qty));
      }
    }
    this.simpleObservablecountsub.next(this.total);
    this.simpleObservablecountDelivery.next(this.delivery);

    let cnt = 0;
    for (let i = 0; i < JSON.parse(localStorage.getItem('products')).length; i++) {
      cnt = cnt + parseInt(JSON.parse(localStorage.getItem("products"))[i].qty);
    }
    this.simpleObservablecount.next(cnt);
    return qty;


  }


  addtoCartQty(product) {
    let products = [];

    if (localStorage.getItem('products')) {
      this.proget = JSON.parse(localStorage.getItem('products'));
    }
    let ct = JSON.parse(localStorage.getItem("products")).length;
    let subtotal=0;
    for (let i = 0; i < ct; i++) {
      if (JSON.parse(localStorage.getItem("products"))[i].productId == product.id) {
        var qty = parseInt(JSON.parse(localStorage.getItem("products"))[i].qty) + 1;
        var id = JSON.parse(localStorage.getItem("products"))[i].productId;
        var name = JSON.parse(localStorage.getItem("products"))[i].product_name;
        var image = JSON.parse(localStorage.getItem("products"))[i].image;
        var price = parseInt(JSON.parse(localStorage.getItem("products"))[i].price);
        var order_detail_id = parseInt(JSON.parse(localStorage.getItem("products"))[i].order_detail_id);
        var order_id = parseInt(JSON.parse(localStorage.getItem("products"))[i].order_id);
        var preference = JSON.parse(localStorage.getItem("products"))[i].preference;
        var varianceOrg = JSON.parse(localStorage.getItem("products"))[i].variance;
         subtotal = subtotal + (price*qty);
         var delivery_charge = JSON.parse(localStorage.getItem("products"))[i].delivery_charge;

        let storageProducts = JSON.parse(localStorage.getItem('products'));
        var vendor = JSON.parse(localStorage.getItem("products"))[i].vendor;
        this.removeproget = JSON.parse(localStorage.getItem("products")).filter(
          (products) => products.productId !== product.id
        );
        this.removeproget.push({ 'productId': id, 'product_name': name, 'image': image, 'price': price, 'qty': qty, 'subtotal': subtotal, 'vendor': vendor,'order_detail_id':order_detail_id ,'order_id':order_id,'variance':varianceOrg,'preference':preference,'delivery_charge':delivery_charge});
        localStorage.setItem('products', JSON.stringify(this.removeproget));
      }

    }
    this.simpleObservable.next(this.removeproget);
    this.total = 0;
    this.delivery=0;
    for (let i = 0; i < JSON.parse(localStorage.getItem('products')).length; i++) {
      //total = total + JSON.parse(localStorage.getItem("products"))[i].subtotal;
      this.total = this.total + (parseInt(JSON.parse(localStorage.getItem("products"))[i].price)*parseInt(JSON.parse(localStorage.getItem("products"))[i].qty));
    }
    for (let i = 0; i < JSON.parse(localStorage.getItem('products')).length; i++) {
      // total = total + JSON.parse(localStorage.getItem("products"))[i].subtotal;
      if(JSON.parse(localStorage.getItem("products"))[i].delivery_charge!=""&&JSON.parse(localStorage.getItem("products"))[i].delivery_charge!=null)
      {
      this.delivery = this.delivery + (parseInt(JSON.parse(localStorage.getItem("products"))[i].price)*parseInt(JSON.parse(localStorage.getItem("products"))[i].qty));
      }
    }
    this.simpleObservablecountsub.next(this.total);
    this.simpleObservablecountDelivery.next(this.delivery);

    let cnt = 0;
    for (let i = 0; i < JSON.parse(localStorage.getItem('products')).length; i++) {
      cnt = cnt + parseInt(JSON.parse(localStorage.getItem("products"))[i].qty);
    }
    this.simpleObservablecount.next(cnt);
    return qty;
  }


  removetoCartQty(product) {
    let products = [];

    if (localStorage.getItem('products')) {
      this.proget = JSON.parse(localStorage.getItem('products'));
    }


    let ct = JSON.parse(localStorage.getItem("products")).length;
    let subtotal=0;
    for (let i = 0; i < ct; i++) {
      if (JSON.parse(localStorage.getItem("products"))[i].productId == product.id) {
        var qty = parseInt(JSON.parse(localStorage.getItem("products"))[i].qty) - 1;
        var id = JSON.parse(localStorage.getItem("products"))[i].productId;
        var name = JSON.parse(localStorage.getItem("products"))[i].product_name;
        var image = JSON.parse(localStorage.getItem("products"))[i].image;
        var price = parseInt(JSON.parse(localStorage.getItem("products"))[i].price);
        var order_detail_id = parseInt(JSON.parse(localStorage.getItem("products"))[i].order_detail_id);
        var order_id = parseInt(JSON.parse(localStorage.getItem("products"))[i].order_id);
        var preference = JSON.parse(localStorage.getItem("products"))[i].preference;
        var varianceOrg = JSON.parse(localStorage.getItem("products"))[i].variance;
        var delivery_charge = JSON.parse(localStorage.getItem("products"))[i].delivery_charge;

        // var subtotal = parseInt(JSON.parse(localStorage.getItem("products"))[i].subtotal) + price;
         subtotal = subtotal + (price*qty);
        let storageProducts = JSON.parse(localStorage.getItem('products'));
        var vendor = JSON.parse(localStorage.getItem("products"))[i].vendor;
        this.removeproget = JSON.parse(localStorage.getItem("products")).filter(
          (products) => products.productId !== product.id
        );
        this.removeproget.push({ 'productId': id, 'product_name': name, 'image': image, 'price': price, 'qty': qty, 'subtotal': subtotal, 'vendor': vendor,'order_detail_id':order_detail_id,'order_id':order_id,'variance':varianceOrg,'preference':preference,'delivery_charge':delivery_charge });
        localStorage.setItem('products', JSON.stringify(this.removeproget));
      }

    }
    this.simpleObservable.next(this.removeproget);
    let total = 0;
    this.delivery=0;
    for (let i = 0; i < JSON.parse(localStorage.getItem('products')).length; i++) {
      total = total + JSON.parse(localStorage.getItem("products"))[i].subtotal;
    }
    for (let i = 0; i < JSON.parse(localStorage.getItem('products')).length; i++) {
      // total = total + JSON.parse(localStorage.getItem("products"))[i].subtotal;
      if(JSON.parse(localStorage.getItem("products"))[i].delivery_charge!=""&&JSON.parse(localStorage.getItem("products"))[i].delivery_charge!=null)
      {
      this.delivery = this.delivery + (parseInt(JSON.parse(localStorage.getItem("products"))[i].price)*parseInt(JSON.parse(localStorage.getItem("products"))[i].qty));
      }
    }
    this.simpleObservablecountsub.next(total);
    this.simpleObservablecountDelivery.next(this.delivery);
    let cnt = 0;
    for (let i = 0; i < JSON.parse(localStorage.getItem('products')).length; i++) {
      cnt = cnt + parseInt(JSON.parse(localStorage.getItem("products"))[i].qty);
    }
    this.simpleObservablecount.next(cnt);
    return qty;
  }

  getCartOnInit() {
    this.total = 0;
    let products = [];

    if (localStorage.getItem('products')) {
      this.proget = JSON.parse(localStorage.getItem('products'));
    }
    this.total = 0;
    this.delivery=0;
    for (let i = 0; i < JSON.parse(localStorage.getItem('products')).length; i++) {
      // total = total + JSON.parse(localStorage.getItem("products"))[i].subtotal;
      this.total =     this.total + (parseInt(JSON.parse(localStorage.getItem("products"))[i].price)*parseInt(JSON.parse(localStorage.getItem("products"))[i].qty));
    }
    for (let i = 0; i < JSON.parse(localStorage.getItem('products')).length; i++) {
      // total = total + JSON.parse(localStorage.getItem("products"))[i].subtotal;
      if(JSON.parse(localStorage.getItem("products"))[i].delivery_charge!=""&&JSON.parse(localStorage.getItem("products"))[i].delivery_charge!=null)
      {
      this.delivery = this.delivery + (parseInt(JSON.parse(localStorage.getItem("products"))[i].price)*parseInt(JSON.parse(localStorage.getItem("products"))[i].qty));
      }
    }
    this.simpleObservablecountsub.next(this.total);
    this.simpleObservablecountDelivery.next(this.delivery);
    this.simpleObservable.next(this.proget);

    let cnt = 0;
    for (let i = 0; i < JSON.parse(localStorage.getItem('products')).length; i++) {
      cnt = cnt + parseInt(JSON.parse(localStorage.getItem("products"))[i].qty);
    }
    this.simpleObservablecount.next(cnt);
    return this.simpleObservable$;
  }
  getCartCount() {
    return this.simpleObservablecount$;
  }
  getCartCountSub() {
    return this.simpleObservablecountsub$;
  }
  getCartCountDelivery()
  {
    return this.simpleObservablecountDelivery$;
  }
  getCart() {

    return this.simpleObservable$;

  }

  public getCartForLoggedUser(id) {
    if (!this.cacheOrder$) {
      this.cacheOrder$ = this.requestCategory(id).pipe(
        shareReplay(CACHE_SIZE)
      );
    }

    return this.cacheOrder$;
  }
  private requestCategory(id) {
    return this.http.get<any>(this.link.config_url + "client_api/customerorder/" + id);
  }

  removeWishlist(pro) {
    let storageProducts = JSON.parse(localStorage.getItem('wishlist_products'));
    this.removeproget = storageProducts.filter(product => product.productId !== pro.productId);
    localStorage.setItem('wishlist_products', JSON.stringify(this.removeproget));
       return  true;
  }

  removeCart(pro) {
    let storageProducts = JSON.parse(localStorage.getItem('products'));
    this.removeproget = storageProducts.filter(product => product.productId !== pro.productId);
    localStorage.setItem('products', JSON.stringify(this.removeproget));
    this.simpleObservable.next(this.removeproget);

    let cnt = 0;
    for (let i = 0; i < JSON.parse(localStorage.getItem('products')).length; i++) {
      cnt = cnt + parseInt(JSON.parse(localStorage.getItem("products"))[i].qty);
    }
    this.simpleObservablecount.next(cnt);
    this.total = 0;
    this.delivery=0;
    for (let i = 0; i < JSON.parse(localStorage.getItem('products')).length; i++) {
      // total = total + JSON.parse(localStorage.getItem("products"))[i].subtotal;
      this.total =     this.total  + (parseInt(JSON.parse(localStorage.getItem("products"))[i].price)*parseInt(JSON.parse(localStorage.getItem("products"))[i].qty));
    }
    for (let i = 0; i < JSON.parse(localStorage.getItem('products')).length; i++) {
      // total = total + JSON.parse(localStorage.getItem("products"))[i].subtotal;
      if(JSON.parse(localStorage.getItem("products"))[i].delivery_charge!=""&&JSON.parse(localStorage.getItem("products"))[i].delivery_charge!=null)
      {
      this.delivery = this.delivery + (parseInt(JSON.parse(localStorage.getItem("products"))[i].price)*parseInt(JSON.parse(localStorage.getItem("products"))[i].qty));
      }
    }
    this.simpleObservablecountsub.next(this.total );
    this.simpleObservablecountDelivery.next(this.delivery );
    return this.simpleObservable$;
  }
  removeCartFromHome(pro) {
    let storageProducts = JSON.parse(localStorage.getItem('products'));
    this.removeproget = storageProducts.filter(product => product.productId !== pro.id);
    localStorage.setItem('products', JSON.stringify(this.removeproget));
    this.simpleObservable.next(this.removeproget);

    let cnt = 0;
    for (let i = 0; i < JSON.parse(localStorage.getItem('products')).length; i++) {
      cnt = cnt + parseInt(JSON.parse(localStorage.getItem("products"))[i].qty);
    }
    this.simpleObservablecount.next(cnt);
    this.total = 0;
    this.delivery = 0;
    for (let i = 0; i < JSON.parse(localStorage.getItem('products')).length; i++) {
      // total = total + JSON.parse(localStorage.getItem("products"))[i].subtotal;
      this.total =     this.total  + (parseInt(JSON.parse(localStorage.getItem("products"))[i].price)*parseInt(JSON.parse(localStorage.getItem("products"))[i].qty));
    }
    for (let i = 0; i < JSON.parse(localStorage.getItem('products')).length; i++) {
      // total = total + JSON.parse(localStorage.getItem("products"))[i].subtotal;
      if(JSON.parse(localStorage.getItem("products"))[i].delivery_charge!=""&&JSON.parse(localStorage.getItem("products"))[i].delivery_charge!=null)
      {
      this.delivery = this.delivery + (parseInt(JSON.parse(localStorage.getItem("products"))[i].price)*parseInt(JSON.parse(localStorage.getItem("products"))[i].qty));
      }
    }

    this.simpleObservablecountsub.next(    this.total );
    this.simpleObservablecountDelivery.next(    this.delivery );
    return this.simpleObservable$;
  }
  removeOrder(id)
  {
    return this.http.get<any>(this.link.config_url + "client_api/deleteorder/" + id)
  }
  removeCartLogin(id)
  {
    console.log(id+ "this is delete id");
    return this.http.delete<any>(this.link.config_url + "client_api/deleteorderdetails/" + id);
  }
  addtoCartOnLogin(tot,vendor,response) {

    let params = new HttpParams();
    var paramsct: any;
    var paramsct1: any;


    paramsct = {
      "orderDate": formatDate(new Date(), 'yyyy-MM-dd', 'en'),
             "orderTotal": tot,
             "deliveryDate": formatDate(new Date(), 'yyyy-MM-dd', 'en'),
             "status": "added_to_cart",
             "isDelivered": false,
             "paymentMethod": "",
             "paymentStatus": false,
             "vendorId":vendor,
             "customerId":localStorage.getItem("user_id"),
             "orderdetails": response
         }
         return this.datas = this.http.post(this._url.config_url + 'client_api/createorder', paramsct);


  }


  addtoCartLoggedInFromWish(product) {
    console.log(JSON.stringify(product)+"Our product");
    let params = new HttpParams();
    var paramsct: any;

    var paramsct1: any;

    if(localStorage.getItem('order_id')==null||localStorage.getItem('order_id').length==0)
    {
      console.log("inside if case");
      var extra='[';
      var tot=0;
      let subtotal=0;
      let products = [];
      let total = 0;
      if (localStorage.getItem('products')) {
        this.proget = JSON.parse(localStorage.getItem('products'));
      }
      let price=0;
      if(product.sales_rate!=null)
      {
        price=product.sales_rate;
      }
      else
      {
        price=product.price;
      }
      var Net_weight=0;
      if(product.Net_weight==null||product.Net_weight=='')
      {
         Net_weight=Net_weight=0;
      }
      else
      {
        Net_weight=product.Net_weight
      }
       extra=extra+'{ "productId":'+product.productId+',"productQuantity":1,"productPrice": '+price+',"netWeight":'+Net_weight+' }';
    extra=extra+']';
    console.log(extra+"this is extra")
    var response = JSON.parse(extra);
    paramsct = {
     "orderDate": formatDate(new Date(), 'yyyy-MM-dd', 'en'),
             "orderTotal": tot,
             "deliveryDate": formatDate(new Date(), 'yyyy-MM-dd', 'en'),
             "status": "added_to_cart",
             "isDelivered": false,
             "paymentMethod": "",
             "paymentStatus": false,
             "vendorId":product.vendor,
             "customerId":localStorage.getItem("user_id"),
             "orderdetails": response
         }
         console.log(JSON.stringify(paramsct)+"this is extra data");
         return this.datas = this.http.post(this._url.config_url + 'client_api/createorder', paramsct);
    }
    else
    {
      console.log("inside else case");
      var extra='[';
      var tot=0;
      let ct = JSON.parse(localStorage.getItem("products")).length;
      let price=0;
      if(product.sales_rate!=null)
      {
        price=product.sales_rate;
      }
      else
      {
        price=product.price;
      }
      var Net_weight=0;
      if(product.Net_weight==null||product.Net_weight=='')
      {
         Net_weight=Net_weight=0;
      }
      else
      {
        Net_weight=product.Net_weight
      }
       extra=extra+'{ "productId":'+product.productId+',"productQuantity":1,"productPrice": '+price+',"netWeight":'+Net_weight+' }';
    extra=extra+']';
    console.log(extra+"this is extra");
    var response = JSON.parse(extra);
    paramsct = {
      "orderDate": formatDate(new Date(), 'yyyy-MM-dd', 'en'),
             "orderTotal": tot,
             "deliveryDate": formatDate(new Date(), 'yyyy-MM-dd', 'en'),
             "status": "added_to_cart",
             "isDelivered": false,
             "paymentMethod": "",
             "paymentStatus": false,
             "vendorId":product.vendor,
             "customerId":localStorage.getItem("user_id"),
             "orderdetails": response
         }
         console.log(JSON.stringify(paramsct)+"this is extra data");
         return this.datas = this.http.put(this._url.config_url + 'client_api/updateorderTotal/'+localStorage.getItem('order_id'), paramsct);
 }




  }







  addtoCartLoggedIn(product) {
    let params = new HttpParams();
    var paramsct: any;

    var paramsct1: any;
      var extra='[';
      var tot=0;
      let subtotal=0;

      let products = [];
      let total = 0;
      if (localStorage.getItem('products')) {
        this.proget = JSON.parse(localStorage.getItem('products'));
      }
      let price=0;
      if(product.sales_rate!=null)
      {
        price=product.sales_rate;
      }
      else
      {
        price=product.price;
      }
      var Net_weight=0;
      if(product.Net_weight==null||product.Net_weight=='')
      {
         Net_weight=Net_weight=0;
      }
      else
      {
        Net_weight=product.Net_weight
      }
      if(product.variance[0])
      {
        this.pref=product.variance[0].preference;
      }
      else
      {
        this.pref="";
      }
       extra=extra+'{ "productId":'+product.id+',"productQuantity":1,"productPrice": '+price+',"netWeight":'+Net_weight+',"variance":"'+product.varianceOrg+'","preference":"'+this.pref+'","delivery_charge":"'+product.delivery_charge+'" }';




    extra=extra+']';

    var response = JSON.parse(extra);
    paramsct = {
      "orderDate": formatDate(new Date(), 'yyyy-MM-dd', 'en'),
             "orderTotal": tot,
             "deliveryDate": formatDate(new Date(), 'yyyy-MM-dd', 'en'),
             "status": "added_to_cart",
             "isDelivered": false,
             "paymentMethod": "",
             "paymentStatus": false,
             "vendorId":product.vendor,
             "customerId":localStorage.getItem("user_id"),
             "orderdetails": response
         }

         return this.datas = this.http.post(this._url.config_url + 'client_api/createorder', paramsct);


  }


  addtoCartLoggedInQty(product) {
    let params = new HttpParams();
    var paramsct: any;

    let storageProducts = JSON.parse(localStorage.getItem('products'));
    this.removeproget = storageProducts.filter(product1 => product1.productId == product.id);

    var paramsct1: any;
      var extra='[';
      var tot=0;
      let ct = JSON.parse(localStorage.getItem("products")).length;
      let price=0;
      if(product.sales_rate!=null)
      {
        price=product.sales_rate;
      }
      else
      {
        price=product.price;
      }
      var Net_weight=0;
      if(product.Net_weight==null||product.Net_weight=='')
      {
         Net_weight=Net_weight=0;
      }
      else
      {
        Net_weight=product.Net_weight
      }

       extra=extra+'{"id":'+this.removeproget[0].order_detail_id+', "productId":'+product.id+',"productQuantity":'+this.removeproget[0].qty+',"productPrice": '+price+',"netWeight":'+Net_weight+',"variance":"'+this.removeproget[0].variance+'","preference":"'+this.removeproget[0].preference+'","delivery_charge":"'+this.removeproget[0].delivery_charge+'" }';
   extra=extra+']';

    var response = JSON.parse(extra);
    paramsct = {
      "orderDate": formatDate(new Date(), 'yyyy-MM-dd', 'en'),
             "orderTotal": tot,
             "deliveryDate": formatDate(new Date(), 'yyyy-MM-dd', 'en'),
             "status": "added_to_cart",
             "isDelivered": false,
             "paymentMethod": "",
             "paymentStatus": false,
             "vendorId":product.vendor,
             "customerId":localStorage.getItem("user_id"),
             "orderdetails": response
         }

         return this.datas = this.http.put(this._url.config_url + 'client_api/updateorderTotal/'+this.removeproget[0].order_id, paramsct);
  }

  addtoCartLoggedInQtyFromCart(product) {

    let params = new HttpParams();
    var paramsct: any;

    let storageProducts = JSON.parse(localStorage.getItem('products'));
    this.removeproget = storageProducts.filter(product1 => product1.productId == product.productId);


    var paramsct1: any;
      var extra='[';
      var tot=0;
      let ct = JSON.parse(localStorage.getItem("products")).length;


      let price=0;
      if(product.sales_rate!=null)
      {
        price=product.sales_rate;
      }
      else
      {
        price=product.price;
      }
      var Net_weight=0;
      if(product.Net_weight==null||product.Net_weight=='')
      {
         Net_weight=Net_weight=0;
      }
      else
      {
        Net_weight=product.Net_weight
      }

       extra=extra+'{"id":'+this.removeproget[0].order_detail_id+', "productId":'+product.productId+',"productQuantity":'+this.removeproget[0].qty+',"productPrice": '+price+',"netWeight":'+Net_weight+',"variance":"'+this.removeproget[0].variance+'","preference":"'+this.removeproget[0].preference+'","delivery_charge":"'+this.removeproget[0].delivery_charge+'" }';
   extra=extra+']';
    var response = JSON.parse(extra);
    paramsct = {
      "orderDate": formatDate(new Date(), 'yyyy-MM-dd', 'en'),
             "orderTotal": tot,
             "deliveryDate": formatDate(new Date(), 'yyyy-MM-dd', 'en'),
             "status": "added_to_cart",
             "isDelivered": false,
             "paymentMethod": "",
             "paymentStatus": false,
             "vendorId":product.vendor,
             "customerId":localStorage.getItem("user_id"),
             "orderdetails": response
         }
         return this.datas = this.http.put(this._url.config_url + 'client_api/updateorderTotal/'+this.removeproget[0].order_id, paramsct);
  }
  removetoCartLoggedInQty(product) {
    let params = new HttpParams();
    var paramsct: any;

    let storageProducts = JSON.parse(localStorage.getItem('products'));
    if(storageProducts.filter(product1 => product1.productId == product.id))
    {
    this.removeproget = storageProducts.filter(product1 => product1.productId == product.id);


    var paramsct1: any;
      var extra='[';
      var tot=0;
      let ct = JSON.parse(localStorage.getItem("products")).length;


      let price=0;
      if(product.sales_rate!=null)
      {
        price=product.sales_rate;
      }
      else
      {
        price=product.price;
      }
      var Net_weight=0;
      if(product.Net_weight==null||product.Net_weight=='')
      {
         Net_weight=Net_weight=0;
      }
      else
      {
        Net_weight=product.Net_weight
      }
       extra=extra+'{"id":'+this.removeproget[0].order_detail_id+', "productId":'+product.id+',"productQuantity":'+this.removeproget[0].qty+',"productPrice": '+price+',"netWeight":'+Net_weight+',"variance":"'+this.removeproget[0].variance+'","preference":"'+this.removeproget[0].preference+'","delivery_charge":"'+this.removeproget[0].delivery_charge+'" }';
      //  extra=extra+'{"id":'+this.removeproget[0].order_detail_id+', "productId":'+product.productId+',"productQuantity":'+this.removeproget[0].qty+',"productPrice": '+price+',"netWeight":'+Net_weight+',"variance":"'+this.removeproget[0].variance+'","preference":"'+this.removeproget[0].preference+'" }';
    extra=extra+']';
    var response = JSON.parse(extra);
    paramsct = {
      "orderDate": formatDate(new Date(), 'yyyy-MM-dd', 'en'),
             "orderTotal": tot,
             "deliveryDate": formatDate(new Date(), 'yyyy-MM-dd', 'en'),
             "status": "added_to_cart",
             "isDelivered": false,
             "paymentMethod": "",
             "paymentStatus": false,
             "vendorId":(product.vendor) + "",
             "customerId":localStorage.getItem("user_id"),
             "orderdetails": response
         }
         return this.datas = this.http.put(this._url.config_url + 'client_api/updateorderTotal/'+this.removeproget[0].order_id, paramsct);
        }
        else
        {

        }
        }

        removetoCartLoggedInQtyFromCart(product) {
          let params = new HttpParams();
          var paramsct: any;

          let storageProducts = JSON.parse(localStorage.getItem('products'));
          if(storageProducts.filter(product1 => product1.productId == product.productId))
          {
          this.removeproget = storageProducts.filter(product1 => product1.productId == product.productId);


          var paramsct1: any;
            var extra='[';
            var tot=0;
            let ct = JSON.parse(localStorage.getItem("products")).length;


            let price=0;
            if(product.sales_rate!=null)
            {
              price=product.sales_rate;
            }
            else
            {
              price=product.price;
            }
            var Net_weight=0;
            if(product.Net_weight==null||product.Net_weight=='')
            {
               Net_weight=Net_weight=0;
            }
            else
            {
              Net_weight=product.Net_weight
            }
             extra=extra+'{"id":'+this.removeproget[0].order_detail_id+', "productId":'+product.productId+',"productQuantity":'+this.removeproget[0].qty+',"productPrice": '+price+',"netWeight":'+Net_weight+',"variance":"'+this.removeproget[0].variance+'","preference":"'+this.removeproget[0].preference+'","delivery_charge":"'+this.removeproget[0].delivery_charge+'" }';
          extra=extra+']';

          var response = JSON.parse(extra);
          paramsct = {
            "orderDate": formatDate(new Date(), 'yyyy-MM-dd', 'en'),
                   "orderTotal": tot,
                   "deliveryDate": formatDate(new Date(), 'yyyy-MM-dd', 'en'),
                   "status": "added_to_cart",
                   "isDelivered": false,
                   "paymentMethod": "",
                   "paymentStatus": false,
                   "vendorId":(product.vendor) + "",
                   "customerId":localStorage.getItem("user_id"),
                   "orderdetails": response
               }

               return this.datas = this.http.put(this._url.config_url + 'client_api/updateorderTotal/'+this.removeproget[0].order_id, paramsct);
              }
              else
              {

              }
              }


updateOrder(paymentMethod,paymentStatus,tot,order_id,vendor)
{

  if(localStorage.getItem('products').length>0)
  {
      this.paramsctord = {
        "orderDate": formatDate(new Date(), 'yyyy-MM-dd', 'en'),
               "orderTotal": tot,
               "deliveryDate": formatDate(new Date(), 'yyyy-MM-dd', 'en'),
               "status": "Ordered",
               "isDelivered": false,
               "paymentMethod": paymentMethod,
               "paymentStatus": paymentStatus,
               "vendorId":vendor,
               "customerId":localStorage.getItem("user_id"),
               "orderdetails": []
           }

       return  this.http.put(this._url.config_url + 'client_api/updateorderTotal/'+order_id,this.paramsctord);


  }


 }




updateOrderStatus(order,status)
{
  var paramsct:any;
  console.log(JSON.stringify(order)+"odrefcv @@@@@@@@")

  paramsct = {
    "orderDate": order[0].order_date,
           "orderTotal": order[0].order_total,
           "deliveryDate": order[0].delivery_date,
           "status": status,
           "isDelivered": order[0].Is_delivered,
           "paymentMethod": order[0].payment_method,
           "paymentStatus": order[0].payment_status,
           "vendorId":order[0].vendor_id,
           "customerId":order[0].customer_id,
           "orderdetails": []
       }
        console.log(JSON.stringify(paramsct)+"@@@@@@@@@@this is final edit data");
       return   this.http.put(this._url.config_url + 'client_api/updateorderTotal/'+order[0].id,paramsct);

}

updateOrder_date(ddate)
{

  if(localStorage.getItem('order_id')!=null)
  {
    // var paramsctorder: any;
    console.log("inside else case");
    var extra='[';
    var tot=0;
    let ct = JSON.parse(localStorage.getItem("products")).length;
    let subtotal=0;
  for (let i = 0; i < ct; i++) {

      var qty = parseInt(JSON.parse(localStorage.getItem("products"))[i].qty) + 0;
      var id = JSON.parse(localStorage.getItem("products"))[i].productId;
      var name = JSON.parse(localStorage.getItem("products"))[i].product_name;
      var image = JSON.parse(localStorage.getItem("products"))[i].image;
      var price = parseInt(JSON.parse(localStorage.getItem("products"))[i].price);
      // var subtotal = parseInt(JSON.parse(localStorage.getItem("products"))[i].subtotal) + price;
      if(qty==0)
      {
        qty=1;
      }
      subtotal = subtotal + price;
      let storageProducts = JSON.parse(localStorage.getItem('products'));
      var vendor = JSON.parse(localStorage.getItem("products"))[i].vendor;
      tot=tot+subtotal;

    //  extra=extra+'{ "productId":'+id+',"productQuantity":'+qty+',"productPrice": '+price+',"netWeight":1 }';



  }

  // if(vendor==null)
  // {
  //   vendor=0;
  // }
 // var response = JSON.parse(extra);
  this.paramsctord = {
    "orderDate": formatDate(new Date(), 'yyyy-MM-dd', 'en'),
           "orderTotal": tot,
           "deliveryDate": formatDate(ddate, 'yyyy-MM-dd', 'en'),
           "status": "add_to_cart",
           "isDelivered": false,
           "paymentMethod": "",
           "paymentStatus": false,
           "vendorId":vendor,
           "customerId":localStorage.getItem("user_id"),
           "orderdetails": []
       }
        console.log(JSON.stringify(this.paramsctord)+"this is final edit data");
       return   this.http.put(this._url.config_url + 'client_api/updateorderTotal/'+localStorage.getItem('order_id'),this.paramsctord);
  }

}
updateCord(x,y)
{
  var para;
  para = {
    "xaxis": x,
    "yaxis": y

       }
       console.log(JSON.stringify(para)+"para meter to updated");
  return   this.http.put(this._url.config_url + 'client_api/update/'+localStorage.getItem('user_id'),para);
}


addtoCartLoggedInLogin(pro) {

  let params = new HttpParams();
  var paramsct: any;


  paramsct = {
    "orderDate": formatDate(new Date(), 'yyyy-MM-dd', 'en'),
           "orderTotal": pro.price,
           "deliveryDate": formatDate(new Date(), 'yyyy-MM-dd', 'en'),
           "status": "added_to_cart",
           "isDelivered": false,
           "paymentMethod": "",
           "paymentStatus": false,
           "vendorId":pro.vendor,
           "customerId":localStorage.getItem("user_id"),
           "orderdetails": [
               {

                   "productId":pro.id,
                   "productQuantity": 1,
                   "productPrice": pro.price,
                   "netWeight":1,
                   "variance":pro.varianceOrg,
                   "preference":pro.variance[0].preference,
                   "delivery_charge":pro.delivery_charge


               }
           ]
       }


  return this.http.post(this._url.config_url + 'client_api/createorder', paramsct);

}

  addAddressUser(addr) {
    let id = localStorage.getItem('user_id');
    let params = new HttpParams();
    params = params.append('address', addr.address);
    params = params.append('xaxis', addr.latitude);
    params = params.append('yaxis', addr.longitude);
    params = params.append('postcode', addr.pincode);
    return this.http.put<any>(this.link.config_url + this._addressUrl + id, addr);
  }
  saveEditUser(addr) {
    let id = localStorage.getItem('user_id');
    let params = new HttpParams();
    params = params.append('name', addr.username);
    params = params.append('email', addr.email);
    params = params.append('postcode', addr.postcode);
    params = params.append('address', addr.address);
    return this.http.put<any>(this.link.config_url + this._addressUrl + id, addr);
  }
  saveEditUserShipping(addr)
  {
    let id = localStorage.getItem('user_id');
    let params = new HttpParams();
    params = params.append('name', addr.username);
    params = params.append('email', addr.email);
    params = params.append('address', addr.address);
    params = params.append('postcode', addr.postcode);
    return this.http.put<any>(this.link.config_url + this._addressUrl + id, addr);

  }
  getSingleOrder(id)
  {
    return this.http.get(this._url.config_url+'client_api/retrieveorder/'+id);
  }
  removetoCartQtyCart(product) {
    let products = [];

    if (localStorage.getItem('products')) {
      this.proget = JSON.parse(localStorage.getItem('products'));
    }
    let ct = JSON.parse(localStorage.getItem("products")).length;
    let subtotal=0;
    this.removeproget=[];
    for (let i = 0; i < ct; i++) {
      if (JSON.parse(localStorage.getItem("products"))[i].productId == product.productId) {
        var qty = parseInt(JSON.parse(localStorage.getItem("products"))[i].qty) - 1;
        var id = JSON.parse(localStorage.getItem("products"))[i].productId;
        var name = JSON.parse(localStorage.getItem("products"))[i].product_name;
        var image = JSON.parse(localStorage.getItem("products"))[i].image;
        var price = parseInt(JSON.parse(localStorage.getItem("products"))[i].price);
        var order_detail_id = parseInt(JSON.parse(localStorage.getItem("products"))[i].order_detail_id);
        var order_id = parseInt(JSON.parse(localStorage.getItem("products"))[i].order_id);
        var preference = JSON.parse(localStorage.getItem("products"))[i].preference;
        var varianceOrg = JSON.parse(localStorage.getItem("products"))[i].variance;
        var delivery_charge = JSON.parse(localStorage.getItem("products"))[i].delivery_charge;

        var comment = parseInt(JSON.parse(localStorage.getItem("products"))[i].comment);
        // var subtotal = parseInt(JSON.parse(localStorage.getItem("products"))[i].subtotal) + price;
         subtotal = subtotal + (price*qty);
        let storageProducts = JSON.parse(localStorage.getItem('products'));
        var vendor = JSON.parse(localStorage.getItem("products"))[i].vendor;
        this.removeproget.push({ 'productId': id, 'product_name': name, 'image': image, 'price': price, 'qty': qty, 'subtotal': subtotal, 'vendor': vendor,'order_detail_id':order_detail_id,'order_id':order_id,'comment':comment,'variance':varianceOrg,'preference':preference,'delivery_charge':delivery_charge });
      }
      else
      {
        var qty = parseInt(JSON.parse(localStorage.getItem("products"))[i].qty);
        var id = JSON.parse(localStorage.getItem("products"))[i].productId;
        var name = JSON.parse(localStorage.getItem("products"))[i].product_name;
        var image = JSON.parse(localStorage.getItem("products"))[i].image;
        var price = parseInt(JSON.parse(localStorage.getItem("products"))[i].price);
        var order_detail_id = parseInt(JSON.parse(localStorage.getItem("products"))[i].order_detail_id);
        var order_id = parseInt(JSON.parse(localStorage.getItem("products"))[i].order_id);
        var preference = JSON.parse(localStorage.getItem("products"))[i].preference;
        var varianceOrg = JSON.parse(localStorage.getItem("products"))[i].variance;
        var delivery_charge = JSON.parse(localStorage.getItem("products"))[i].delivery_charge;

        var comment = parseInt(JSON.parse(localStorage.getItem("products"))[i].comment);
        // var subtotal = parseInt(JSON.parse(localStorage.getItem("products"))[i].subtotal) + price;
         subtotal = subtotal + (price*qty);
        let storageProducts = JSON.parse(localStorage.getItem('products'));
        var vendor = JSON.parse(localStorage.getItem("products"))[i].vendor;
        this.removeproget.push({ 'productId': id, 'product_name': name, 'image': image, 'price': price, 'qty': qty, 'subtotal': subtotal, 'vendor': vendor,'order_detail_id':order_detail_id,'order_id':order_id,'comment':comment,'variance':varianceOrg,'preference':preference,'delivery_charge':delivery_charge });
      }

    }
    // this.removeproget = JSON.parse(localStorage.getItem("products")).filter(
    //   (products) => products.productId !== product.productId
    // );

    localStorage.setItem('products', JSON.stringify(this.removeproget));
    this.simpleObservable.next(this.removeproget);
    this.total = 0;
    this.delivery=0;
    for (let i = 0; i < JSON.parse(localStorage.getItem('products')).length; i++) {
      // total = total + JSON.parse(localStorage.getItem("products"))[i].subtotal;
      this.total =     this.total  + (parseInt(JSON.parse(localStorage.getItem("products"))[i].price)*parseInt(JSON.parse(localStorage.getItem("products"))[i].qty));
    }
    for (let i = 0; i < JSON.parse(localStorage.getItem('products')).length; i++) {
      // total = total + JSON.parse(localStorage.getItem("products"))[i].subtotal;
      if(JSON.parse(localStorage.getItem("products"))[i].delivery_charge!=""&&JSON.parse(localStorage.getItem("products"))[i].delivery_charge!=null)
      {
      this.delivery = this.delivery + (parseInt(JSON.parse(localStorage.getItem("products"))[i].price)*parseInt(JSON.parse(localStorage.getItem("products"))[i].qty));
      }
    }
    this.simpleObservablecountsub.next(    this.total );
    this.simpleObservablecountDelivery.next(    this.delivery );

    let cnt = 0;
    for (let i = 0; i < JSON.parse(localStorage.getItem('products')).length; i++) {
      cnt = cnt + parseInt(JSON.parse(localStorage.getItem("products"))[i].qty);
    }
    this.simpleObservablecount.next(cnt);
    return qty;
  }

  getOrderId(id)
  {

  }
  updateOrderStatusFromOrder(order,status)
  {
    var paramsct:any;

    paramsct = {
      "orderDate": order.order_date,
             "orderTotal": order.order_total,
             "deliveryDate": order.delivery_date,
             "status": status,
             "isDelivered": order.Is_delivered,
             "paymentMethod": order.payment_method,
             "paymentStatus": order.payment_status,
             "vendorId":order.vendor_id,
             "customerId":order.customer_id,
             "orderdetails": []
         }
         return   this.http.put(this._url.config_url + 'client_api/updateorderTotal/'+order.id,paramsct);

  }

  viewaddtocartLogin(id) {
    return this.http.get<any>(this.link.config_url + "client_api/customerorder/" + id+"?status=added_to_cart");
  }
  getSingleProductReview(id)
  {
    return this.http.get(this._url.config_url+'client_api/product_rating?pid='+id)
  }
  applyCoupon(product,id,coupon)
{
  console.log(product);
  var paramsct: any;

    var tot=parseInt(product.price)*parseInt(product.qty);
    paramsct = {
      "orderDate": formatDate(new Date(), 'yyyy-MM-dd', 'en'),
             "orderTotal": tot,
             "deliveryDate": formatDate(new Date(), 'yyyy-MM-dd', 'en'),
             "status": "added_to_cart",
             "isDelivered": false,
             "paymentMethod": "",
             "paymentStatus": false,
             "vendorId":'',
             "customerId":parseInt(localStorage.getItem("user_id")),
             "orderdetails": [
                 {

                     "productId":product.productId,
                     "productQuantity": parseInt(product.qty),
                     "productPrice": product.price,
                     "netWeight":1,
                     "variance":product.variance,
                     "preference":product.preference,
                     "delivery_charge":parseInt(product.delivery_charge),
                     "coupon":coupon


                 }
             ]
         }
         console.log(paramsct);
  return this.http.put(this._url.config_url+'client_api/apply_coupon/'+id,paramsct);
}
}
