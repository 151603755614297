import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router,ActivatedRoute, ParamMap } from '@angular/router';
import { CartService } from '../services/cart.service';
import { FormBuilder,Validators } from '@angular/forms';
import { ConfigService } from '../services/config.service';
import { ProductsService } from '../services/products.service';
import { OrderService } from '../services/order.service';
@Component({
  selector: 'app-shipping',
  templateUrl: './shipping.component.html',
  styleUrls: ['./shipping.component.css']
})
export class ShippingComponent implements OnInit {
  login_status = false;
  countsub = 0;
  flag123=0;
  cart:any;
  count=0;
  oder_details="";
  productbefore=[];
  products:any;
  product_single:any;
  private sub:any;
   p_id;
   name;
   category:any;
   pincode;

   added;
   addedno;
     addBtn;
     addedBtn;
     cart_item_id=[];
     sum=0;
     countdelivery = 0;
     savedAddr;

     orderId="";
     saveUser={username:'',email:'',postcode:'',address:''};
  updatedqty = 1;
  constructor(private _product:ProductsService,
    private router:Router,
    private _cart:CartService,
    private fb:FormBuilder, 
    private _config:ConfigService,private _order:OrderService

    ) { }
    userForm=this.fb.group(
      {

        username:['',[Validators.required]],
        email:['',[Validators.required]],
        postcode:['',[Validators.required]],
        address:['',[Validators.required]]
      }
     )
  img_link=this._config.config_url.slice(0, -1);
  ngOnInit() {
    this._config.chack_netStatus();
    if (localStorage.getItem("user_id") === null) {
			this.login_status = false;
		} else {
			this.login_status = true;
		}
    this.cart=JSON.parse(localStorage.products);
    if(localStorage.getItem('location')!==null)
    {
     this.pincode=localStorage.getItem('location');
    }

    this._product.getAllProducts().subscribe((data)=>{

      this.products=JSON.parse(JSON.stringify(data));
     
    
      
  });

  if(localStorage.getItem('user_id')!=null)
    {
      this._order.customerData(localStorage.getItem('user_id')).subscribe((data)=>{
      
       
        this.savedAddr=JSON.parse(JSON.stringify(data));
        this.savedAddr=this.savedAddr[0];
       
        this.saveUser.username=this.savedAddr['username'];
        this.saveUser.email=this.savedAddr['email'];
        this.saveUser.address=this.savedAddr['address'];
        this.saveUser.postcode=this.savedAddr['postcode'];
        // this.delUser.email=this.savedAddr['email'];
        // this.delUser.username=this.savedAddr['username'];
            
      });
    
    }
    this.countdelivery=0;
for (let i=0;i<this.cart.length;i++)
{
  this.sum=this.sum+this.cart[i].subtotal;
  if(this.cart[i].delivery_charge!=null&&this.cart[i].delivery_charge!='')
  {
    this.countdelivery=this.countdelivery+parseInt(this.cart[i].delivery_charge);
  }
}

if(localStorage.getItem('user_id')!=null)
{
  this._order.customerData(localStorage.getItem('user_id')).subscribe((data)=>{
    this.savedAddr=JSON.parse(JSON.stringify(data));
    this.savedAddr=this.savedAddr[0];
        
  });

}

this.cart_item_id = this._cart.getAddedItems();
    if (this.cart_item_id == null) {
      this.cart_item_id = [];
    }

  }
  removeProduct(product)
  {
    this._cart.removeCart(product).subscribe((data)=>{
    
    this.cart=JSON.parse(JSON.stringify(data));
    this.count=JSON.parse(localStorage.products).length;
  });
  }

  addQty(product) {
    if (localStorage.getItem("user_id") === null) {
      this.updatedqty = this._cart.addtoCartQtyCart(product);
      this.ngOnInit();
    } else {
      this.updatedqty = this._cart.addtoCartQtyCart(product);
      this._cart.addtoCartLoggedInQty(product).subscribe((data) => {
        if (localStorage.getItem("order_id") == null) {
          this.oder_details = JSON.parse(JSON.stringify(data));
          localStorage.setItem("order_id", data[0].id);
        }
      });
    }



  }
  removeQty(product) {
    if(this.getCount(product)>1)
    {
    if (localStorage.getItem("user_id") === null) {
      this.updatedqty = this._cart.removetoCartQtyCart(product);

    }
    else {
      this.updatedqty = this._cart.removetoCartQtyCart(product);
      this._cart.addtoCartLoggedIn(product).subscribe((data) => {
        if(localStorage.getItem('order_id')==null)
        {
          this.oder_details=JSON.stringify(data);
          localStorage.setItem('order_id', data[0].id);
        }
      });
    }

  }
  else
  {
    
    let storageProducts = JSON.parse(localStorage.getItem('products'));
    if(storageProducts.filter(product1 => product1.productId == product.id))
    {
    let removeproget = storageProducts.filter(product1 => product1.productId == product.id);
		let myId=removeproget[0].order_detail_id;
    this._cart.removeCartLogin(myId).subscribe((data) => { });
    this.removeProduct1(product);
    }
  }
  }
  removeProduct1(product) {
		if (localStorage.getItem('user_id')) {
      let storageProducts = JSON.parse(localStorage.getItem('products'));
      let removeproget = storageProducts.filter(product1 => product1.productId == product.productId);
		
      let myId=removeproget[0].order_detail_id;
			this._cart.removeCartLogin(myId).subscribe((data) => { });

		}
		this._cart.removeCart(product).subscribe((data) => {
      this.ngOnInit();
			let d = data;
		});
		this._cart.getCartCountSub().subscribe((data) => {

			this.countsub = JSON.parse(JSON.stringify(data));
		});
    this.ngOnInit();
	
	}

  check_availability(pin_array) {
    
    var arr = [];
    for (var i = 0; i < pin_array.length; i++) {
      arr.push(pin_array[i].pincode);
    }
    if ((arr.indexOf(this.pincode) > -1)) {
      return true;
    }
    else {
      return false;
    }
  }
  getCount(pro)
      {
        if(localStorage.getItem("products"))
        {

     
        let sel = JSON.parse(localStorage.getItem("products")).filter(
          (products) => products.productId === pro.productId
        );
        if(sel!=""&&sel!=null)
        {
          return sel[0].qty;
        }
        else
        {
          return 0;
        }
      }
      else
      {
        return 0;
      }
      }

      check_cartStatus(id)
      {
        if(this.cart_item_id.includes(id))
        {
          
          return true;
        }
        else
        {
          
          return false;
        }
      }

      userEdit()
      {

        if(this.saveUser.address!=""&&this.saveUser.email!=""&&this.saveUser.postcode!=""&&this.saveUser.username!=""&&this.saveUser.address!=null&&this.saveUser.email!=null&&this.saveUser.postcode!=null&&this.saveUser.username!=null)
        {
          document.getElementById("err2").innerHTML="";
        this._cart.saveEditUserShipping(this.saveUser).subscribe((res) =>
        {
        //  alert("User Data saved");
         this.router.navigate(['/payment']);
          
        },err=>
        {
         
          alert("Error");
        })
      }
      else
      {
        document.getElementById("err2").innerHTML="Provide your shipping details";
      }
      }

       check_coupon(val)
   {
     if(val.value=="")
     {
        document.getElementById('err').innerHTML="Please Enter Coupon code";
     }
     else
     {
        document.getElementById('err').innerHTML="";

        let ct = JSON.parse(localStorage.getItem("products")).length;
        let subtotal=0;
        var order_id;
          this.flag123=0;
      for (let i = 0; i < ct; i++) {
      
          var qty = parseInt(JSON.parse(localStorage.getItem("products"))[i].qty) + 0;
          var price = parseInt(JSON.parse(localStorage.getItem("products"))[i].price);
           order_id = JSON.parse(localStorage.getItem("products"))[i].order_id ;
          if(qty==0)
          {
            qty=1;
          }
          
          var vendor = JSON.parse(localStorage.getItem("products"))[i].vendor;
    
          var arr={};
         this._cart.applyCoupon(JSON.parse(localStorage.getItem("products"))[i],order_id,val.value).subscribe((data) => {
          // .subscribe((data)=>{
            console.log(data);
            if(data=="Not a valid coupon")
            {
              
            }
            else
            {
            this.flag123=1;
            }
            var Net_weight='';
            var delivery_charge= 0;
            var image= "";
            var order_detail_id= 0;
            var order_id1=0;
            var preference= "";
            var price1=0;
            var productId=0;
            var product_name="";
            var qty1= 0;
            var subtotal1= 0;
            var unit= null;
            var variance= "";
            let storageProducts = JSON.parse(localStorage.getItem('products'));
            if(storageProducts.filter(product1 => product1.order_id == order_id))
            {
              let removeproget = storageProducts.filter(product1 => product1.order_id == order_id);
              Net_weight=removeproget[0].Net_weight;
              delivery_charge=removeproget[0].delivery_charge;
              image=removeproget[0].image;
              order_detail_id=removeproget[0].order_detail_id;
              order_id1=removeproget[0].order_id;
              preference=removeproget[0].preference;
              price1=removeproget[0].price;
             productId= removeproget[0].productId;
             product_name= removeproget[0].product_name;
             qty1= removeproget[0].qty;
             subtotal1= removeproget[0].subtotal;
             unit= removeproget[0].unit;
             variance= removeproget[0].variance;

            }
            
            //set subtotal
            

            //set subtotal emds
            let storageProducts1 = JSON.parse(localStorage.getItem('products'));
            var removeprogetfull ;
            if(storageProducts1.filter(product1 => product1.order_id != order_id))
            {
              var removeprogetfull = storageProducts1.filter(product1 => product1.order_id != order_id);
            }
            if(data=="Not a valid coupon")
            {
              subtotal = subtotal + (price*qty);
              removeprogetfull.push({ 'productId': productId, 'product_name': product_name, 'image': image, 'price': price1, 'qty': qty1, 'subtotal': price*qty, 'vendor': vendor,'order_detail_id':order_detail_id,'order_id':order_id,'variance':variance,'preference':preference,'delivery_charge':delivery_charge });
            }
            else
            {
     
       var out=JSON.parse(JSON.stringify(data));
       subtotal = subtotal +out[0].Subtotal;
       removeprogetfull.push({ 'productId': productId, 'product_name': product_name, 'image': image, 'price': price1, 'qty': qty1, 'subtotal': out[0].Subtotal, 'vendor': vendor,'order_detail_id':order_detail_id,'order_id':order_id,'variance':variance,'preference':preference,'delivery_charge':delivery_charge });
            }
            localStorage.setItem('products', JSON.stringify(removeprogetfull));
          // });
          if(i==(ct-1))
          {
          //  setTimeout(function(){
           if(this.flag123===1)
           {
             this.sum=subtotal;
             document.getElementById('err1').innerHTML="Coupon Applied";
             document.getElementById('err').innerHTML="";
           }
           else
           {
             document.getElementById('err').innerHTML="Invalid Coupon Code";
             document.getElementById('err1').innerHTML="";
           }
        //  },1000);
          }
         });
        
      }
      // setTimeout(function(){

      // },5000);

    
  

     }
    
   }
}
