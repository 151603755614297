<section class="log"></section>
<div class="container">
    <div class="login-form mx-auto d-block w-100">
        <div class="page-header text-center">
            <h1 class="logh1"><strong>Create a noon account

			</strong></h1>
        </div>
        <div class="text-center mb-30">
            Already have an account?<span routerLink="/login" href="javascript:void(0)"> Sign In</span>
        </div>
        <form [formGroup]="registerForm" (ngSubmit)="registerUser()" method="post" class="form-validate" _lpchecked="1">
            <div class="form-group">
                <div class="control-label">
                    <label id="username-lbl" for="username" class="required invalid">Name<span class="star"> *</span></label>
                </div>
                <div class="controls">
                    <input name="username" id="username" value="" class="validate-username required form-control invalid jsx" size="25" required="required" aria-required="true" autofocus="" aria-invalid="true" type="text" [class.is-invalid]="registerForm.get('username').invalid && registerForm.get('username').touched"
                        formControlName="username" [(ngModel)]="registeredUser.username" required>
                    <div *ngIf="registerForm.get('username').invalid && registerForm.get('username').touched">
                        <small *ngIf="registerForm.get('username').errors?.required" class="text-danger">Name is required</small>

                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="control-label">
                    <label id="password-lbl" for="password" class="required">Password<span class="star"> *</span></label>

                </div>
                <div class="controls">
                    <input name="password" id="password" value="" class="validate-password required form-control jsx" size="25" maxlength="99" required="required" aria-required="true" type="password" [class.is-invalid]="registerForm.get('password').invalid && registerForm.get('password').touched"
                        formControlName="password" [(ngModel)]="registeredUser.password" required>
                    <div *ngIf="registerForm.get('password').invalid && registerForm.get('password').touched">
                        <small *ngIf="registerForm.get('password').errors?.required" class="text-danger">Password is required</small>

                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="control-label">
                    <label id="password-lbl" for="password" class="required">Phone Number<span class="star"> *</span></label>

                </div>
                <div class="controls">
                    <input name="phone" id="phone" value="" class="validate-password required form-control jsx" size="25" maxlength="99" required="required" aria-required="true" type="text" [class.is-invalid]="registerForm.get('phone').invalid && registerForm.get('phone').touched"
                        formControlName="phone" [(ngModel)]="registeredUser.phone" required>
                    <div *ngIf="registerForm.get('phone').invalid && registerForm.get('phone').touched">
                        <small *ngIf="registerForm.get('phone').errors?.required" class="text-danger">Phone Number is required</small>

                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="control-label">
                    <label id="password-lbl" for="password" class="required">Email<span class="star"> *</span></label>

                </div>
                <div class="controls">
                    <input name="email" id="email" value="" class="validate-password required form-control jsx" size="25" maxlength="99" required="required" aria-required="true" type="email" [class.is-invalid]="registerForm.get('email').invalid && registerForm.get('email').touched"
                        formControlName="email" [(ngModel)]="registeredUser.email" required>
                    <div *ngIf="registerForm.get('email').invalid && registerForm.get('email').touched">
                        <small *ngIf="registerForm.get('email').errors?.required" class="text-danger">Email is required</small>

                    </div>
                </div>
            </div>



            <button id="btn_signin_signin" [disabled]="registerForm.invalid" aria-label="Sign In" class="jsx-3452973619 base ripple primary uppercase fullWidth">Create an account</button>
            <input name="return" value="" type="hidden"><input name="7c519d6abc4458bded19328f936cce5a" value="1" type="hidden">
        </form>

    </div>
</div>