import { formatDate } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  static pincode;
  tottal;
  public base_url = "https://admin.flublet.com/";
  constructor(private http: HttpClient) {
    console.log(PaymentService.pincode);

  }


  getOrderDetails(sch) {
    var orderDetails = [];
    let params = new HttpParams();
    var paramsct: any;
    localStorage.getItem('products');
    var tot=0;
    let ct = JSON.parse(localStorage.getItem("products")).length;
    let subtotal=0;

    var order_id;
  for (let i = 0; i < ct; i++) {

      var products =JSON.parse(localStorage.getItem("products"))[i].productId;
      var product_name =JSON.parse(localStorage.getItem("products"))[i].product_name;
      var variance = JSON.parse(localStorage.getItem("products"))[i].variance;
      var delivery_charge = JSON.parse(localStorage.getItem("products"))[i].delivery_charge;
      var qty = parseInt(JSON.parse(localStorage.getItem("products"))[i].qty) + 0;
      var price = parseInt(JSON.parse(localStorage.getItem("products"))[i].price);
       var order_id = JSON.parse(localStorage.getItem("products"))[i].order_id ;
       var order_detail_id =JSON.parse(localStorage.getItem("products"))[i].order_detail_id;
      if(qty==0)
      {
        qty=1;
      }

      console.log('====================================');
      console.log('subTotal',subtotal,">>price",price,">>qunty",qty);
      console.log('====================================');
      var vendor = JSON.parse(localStorage.getItem("products"))[i].vendor;
      subtotal = (subtotal) + (price*qty);
      tot=subtotal+sch;
      this.tottal = tot;
      console.log('====================================');
      console.log('total',this.tottal);
      console.log('====================================');
      var arr={};
      orderDetails.push( {
        "id":order_detail_id,
        "productId":products,
        "productQuantity": 1,
        "productPrice": price,
        "product_name":product_name,
        "netWeight":1,
        "pincode": PaymentService.pincode,
        "comment": "good",
        "variance":variance,
        "preference":"",
        "delivery_charge":delivery_charge


    })
      // this._cart.updateOrder("RAZORPAY",true,tot,order_id,vendor).subscribe((data)=>{
      //   console.log(localStorage.getItem('products')+"this is before remove section");
      //   localStorage.removeItem('products');
      // });
  }

    paramsct = {
      "orderDate": formatDate(new Date(), 'yyyy-MM-dd', 'en'),
             "orderTotal": tot,
             "deliveryDate": formatDate(new Date(), 'yyyy-MM-dd', 'en'),
             "status": "added_to_cart",
             "isDelivered": false,
             "paymentMethod": "RazorePay",
             "paymentStatus": false,
             "vendorId":vendor,
             "customerstatus_changedate": formatDate(new Date(), 'yyyy-MM-dd', 'en'),
             "currency":"INR",
             "customerId":localStorage.getItem("user_id"),
             "orderdetails":orderDetails
         }


    return this.http.post(this.base_url + 'client_api/createorder_razorpay', paramsct);

  }

  succesHandler(info) {
    console.log('====================================');
    console.log(info);
    console.log('====================================');
    var orderDetails = [];
    let params = new HttpParams();
    var paramsct: any;
    localStorage.getItem('products');
    var tot=0;
    let ct = JSON.parse(localStorage.getItem("products")).length;
    let subtotal=0;

    var order_id;
  for (let i = 0; i < ct; i++) {

      var products =JSON.parse(localStorage.getItem("products"))[i].productId;
      var product_name =JSON.parse(localStorage.getItem("products"))[i].product_name;
      var variance = JSON.parse(localStorage.getItem("products"))[i].variance;
      var delivery_charge = JSON.parse(localStorage.getItem("products"))[i].delivery_charge;
      var qty = parseInt(JSON.parse(localStorage.getItem("products"))[i].qty) + 0;
      var price = parseInt(JSON.parse(localStorage.getItem("products"))[i].price);
      var order_id = JSON.parse(localStorage.getItem("products"))[i].order_id ;
      var Net_weight = JSON.parse(localStorage.getItem("products"))[i].Net_weight;
      var order_detail_id =JSON.parse(localStorage.getItem("products"))[i].order_detail_id;
      if(Net_weight==null){
        Net_weight =0;
      }

      if(qty==0)
      {
        qty=1;
      }
      subtotal = subtotal + (price*qty);
      var vendor = JSON.parse(localStorage.getItem("products"))[i].vendor;
      tot=tot+subtotal;

      var arr={};
      orderDetails.push( {

        "productId":products,
        "productQuantity": 1,
        "productPrice": price,
        "product_name":product_name,
        "netWeight":Net_weight,
        "pincode": PaymentService.pincode,
        "comment": "good",
        "variance":variance,
        "preference":"",
        "id":order_detail_id



    })

  }
  console.log('====================================');
  console.log('total',this.tottal);
  console.log('====================================');
///need to chnge status
    paramsct = {
      "orderDate": formatDate(new Date(), 'yyyy-MM-dd', 'en'),
             "orderTotal": this.tottal,
             "deliveryDate": formatDate(new Date(), 'yyyy-MM-dd', 'en'),
             "status": "Ordered",
             "isDelivered": false,
             "paymentMethod": "RazorePay",
             "paymentStatus": true,
             "vendorId":vendor,
             "customerstatus_changedate": formatDate(new Date(), 'yyyy-MM-dd', 'en'),
             "currency":"INR",
             "customerId":localStorage.getItem("user_id"),
             "orderdetails":orderDetails,
             "razorpay_order_id": info.razorpay_order_id,
              "razorpay_payment_id":info.razorpay_payment_id,
              "razorpay_signature": info.razorpay_signature,
              "orderCreationId":order_id,

         }


    return this.http.put(this.base_url + 'client_api/success/'+order_id, paramsct);

  }

}
