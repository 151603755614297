<!-- {{ spin }} -->
<!-- <div *ngIf="spin" class="d-flex justify-content-center">
    <div class="spinner-border" role="status">
        <span class="sr-only" id="loading"></span>
    </div>
</div> -->

<section class="bill">
    <div class="container mt-8">
        <form action="#" class="form" [formGroup]="userForm" (ngSubmit)="userEdit()">
            <div class="row gutter-lg">
                <div class="col-lg-7 mb-6">
                    <h3 class="title title-simple text-left">Billing Details</h3>
                    <div class="row">
                        <div class="col-sm-6">
                            <label>First Name *</label>
                            <input type="text" class="form-control" name="username" id="username" formControlName="username" [class.is-invalid]="userForm.get('username').invalid && userForm.get('username').touched" [(ngModel)]="saveUser.username" required>
                        </div>
                        <div class="col-sm-6">
                            <label>Last Name *</label>
                            <input type="text" class="form-control" name="last-name" required="">
                        </div>
                    </div>
                    <label>Company Name(Optional)</label>
                    <input type="text" class="form-control" name="company-name" required="">
                    <label>Country / Region *</label>
                    <input type="text" class="form-control" name="country" required="">
                    <label>Address *</label>
                    <input type="text" class="form-control" name="address" id="address" formControlName="address" [class.is-invalid]="userForm.get('address').invalid && userForm.get('address').touched" [(ngModel)]="saveUser.address" required>
                    <!-- <input type="text" class="form-control" name="address2" required="" placeholder="Appartments, suite, unit etc ..."> -->
                    <!-- <div class="row">
                        <div class="col-sm-6">
                            <label>Town / City *</label>
                            <input type="text" class="form-control" name="city" required="">
                        </div>
                        <div class="col-sm-6">
                            <label>State / County *</label>
                            <input type="text" class="form-control" name="state" required="">
                        </div>
                    </div> -->
                    <div class="row">
                        <div class="col-sm-6">
                            <label>Postcode / ZIP *</label>
                            <input type="text" class="form-control" name="postcode" id="postcode" formControlName="postcode" [class.is-invalid]="userForm.get('postcode').invalid && userForm.get('postcode').touched" [(ngModel)]="saveUser.postcode" required>
                        </div>
                        <!-- <div class="col-sm-6">
                            <label>Phone *</label>
                            <input type="text" class="form-control" name="phone"  required="">
                        </div> -->
                    </div>
                    <label>Email address *</label>
                    <input type="text" class="form-control" name="email" id="email" formControlName="email" [class.is-invalid]="userForm.get('email').invalid && userForm.get('email').touched" [(ngModel)]="saveUser.email" required>
                    <!-- <div class="form-checkbox mt-8">
                        <input type="checkbox" class="custom-checkbox" id="create-account" name="create-account">
                        <label class="form-control-label" for="create-account">Create an account?</label>
                    </div> -->
                    <!-- <div class="form-checkbox mb-6">
                        <input type="checkbox" class="custom-checkbox" id="different-address" name="different-address">
                        <label class="form-control-label" for="different-address">Ship to a different
                            address?</label>
                    </div> -->
                    <h3 class="title title-simple text-left mb-3">Additional information</h3>
                    <label>Order Notes (optional)</label>
                    <textarea class="form-control" cols="30" rows="6" placeholder="Notes about your order, e.g. special notes for delivery"></textarea>
                </div>
                <aside class="col-lg-5 sticky-sidebar-wrapper">
                    <div class="pin-wrapper" style="height: 1035.4px;">
                        <div class="sticky-sidebar" data-sticky-options="{'bottom': 50}" style="border-bottom: 0px none rgb(102, 102, 102); width: 474.163px;">
                            <h3 class="title title-simple text-left">Your Order</h3>
                            <div class="summary mb-4">
                                <div class="tbpad">
                                    <table class="order-table">
                                        <thead>
                                            <tr>
                                                <th>Product</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let crt of cart; let i=index">
                                                <td class="product-name">{{ crt.product_name}} <strong class="product-quantity">×&nbsp; {{ getCount(crt) }}</strong></td>
                                                <td class="product-total">₹ {{ crt.price }}</td>
                                            </tr>


                                            <tr class="summary-subtotal">
                                                <td>
                                                    <h4 class="summary-subtitle">Subtotal</h4>
                                                </td>
                                                <td class="summary-subtotal-price">₹ {{ sum }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <div class="Address-switcher-container">

                                                    <div class="Address-address-box Address-pincode-input Address-pdp-box">
                                                        <input type="text" placeholder="Enter Coupon code" id="availcheck" autocomplete="off" #coupon name="pincode">
                                                        <button type="button" class="Address-address-button" style="color: #086fcf" (click)="check_coupon(coupon)">
                                                        APPLY
                                                    </button>
                                                    </div>
                                                    <small class="err" id="err"></small>

                                                    <p><small class="err1" id="err1"></small></p>
                                                </div>
                                            </tr>
                                            <tr class="sumnary-shipping shipping-row-last">
                                                <td colspan="2">
                                                    <h4 class="summary-subtitle">Shipping</h4>
                                                    <ul>
                                                        <li>
                                                            <div class="custom-radio">
                                                                <input type="radio" id="free-shipping" name="shipping" class="custom-control-input" checked="">
                                                                <label *ngIf="countdelivery==0" class="custom-control-label">Free
                                                            Shipping</label>
                                                                <label class="custom-control-label" *ngIf="countdelivery!=0">₹ {{ countdelivery }}</label>


                                                            </div>
                                                        </li>
                                                        <!-- <li>
                                                        <div class="custom-radio">
                                                            <input type="radio" id="standard_shipping" name="shipping" class="custom-control-input">
                                                            <label class="custom-control-label" for="standard_shipping">Standard</label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="custom-radio">
                                                            <input type="radio" id="express_shipping" name="shipping" class="custom-control-input">
                                                            <label class="custom-control-label" for="express_shipping">Express</label>
                                                        </div>
                                                    </li> -->
                                                    </ul>
                                                </td>
                                            </tr>
                                            <tr class="summary-subtotal">
                                                <td>
                                                    <h4 class="summary-subtitle">Total</h4>
                                                </td>
                                                <td>
                                                    <p class="summary-total-price">₹ {{ sum+countdelivery }}</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <p class="checkout-info">Your personal data will used to process your order, support your experience throughout this website, and for other purposes described in our privacy policy.</p>
                                <span id="err2" class="err2"></span>
                                <button type="submit" class="jsx-278401895 jsx-3349621030 ripple">Place Order</button>
                            </div>
                        </div>
                    </div>
                </aside>
            </div>
        </form>
    </div>
</section>