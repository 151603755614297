<div class="container-fluid">
    <div class="row">
        <aside class="col-lg-12">
            <div class="heading">
                <h4>Shipping Policy</h4>
            </div>
            <div class="card">
                <p>Flublet ships its products to almost all parts of India. Orders placed will be shipped within 72 working hrs of payment confirmation. We ship on all days except Sundays and National Holidays or local disturbance.</p>
                <p>Shipping Charges: Applicable shipping charges are available on the checkout screen. We also provide Free shipping for special products.</p>
                <p>The shipping charges can be modified by Flublet.com at any point without prior intimation. The new charges would reflect on the product page as well as in the checkout flow. </p>
                <p>Every once in a while, Flublet brings out various offers in an attempt to satisfy customers. Various offers could be introduced for a limited span of time that enable free shipping of goods, subject to the fulfilment of the terms and conditions
                    attached to the offer. </p>
                <p>Estimated Delivery Time: For all areas serviced by reputed couriers, the delivery time would be within 4 to 7 business days of shipping (business days exclude Sundays and other holidays). However items weighing over 2 kilos or high volume
                    may take a 3 of days longer to reach. For other areas the products will be shipped through Indian Postal Service and may take 1-2 weeks depending on location. </p>

                <p>Tracking Packages: We will mail you the name of the courier company and the tracking number of your consignment in your registered email address.we will also mention Tracking Number and courier company in "MY ORDER" page. In case you do
                    not receive a mail from us within 72 hrs of placing an order please check your spam folder. Tracking may not appear online for up to another 24 hours in some cases, so please wait until your package is scanned by the courier company.
                </p>
                <p>Non-availability on Delivery: Our delivery partners will attempt to deliver the package thrice before it is returned back to us. Please provide your mobile number in the delivery address as it will help in making a faster delivery. </p>
                <p>In case you do not accept the parcel, the charges towards forward and reverse logistics will not be refunded. Any reattempt for delivery will be additionally chargeable and not covered under delivery TAT mentioned above.</p>
                <p>In case of COD orders being not accepted; advance payment won’t be refunded and will be charged as penalty.</p>

            </div>
        </aside>

    </div>
</div>