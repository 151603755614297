import { Component, OnInit, Input, ElementRef, ViewChildren, QueryList, NgZone } from "@angular/core";
import * as $ from "../../../node_modules/jquery";
import { ViewChild } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CategoryService } from "../services/category.service";
import { Router, ActivatedRoute, ParamMap, RouterOutlet } from "@angular/router";
import { CartService } from "../services/cart.service";
import { AutoHeightService } from "ngx-owl-carousel-o/lib/services/autoheight.service";
import { FormBuilder, Validators } from "@angular/forms";
import { AuthService } from "../services/auth.service";
import { ProductsService } from "../services/products.service";
import { ConfigService } from "../services/config.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public show:boolean = false;
  public buttonName:any = 'Show';

  public show1:boolean = false;
  public buttonna:any = 'Show1';
  @Input() top: any;
  @ViewChildren(RouterOutlet) routerOutlets: QueryList<RouterOutlet>;
 

  @ViewChild("closebutton") closebutton;
  @ViewChild("divToScroll") divToScroll: ElementRef;
  category: any;
  fill_category:any;
  banner: any;
  // sub = 0;
  sub_category:any;
  isItemAvailable = false;
  sts=false;
  mainitm;
  items = [];
  product_single;
  p_id;
  name;
  search_key;
  subtotal;
  cart: any;
  user_id;
  products;
  count = 0;
  countsub = 0;
  IsmodelShow;
  loggedUserpassword = {
    username: "",
    password: "",
  };
  loggedUser = {
    username: "",
    password: "",
  };
  loggedUser1 = {
    phone: "",
    otp: "",
    password1: "",
    cpassword1: "",
  };
  loggedUser12 = {
    us12: "",
    otp12: "",
  };
  loggedUserpassword1 = {
    username: "",
    password: "",
  };
  loggedUserpasswordset = {
    phone: "",
    password: "",
  };
  loggedUserOTP = {
    username: "",
    password: "",
  };
  locUser = {
    loc: "",
  };
  login_status = false;
  regUser = {
    email: "",
    password: "",
  };
  users = {
    status: "",
    user_id: "",
    user_name: "",
  };
  phn = {
    phone: "",
  };
  us = "";
  phone = "";
  usData = "";
  proceedAction = false;
  userAction = false;
  userAction1 = false;
  oder_details = "";
  logCheck = false;
  first_time=true;
  dummyarr = [1, 2, 3, 4, 5];

  catLoadflag = false;
  catCount = 0;
  catLoadCount = 0;
   catShow=true;
   searchText="";
   private sub:any;
  constructor(
    private _auth: AuthService,
    private _category: CategoryService,
    private _product: ProductsService,
    private router: Router,
    private cartService: CartService,
    private fb: FormBuilder,
    private _router: Router,
    private _config: ConfigService,
    private _cart: CartService,
    private ngZone: NgZone,
    private route: ActivatedRoute
  ) { }
  img_link = this._config.config_url.slice(0, -1);
  loginForm = this.fb.group({
    username: ["", [Validators.required]],
    password: ["", [Validators.required]],
  });
  loginForm1 = this.fb.group({
    phone: ["", [Validators.required]],
    otp: ["", [Validators.required]],
    password1: ["", [Validators.required]],
    cpassword1: ["", [Validators.required]],
  });
  loginForm12 = this.fb.group({
    us12: ["", [Validators.required]],
    otp12: ["", [Validators.required]],
  });
  locForm = this.fb.group({
    loc: ["", [Validators.required]],
  });
  regForm = this.fb.group({
    email: ["", [Validators.required]],
    password: ["", [Validators.required]],
  });
  


  testing() {
    $("#sidebarCollapse").on("click", function() {
      $("#sidebar").addClass("active");
    });
  
    $("#sidebarCollapseX").on("click", function() {
      $("#sidebar").removeClass("active");
    });
  
    $("#sidebarCollapse").on("click", function() {
      if ($("#sidebar").hasClass("active")) {
        $(".overlay").addClass("visible");
      }
    });
  
    $("#sidebarCollapseX").on("click", function() {
      $(".overlay").removeClass("visible");
    });
    $("#slidebar").click(function(){
      window.close();
  });
    
  }
  


  toggle() {
    this.show = !this.show;

    // CHANGE THE NAME OF THE BUTTON.
    if(this.show)  
      this.buttonName = "Hide";
    else
      this.buttonName = "Show";
  }
  


  min() {
    this.show1 = !this.show1;

    // CHANGE THE NAME OF THE BUTTON.
    if(this.show1)  
      this.buttonna = "Hide1";
    else
      this.buttonna = "Show1";
  }
  
  locatUser() {
    localStorage.setItem("location", this.locUser.loc);
    this._product.getProducts(this.locUser.loc).subscribe(
      (res) => {
        this.closebutton.nativeElement.click();
        window.location.reload();
      },
      (err) => {
        console.log(err);
        // alert("No item Found");
      }
    );
  }



  search() {
    this._router.navigate(["/search/" + this.searchText]);
  }
  onSearchChange(search: string)
	{
		
		 this._product.observableEx.next(search);

	}
  checkout() {
    document.querySelector(".mini_cart").classList.remove("active");
    if (localStorage.getItem("user_id") === null) {
      document.querySelector(".mini_log").classList.add("active");
    } else {
      this._router.navigate(["/cart"]);
    }
  }

  logout() {
   
    localStorage.clear();
    this._router.navigate([""]).then(() => {
     
      window.location.reload();
    });
  }


  ngOnInit(): void {

    this._router.events.subscribe((val) => {
      if (this.router.url === "") 
      {
        this.catShow=true;
      }
      if (this.router.url === "/") 
      {
        this.catShow=true;
      }
      if (this.router.url === "/home") 
      {
        this.catShow=true;
      }
      else
      {
        this.catShow=false;
      }
     
  });

    let inputField: HTMLElement = <HTMLElement>(
      document.querySelector(".is-active")
    );
    inputField && inputField.focus();

    if (localStorage.getItem("user_id") === null) {
      this.login_status = false;
    } else {
      this.login_status = true;
    }
    this._category.getCategories().subscribe((data) => {
      let cat = JSON.parse(JSON.stringify(data));
      localStorage.setItem("category", JSON.stringify(cat));
      localStorage.setItem("banner", JSON.stringify(cat));
      let fil_cat = JSON.parse(localStorage.getItem("category")).filter(
        (item) =>
          item.category_name.indexOf("Banner1_Slider1") <= -1 &&
          item.category_name.indexOf("Banner1_Slider2") <= -1 &&
          item.category_name.indexOf("Banner1_Slider3") <= -1 &&
          item.category_name.indexOf("Banner2") <= -1 &&
          item.category_name.indexOf("Banner3") <= -1
      );

      let fil_banner = JSON.parse(localStorage.getItem("category")).filter(
        (item) =>
          item.category_name.indexOf("Banner1_Slider1") > -1 ||
          item.category_name.indexOf("Banner1_Slider2") > -1 ||
          item.category_name.indexOf("Banner1_Slider3") > -1 ||
          item.category_name.indexOf("Banner2") > -1 ||
          item.category_name.indexOf("Banner3") > -1
      );
      localStorage.setItem("banner", JSON.stringify(fil_banner));
      localStorage.setItem("category", JSON.stringify(fil_cat));
      this.category = fil_cat;
      this.fill_category=fil_cat;
      this.banner = fil_banner;
	  this.catCount=this.category.length;
    });

    this.cartService.getCart().subscribe((data) => {
      // debugger
      this.cart = JSON.parse(JSON.stringify(data));
    });

    this.cartService.getCartCount().subscribe((data) => {
      this.count = JSON.parse(JSON.stringify(data));
    });

    this.cartService.getCartCountSub().subscribe((data) => {
      this.countsub = JSON.parse(JSON.stringify(data));
    });

    if (localStorage.getItem("products")) {
      this.cart = JSON.parse(localStorage.getItem("products"));
      let cnt = 0;
      for (
        let i = 0;
        i < JSON.parse(localStorage.getItem("products")).length;
        i++
      ) {
        cnt =
          cnt + parseInt(JSON.parse(localStorage.getItem("products"))[i].qty);
      }
      this.count = cnt;
    }

    if (localStorage.getItem("products") !== null) {
      for (
        let i = 0;
        i < JSON.parse(localStorage.getItem("products")).length;
        i++
      ) {
        this.countsub =
          this.countsub +
          JSON.parse(localStorage.getItem("products"))[i].subtotal;
      }
    }
    this.sts=false; 
  }

  removeProduct(product) {
    // debugger;

    this.cartService.removeCart(product).subscribe((data) => {
      let currentUrl = this.router.url;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = "reload";
      this.router.navigate([currentUrl]);
      let d = data;
      if (localStorage.getItem("user_id")) {
        let myId = product.order_detail_id;
       
        this.cartService.removeCartLogin(myId).subscribe((data) => {});
      }
    });
    this.cartService.getCartCountSub().subscribe((data) => {
      this.countsub = JSON.parse(JSON.stringify(data));
	this.ngOnInit();
    });
   
  }

  show_login(user: HTMLInputElement) {
    // alert(user.value);
    if (user.value != "") {
      this.phn.phone = user.value;
      this._auth.userCheck(this.phn).subscribe((data) => {
        this.usData = JSON.parse(JSON.stringify(data));

        if (this.usData["status"] == "Existing User") {
          this.userAction1 = true;
          this.userAction = false;
        } else {
          this.userAction1 = false;
          this.userAction = true;
        }
      });

      this.proceedAction = !this.proceedAction;
      this.us = user.value;
      this.phone = user.value;
      this.loggedUser.username = user.value;
      this.loggedUser1.phone = user.value;
    } else {
      user.focus();
    }
  }
  openModal() {
    this._config.openModal = true;
  }
  cls() {
    document
      .querySelector(".offcanvas_menu_wrapper")
      .classList.remove("active");
    document.querySelector(".off_canvars_overlay").classList.remove("active");
  }
 
  catLoaded() {
    this.catLoadCount = this.catLoadCount + 1;

    if (this.catLoadCount === this.catCount) {
      this.catLoadflag = true;
    }
  }
forgot(user: HTMLInputElement)
	{
		this.userAction1 = false;
		this.userAction = true;
			this.us = user.value;
			this.phone = user.value;
			this.loggedUser.username = user.value;
			this.loggedUser1.phone = user.value;
	}
  openSearch() {
    this.router.navigate(['/search',this.searchText]);
    }
    getCat(id)
    {
      this._category.getSubCategories(id).subscribe((data)=>{
       return this.sub_category=JSON.parse(JSON.stringify(data));
      })
    }
    // filterItems() {

    //   return this.product.filter(product => {
    //     this.sts=true;
    //     return this.product['product_name'].toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1;
       
    //   });
  
  
    // }
    initializeItems(){
      this._product.getAllProducts().subscribe((data)=>{
        let pro=JSON.parse(JSON.stringify(data));
        this.products=JSON.parse(JSON.stringify(data));   
        this.items = this.products;
   });
     
  }
    getItems(ev: any) {
      // alert(ev);
      this.fill_category=[];
      this.sts=true;
          this.initializeItems();
        const val = ev.target.value;
        if (val && val.trim() !== '') {
          this.mainitm=false;
            this.isItemAvailable = false;
            console.log(JSON.stringify(this.items) +"this is items");
            this.items=JSON.parse(JSON.stringify(this.items));
            this.mainitm=null;
            this.items = this.items.filter((item) => {
              this.isItemAvailable = true;   
              
              if(item.product_name.toLowerCase().indexOf(val.toLowerCase()) > -1)
              {
                this.mainitm=true;
                  this.fill_category.push({'id':item.category,'category_name':item.category_name,'category_image':item.category_image,'parent':item.category_parent_id})
                
              }
              
                return (item.product_name.toLowerCase().indexOf(val.toLowerCase()) > -1);
            })
            if(this.mainitm)
              {
                this.isItemAvailable = true;
              }
              else
              {
                this.isItemAvailable = false; 
              }
        } else {
            this.isItemAvailable = false;
        }
    
    }
 
}
