import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
usData;
phoneNumber;
otp;
uData;
  constructor(private _auth:AuthService,
    private router: Router
    ) { }

  ngOnInit(): void {
    document.getElementById('finalSuccess').innerHTML="";
  }

  generateOtp(phoneNumber: HTMLInputElement)
  {
    document.getElementById('finalSuccess').innerHTML="";
    if(phoneNumber.value)
    {
      this.phoneNumber=phoneNumber.value;
      document.getElementById('phoneError').innerHTML="";
      this._auth.userCheck({phone:phoneNumber.value}).subscribe((data) => {

				this.usData = JSON.parse(JSON.stringify(data));
			
				if (this.usData['status'] == "Existing User") {
          document.getElementById('phoneError').innerHTML="OTP Send Successfully";
				} else {
          document.getElementById('phoneError').innerHTML="No user found for this Mobile Number";
				}
			});	

    }
    else
    {
      document.getElementById('phoneError').innerHTML="Please provide your phone number";
    }
  }

  resetPassword(otp:HTMLInputElement,newpassword:HTMLInputElement)
  {
    if(otp.value&&newpassword.value)
    {
      document.getElementById('errorotp').innerHTML="";
      this.uData={phone:this.phoneNumber,otp:otp.value,password:newpassword.value}
      this._auth.loginUserPasswordSet(this.uData).subscribe((res) => {
        if (res['token'] != "") {
          document.getElementById('finalSuccess').innerHTML="Password Updated Successfully";
          otp.value="";
          newpassword.value=""; document.getElementById('phoneError').innerHTML="";
          //document.getElementById("username").value="";
         // document.getElementById('finalError').innerHTML="Error !!! Please try again";
        }
        else
        {
          alert("Error !!! please  try again");
         // document.getElementById('finalError').innerHTML="Error !!! Please try again";
        }
      })
    }
    else
    {
      document.getElementById('errorotp').innerHTML="Please Enter OTP and New password";
    }
  }

}
