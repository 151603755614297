<div class="container-fluid">
    <div class="row">
        <aside class="col-lg-9">
            <div class="card">
                <div class="table-responsive">
                    <table class="table table-borderless table-shopping-cart">
                        <thead class="text-muted">
                            <tr class="small text-uppercase">
                                <th scope="col">Product</th>
                                <th scope="col" class="text-right d-none d-md-block" width="200"></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let crt of wishlist; let i=index">
                                <td>
                                    <figure class="itemside align-items-center">
                                        <div class="aside"><img [src]="img_link+crt.product_image1" class="img-sm"></div>
                                        <figcaption class="info">
                                            <!-- <span class="text-muted small">Emerald</span> -->
                                            <br>
                                            <a href="#" class="title text-dark" data-abc="true">{{ crt.product_name}}</a>
                                            <i class="fa fa-trash rmv" (click)="removeWishlistProduct(crt)"> Remove</i>
                                        </figcaption>
                                    </figure>
                                </td>


                                <td class="text-right d-none d-md-block">
                                    <div class="price-wrap"> <var class="price">₹ {{ crt.price }}</var>
                                        <!-- <small class="text-muted">
                                    $9.20 each </small>  -->
                                    </div>
                                </td>
                                <td>
                                    <!-- <div class="jsx-278401895 jsx-3349621030 cartCtaWrapper">
                                        <button (click)="addProduct(crt)" aria-label="Checkout Now" class="jsx-278401895 jsx-3349621030 ripple">Move to Cart</button></div> -->
                                </td>
                            </tr>




                        </tbody>
                    </table>
                </div>
            </div>
        </aside>
        <aside class="col-lg-3">

            <div class="card">
                <div class="card-body">




                    <hr>


                </div>
            </div>
        </aside>
    </div>
</div>