<div class="container-fluid">
    <div class="row">
        <div class="col-md-2 sidebar">

            <div class="sc-1y4n57f-0 juQrmL">
                <div data-qa="Brand" class="sc-1fy0rx2-0 eSqSED">
                    <button type="button" class="sc-1fy0rx2-1 liJOhO" href="#demo" data-toggle="collapse" aria-expanded="true"><div class="sc-1fy0rx2-3 cfEypk"><div class="sc-1fy0rx2-3 cfEypk"><div class="sc-1fy0rx2-3 cfEypk"><span>Brand</span></div><img class="open" src="https://z.nooncdn.com/s/app/com/noon/icons/down-chevron.svg" alt="Brand"></div></div></button>
                    <div open="" class="sc-1fy0rx2-2 jiOBmh" id="demo" class="collapse">
                        <div data-qa="brand-filter-list" class="vcf78e-0 gKInrG jiOBmh">
                            <!-- <div class="searchContainer">
                                <div class="vcf78e-1 dOHyug"><img src="https://z.nooncdn.com/s/app/com/noon/icons/search.svg" alt="search"></div><input type="text" placeholder="Search " value=""></div> -->
                            <label class="vcf78e-2 bXceLU" *ngFor="let b of brands"><span  *ngIf="b"><input id="brand_skechers" class="brand_check" type="checkbox" value="{{ b }}" (change)="getBrandProducts($event)" >&nbsp;&nbsp;
                                    <label class="bname">{{ b }}</label> <span class="bcount">&nbsp;({{ getBrandCount(b) }})</span>
                            </span>
                            </label>

                            <br>
                            <!-- <button type="button" data-qa="btn-see-all" class="limitToggle seeAll" aria-label="See All">See All</button> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="sc-1y4n57f-0 juQrmL">
                <div data-qa="Brand" class="sc-1fy0rx2-0 eSqSED">
                    <button type="button" class="sc-1fy0rx2-1 liJOhO" href="#demo1" data-toggle="collapse"><div class="sc-1fy0rx2-3 cfEypk"><div class="sc-1fy0rx2-3 cfEypk"><div class="sc-1fy0rx2-3 cfEypk"><span>Price</span></div><img class="open" src="https://z.nooncdn.com/s/app/com/noon/icons/down-chevron.svg" alt="Brand"></div></div></button>
                    <div open="" class="sc-1fy0rx2-2 jiOBmh" id="demo1">
                        <label class="prrange pricefilter" (click)="getPriceProducts(0,500,$event)">Within ₹ 500</label>
                        <label class="prrange pricefilter" (click)="getPriceProducts(500,750,$event)">₹ 500 - ₹ 750</label>
                        <label class="prrange pricefilter" (click)="getPriceProducts(750,1000,$event)">₹ 750 - ₹ 1,000</label>
                        <label class="prrange pricefilter" (click)="getPriceProducts(1000,1500,$event)">₹ 1,000 - ₹ 1,500</label>
                        <label class="prrange pricefilter" (click)="getPriceProductsNoRange(1500,$event)">More than ₹ 1,500</label>
                        <div class="bialit-0 enUhaf">

                            <input type="number" #min name="min" class="textInput" aria-label="min" data-qa="filter-min-input" value="5"><span class="between to">To</span>
                            <input type="number" #max name="max" class="textInput" aria-label="max" data-qa="filter-max-input" value="9999"><button class="between" type="button" data-qa="filter-price-btn" (click)="getPriceProducts(min.value,max.value,$event)">go</button></div>
                    </div>
                </div>
            </div>
            <div class="sc-1y4n57f-0 juQrmL">
                <div data-qa="Brand" class="sc-1fy0rx2-0 eSqSED">
                    <button type="button" class="sc-1fy0rx2-1 liJOhO" href="#demo2" data-toggle="collapse"><div class="sc-1fy0rx2-3 cfEypk"><div class="sc-1fy0rx2-3 cfEypk"><div class="sc-1fy0rx2-3 cfEypk"><span>Discount</span></div><img class="open" src="https://z.nooncdn.com/s/app/com/noon/icons/down-chevron.svg" alt="Brand"></div></div></button>
                    <div open="" class="sc-1fy0rx2-2 jiOBmh" id="demo2">
                        <label class="prrange discountfilter" (click)="getDiscountProducts(10,$event)">10% and above</label>
                        <label class="prrange discountfilter" (click)="getDiscountProducts(20,$event)">20% and above</label>
                        <label class="prrange discountfilter" (click)="getDiscountProducts(30,$event)">30% and above</label>
                        <label class="prrange discountfilter" (click)="getDiscountProducts(40,$event)">40% and above</label>
                        <label class="prrange discountfilter" (click)="getDiscountProducts(50,$event)">50% and above</label>
                        <label class="prrange discountfilter" (click)="getDiscountProducts(60,$event)">60% and above</label>
                        <label class="prrange discountfilter" (click)="getDiscountProducts(70,$event)">70% and above</label>
                        <label class="prrange discountfilter" (click)="getDiscountProducts(80,$event)">80% and above</label>

                    </div>
                </div>
            </div>

            <div class="sc-1y4n57f-0 juQrmL">
                <div data-qa="Brand" class="sc-1fy0rx2-0 eSqSED">
                    <button type="button" class="sc-1fy0rx2-1 liJOhO" href="#demo3" data-toggle="collapse"><div class="sc-1fy0rx2-3 cfEypk"><div class="sc-1fy0rx2-3 cfEypk"><div class="sc-1fy0rx2-3 cfEypk"><span>Customer rating</span></div><img class="open" src="https://z.nooncdn.com/s/app/com/noon/icons/down-chevron.svg" alt="Brand"></div></div></button>
                    <div open="" class="sc-1fy0rx2-2 jiOBmh" id="demo3">

                        <div class="star-rating " (click)="getReviewProducts(4,$event)">
                            <span class="ratetest reviewfilter"> More</span>
                            <span>
                            <input type="radio" id="5-stars" name="rating" />
                            <label for="5-stars" class="star">&#9733;</label>
                        </span>
                            <span>
                            <input type="radio" id="5-stars" name="rating" />
                            <label for="5-stars" class="star strsel">&#9733;</label>
                        </span>
                            <span>
                            <input type="radio" id="5-stars" name="rating" />
                            <label for="5-stars" class="star strsel">&#9733;</label>
                        </span>
                            <span>
                            <input type="radio" id="5-stars" name="rating" />
                            <label for="5-stars" class="star strsel">&#9733;</label>
                        </span>
                            <span>
                            <input type="radio" id="5-stars" name="rating" />
                            <label for="5-stars" class="star strsel">&#9733;</label>
                        </span>

                        </div>

                        <div class="star-rating " (click)="getReviewProducts(3,$event)">
                            <span class="ratetest reviewfilter"> More</span>
                            <span>
                            <input type="radio" id="5-stars" name="rating" />
                            <label for="5-stars" class="star">&#9733;</label>
                        </span>
                            <span>
                            <input type="radio" id="5-stars" name="rating" />
                            <label for="5-stars" class="star">&#9733;</label>
                        </span>
                            <span>
                            <input type="radio" id="5-stars" name="rating" />
                            <label for="5-stars" class="star strsel">&#9733;</label>
                        </span>
                            <span>
                            <input type="radio" id="5-stars" name="rating" />
                            <label for="5-stars" class="star strsel">&#9733;</label>
                        </span>
                            <span>
                            <input type="radio" id="5-stars" name="rating" />
                            <label for="5-stars" class="star strsel">&#9733;</label>
                        </span>
                        </div>

                        <div class="star-rating " (click)="getReviewProducts(2,$event)">
                            <span class="ratetest reviewfilter"> More</span>
                            <span>
                            <input type="radio" id="5-stars" name="rating" />
                            <label for="5-stars" class="star">&#9733;</label>
                        </span>
                            <span>
                            <input type="radio" id="5-stars" name="rating" />
                            <label for="5-stars" class="star">&#9733;</label>
                        </span>
                            <span>
                            <input type="radio" id="5-stars" name="rating" />
                            <label for="5-stars" class="star">&#9733;</label>
                        </span>
                            <span>
                            <input type="radio" id="5-stars" name="rating" />
                            <label for="5-stars" class="star strsel">&#9733;</label>
                        </span>
                            <span>
                            <input type="radio" id="5-stars" name="rating" />
                            <label for="5-stars" class="star strsel">&#9733;</label>
                        </span>
                        </div>

                        <div class="star-rating " (click)="getReviewProducts(1,$event)">
                            <span class="ratetest reviewfilter"> More</span>
                            <span>
                            <input type="radio" id="5-stars" name="rating" />
                            <label for="5-stars" class="star">&#9733;</label>
                        </span>
                            <span>
                            <input type="radio" id="5-stars" name="rating" />
                            <label for="5-stars" class="star">&#9733;</label>
                        </span>
                            <span>
                            <input type="radio" id="5-stars" name="rating" />
                            <label for="5-stars" class="star">&#9733;</label>
                        </span>
                            <span>
                            <input type="radio" id="5-stars" name="rating" />
                            <label for="5-stars" class="star">&#9733;</label>
                        </span>
                            <span>
                            <input type="radio" id="5-stars" name="rating" />
                            <label for="5-stars" class="star strsel">&#9733;</label>
                        </span>
                        </div>

                    </div>
                </div>
            </div>






        </div>
        <div class="col-md-10">

            <div class="container">
                <header class="jsx-3400163498">
                    <h3 class="jsx-3400163498">{{ name }}</h3>
                </header>
            </div>

            <section>
                <div class="col-lg-12 " *ngFor="let pro of product">



                    <div class="thumb-wrapper">
                        <div class="img-box">
                            <img [src]="img_link+pro.product_image1 " [routerLink]="[ '/product-details',pro.id,pro.product_name ] " class="img-responsive img-fluid imgg" alt="">
                        </div>


                        <div class="thumb-content">
                            <span class="items-names" [routerLink]="[ '/product-details',pro.id,pro.product_name ] ">{{ pro.product_name | slice:0:8}}
                            </span>
                            <!-- <div class="jsx-193278340">
                                <img class="wishlist" (click)="addWishlist(pro)" src="https://pngimage.net/wp-content/uploads/2018/06/wishlist-icon-png-3.png" />
                            </div> -->
                            <div class="jsx-193278340 container" *ngIf="getWish(pro)<1">
                                <img class="wishlist" (click)="addWishlist(pro)" src="../../assets/img/wishblack.png" />
                                <!-- <img src="https://k.nooncdn.com/s/app/2019/noon-bigalog/458fc09acbbb401ca3536a0aa1221bc4e73c7bff/static/images/noon-express-en.png" alt="noon-express" class="jsx-193278340 fbn"> -->
                            </div>
                            <div class="jsx-193278340 container" *ngIf="getWish(pro)>0">
                                <img class="wishlist" (click)="addWishlist(pro)" src="../../assets/img/wishred.png" />
                                <!-- <img src="https://k.nooncdn.com/s/app/2019/noon-bigalog/458fc09acbbb401ca3536a0aa1221bc4e73c7bff/static/images/noon-express-en.png" alt="noon-express" class="jsx-193278340 fbn"> -->
                            </div>
                            <p class="jsx-4251264678 price">
                                <span class="jsx-4251264678 sellingPrice">
                                    <span>
                                        <span class="currency medium">₹</span>
                                <span class="value">{{ pro.sales_rate }}</span></span>
                                </span>
                                <span class="jsx-4251264678 preReductionPrice">
                                    <span>
                                        <span class="currency null"> ₹</span>
                                <span class="value">{{ pro.price }}</span>
                                </span>

                                </span>
                                <span class="jsx-2467480612 discountTag"><span
                                    class="jsx-2467480612 bg"></span><span class="jsx-2467480612 ">{{ pro.offer }}% Off</span></span>
                            </p>

                        </div>
                    </div>
                </div>




            </section>
            <div class="row sld">
                <div class="colm col-md-2 slider_ed1" *ngFor="let pro of products_cat">
                    <div class="thumb-wrapper">
                        <div class="img-box">
                            <img [src]="img_link+pro.product_image1 " [routerLink]="[ '/product-details',pro.id,pro.product_name ] " class="img-responsive img-fluid imgg" alt="">
                        </div>




                        <div class="thumb-content">
                            <span class="items-names" [routerLink]="[ '/product-details',pro.id,pro.product_name ] "> {{ pro.product_name | slice:0:15}} </span>
                            <!-- <div class="jsx-193278340 container">
                                <img class="wishlist" (click)="addWishlist(pro)" src="https://pngimage.net/wp-content/uploads/2018/06/wishlist-icon-png-3.png" />
                               
                            </div> -->
                            <div class="jsx-193278340 container" *ngIf="getWish(pro)<1">
                                <img class="wishlist" (click)="addWishlist(pro)" src="../../assets/img/wishblack.png" />
                                <!-- <img src="https://k.nooncdn.com/s/app/2019/noon-bigalog/458fc09acbbb401ca3536a0aa1221bc4e73c7bff/static/images/noon-express-en.png" alt="noon-express" class="jsx-193278340 fbn"> -->
                            </div>
                            <div class="jsx-193278340 container" *ngIf="getWish(pro)>0">
                                <img class="wishlist" (click)="addWishlist(pro)" src="../../assets/img/wishred.png" />
                                <!-- <img src="https://k.nooncdn.com/s/app/2019/noon-bigalog/458fc09acbbb401ca3536a0aa1221bc4e73c7bff/static/images/noon-express-en.png" alt="noon-express" class="jsx-193278340 fbn"> -->
                            </div>
                            <p class="jsx-4251264678 price">
                                <span class="jsx-4251264678 sellingPrice">
                        <span>
                            <span class="currency medium">₹</span>
                                <span class="value">{{ pro.sales_rate }}</span></span>
                                </span>
                                <span class="jsx-4251264678 preReductionPrice">
                        <span>
                            <span class="currency null"> ₹</span>
                                <span class="value">{{ pro.price }}</span>
                                </span>

                                </span>
                                <span class="jsx-2467480612 discountTag"><span
                        class="jsx-2467480612 bg"></span><span class="jsx-2467480612 ">{{ pro.offer }}% Off</span></span>
                            </p>

                        </div>
                    </div>
                </div>
            </div>




            <!-- <div id="myCarousel" class="row" data-ride="" data-interval="0">
                <div class="col-md-12">
                    <div class="">
                        <div class="rows">
                            <div class="colm col-md-2" *ngFor="let pro of products">
                                <div class="thumb-wrapper">
                                    <div class="img-box">
                                        <img [src]="img_link+pro.product_image1 " [routerLink]="[ '/product-details',pro.id,pro.product_name ] " class="img-responsive img-fluid imgg" alt="">
                                    </div>
                                    <div class="thumb-content">
                                        <span class="items-names" [routerLink]="[ '/product-details',pro.id,pro.product_name ] "> {{ pro.product_name | slice:0:15}} </span>
                                        <div class="jsx-193278340 container" *ngIf="getWish(pro)<1">
                                            <img class="wishlist" (click)="addWishlist(pro)" src="../../assets/img/wishblack.png" />
                                        </div>
                                        <div class="jsx-193278340 container" *ngIf="getWish(pro)>0">
                                            <img class="wishlist" (click)="addWishlist(pro)" src="../../assets/img/wishred.png" />
                                        </div>
                                        <p class="jsx-4251264678 price">
                                            <span class="jsx-4251264678 sellingPrice">
                                    <span>
                                        <span class="currency medium">₹</span>
                                            <span class="value">{{ pro.sales_rate }}</span></span>
                                            </span>
                                            <span class="jsx-4251264678 preReductionPrice">
                                    <span>
                                        <span class="currency null"> ₹</span>
                                            <span class="value">{{ pro.price }}</span>
                                            </span>
                                            </span>
                                            <span class="jsx-2467480612 discountTag"><span
                                    class="jsx-2467480612 bg"></span><span class="jsx-2467480612 ">{{ pro.offer }}% Off</span></span>
                                        </p>

                                    </div>
                                </div>
                            </div>





                        </div>
                    </div>




                </div>

            </div> -->
        </div>
    </div>
    <div class="abc" id="snackbar"></div>
</div>