<section class="log"></section>
<div class="container">
    <div class="login-form mx-auto d-block w-100">
        <div class="page-header text-center">
            <small>{{ err }}</small>
            <!-- <h6>Welcome back!</h6> -->
            <h6></h6>
            <h1 class="logh1"><strong>Sign in to your account
			</strong></h1>
        </div>
        <div class="text-center mb-30">
            Don’t have an account?<a routerLink="/signup"> Sign up</a>
        </div>
        <div class="errmain">
            <small class="err" id="err">  </small>
        </div>
        <form [formGroup]="loginForm" (ngSubmit)="loginUser()" method="post" class="form-validate" _lpchecked="1">
            <div class="form-group">
                <div class="control-label">
                    <label id="username-lbl" for="username" class="required invalid">Phone Number<span class="star"> *</span></label>
                </div>
                <div class="controls">
                    <input name="username" id="username" value="" class="validate-username required form-control invalid jsx" size="25" required="required" aria-required="true" autofocus="" aria-invalid="true" type="text" [class.is-invalid]="loginForm.get('username').invalid && loginForm.get('username').touched"
                        formControlName="username" [(ngModel)]="logUser.username" required>
                    <div *ngIf="loginForm.get('username').invalid && loginForm.get('username').touched">
                        <small *ngIf="loginForm.get('username').errors?.required" class="text-danger">Phone Number is required</small>

                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="control-label">
                    <label id="password-lbl" for="password" class="required">Password<span class="star"> *</span></label>

                </div>
                <div class="controls">
                    <input name="password" id="password" value="" class="validate-password required form-control jsx" size="25" maxlength="99" required="required" aria-required="true" type="password" [class.is-invalid]="loginForm.get('password').invalid && loginForm.get('password').touched"
                        formControlName="password" [(ngModel)]="logUser.password" required>
                    <div *ngIf="loginForm.get('password').invalid && loginForm.get('password').touched">
                        <small *ngIf="loginForm.get('password').errors?.required" class="text-danger">password is required</small>

                    </div>
                </div>
            </div>

            <div class="text-center">
                <a routerLink="/forgot-password">Forgot your password?</a>
            </div>




            <button id="btn_signin_signin" aria-label="Sign In" class="jsx-3452973619 base ripple primary uppercase fullWidth">Sign In</button>
            <input name="return" value="" type="hidden"><input name="7c519d6abc4458bded19328f936cce5a" value="1" type="hidden">
        </form>

    </div>
</div>