import { Route } from '@angular/compiler/src/core';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CartService } from '../services/cart.service';
import { CategoryService } from '../services/category.service';
import { ConfigService } from '../services/config.service';
import { ProductsService } from '../services/products.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Location } from '@angular/common';



declare var jquery:any;
declare var $ :any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  
  private isButtonVisible = true;
  private isButtonVisible1 = false;
  customOptions: any = {
    loop: true,
    margin: 10,
    autoplay: true,
    responsiveClass: true,

    navText: ['Previous', 'Next'],
    responsive: {
      0: {
        items: 1
      },
      480: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: true,

  }


  bannerLoadflag=false;
  catLoadflag=false;
  catCount=0;
  catLoadCount=0;
  prodLoadflag=false;
  prodCount=0;
  prodLoadCount=0;
  banner1Loadflag=false;
  dummyarray=[1,2,3,4];
  countsub=0;


  //for slider 2
  customOptionsSecond: any = {
    loop: true,
    margin: 10,
    autoplay: false,
    responsiveClass: true,
    // pause:true,
    navText: ['<i class="ion-ios-arrow-left"></i>', '<i class="ion-ios-arrow-right"></i>'],
    responsive: {
      0: {
        items: 1
      },
      480: {
        items: 2
      },
      940: {
        items: 3
      }
    },
    nav: true,

  }
  orgvr;
orderId="";
oder_details="";
  added;
  addedno;
  addBtn;
  addedBtn;
  product = [];
  pcount=[];
  banners = [];
  productbefore = [];
  private sub: any;
  p_id;
  cart: any;
  cart_item_id = [];
  name;
  category: any;
  banner: any;
  updatedqty = 1;
  pincode;
 product_count=0;
lbanner1_slider1=[];
lbanner1_slider2=[];
lbanner1_slider3=[];
lbanner2=[];
lbanner3=[];
lbanner4=[];
lbanner5=[];
lbanner6=[];
lbanner7=[];
lbanner8=[];
lbanner9=[];


  isLoadedBanner=false;
  isLoadedProduct=false;
  upQty=[];
  login_status=false;
  slideOpt = {
    loop: true,
    effect: 'slide',

    freeMode: true,
    freeModeSticky: false,

    slidesPerView: 6,
    spaceBetween: 25
  };

  constructor(private _product: ProductsService, 
    private route: ActivatedRoute, 
    private router: Router, 
    private _category: CategoryService, 
    private _cart: CartService, 
    private _config: ConfigService,
    private location: Location
    ) { 


  }
  img_link = this._config.config_url.slice(0, -1);
  slideOptsOne = {
    initialSlide: 0,
    slidesPerView: 1,
    autoplay: true
  };

  ngOnInit(): void {
    
    this._config.chack_netStatus();

    if (localStorage.getItem("user_id") === null) {
			this.login_status = false;
		} else {
			this.login_status = true;
		}
    if (localStorage.getItem('location') !== null) {
      this.pincode = localStorage.getItem('location');
    }

    this._product.getAllProducts().subscribe((data) => {
      this.product = JSON.parse(JSON.stringify(data));
      this.isLoadedProduct=true;
      this.prodCount=this.product.length;
    });

   this._category.getAllBanners().subscribe((data) => {
      this.banners = JSON.parse(JSON.stringify(data));

      console.log(JSON.stringify(this.banners)+"this is the banner list");
      this.isLoadedBanner=true;
 this.lbanner1_slider1= this.banners.filter(
 banners => banners.category_name === "Banner1_Slider1");
 this.lbanner1_slider2= this.banners.filter(
 banners => banners.category_name === "Banner1_Slider2");
 this.lbanner1_slider3= this.banners.filter(
 banners => banners.category_name === "Banner1_Slider3");
this.lbanner2= this.banners.filter(
banners => banners.category_name === "Banner2");
this.lbanner3= this.banners.filter(
banners => banners.category_name === "Banner3");
this.lbanner4= this.banners.filter(
banners => banners.category_name === "Banner4");
this.lbanner5= this.banners.filter(
 banners => banners.category_name === "Banner5");
this.lbanner6= this.banners.filter(
  banners => banners.category_name === "Banner6");
this.lbanner7= this.banners.filter(
banners => banners.category_name === "Banner7");
this.lbanner8= this.banners.filter(
banners => banners.category_name === "Banner8");
this.lbanner9= this.banners.filter(
banners => banners.category_name === "Banner9");
        console.log(JSON.stringify(this.lbanner1_slider1)+"this is filter");
//       for (var i = 0; i < this.banners.length; i++) {

//   if (this.banners[i]["category_name"] === "Banner1_Slider1") {
//     this.banner1_slider1 = this.banners[i]["category_image"];
//   }
  
//   if (this.banners[i]["category_name"] === "Banner1_Slider2") {
//     this.banner1_slider2 = this.banners[i]["category_image"];
//   }
//   if (this.banners[i]["category_name"] === "Banner1_Slider3") {
//     this.banner1_slider3 = this.banners[i]["category_image"];
//   }
        
//         if (this.banners[i]["category_name"] === "Banner2") {
//           this.banner2 = this.banners[i]["category_image"];
//           if(this.banners[i]["parent"]!=null)
//           {
//           this.banner2_linkid = this.banners[i]["parent"].id;
//           this.banner2_linkname= this.banners[i]["parent"].category_name;
//           }

//         }
//         if (this.banners[i]["category_name"] === "Banner3") {
//           this.banner3 = this.banners[i]["category_image"];
//           if(this.banners[i]["parent"]!=null)
//           {
//           this.banner3_linkid = this.banners[i]["parent"].id;
//           this.banner3_linkname= this.banners[i]["parent"].category_name;
//           }
//         }
//         if (this.banners[i]["category_name"] === "Banner4") {
//           this.banner4 = this.banners[i]["category_image"];
//           if(this.banners[i]["parent"]!=null)
//           {
//           this.banner4_linkid = this.banners[i]["parent"].id;
//           this.banner4_linkname= this.banners[i]["parent"].category_name;
//           }
        
//       }
//       if (this.banners[i]["category_name"] === "Banner5") {
//         this.banner5 = this.banners[i]["category_image"];
//         if(this.banners[i]["parent"]!=null)
//         {
//         this.banner5_linkid = this.banners[i]["parent"].id;
//         this.banner5_linkname= this.banners[i]["parent"].category_name;
//         }
      
//     }
//     if (this.banners[i]["category_name"] === "Banner6") {
//       this.banner6 = this.banners[i]["category_image"];
//       if(this.banners[i]["parent"]!=null)
//       {
//       this.banner6_linkid = this.banners[i]["parent"].id;
//       this.banner6_linkname= this.banners[i]["parent"].category_name;
//       }
    
//   }
//   if (this.banners[i]["category_name"] === "Banner7") {
//     this.banner7 = this.banners[i]["category_image"];
//     if(this.banners[i]["parent"]!=null)
//     {
//     this.banner7_linkid = this.banners[i]["parent"].id;
//     this.banner7_linkname= this.banners[i]["parent"].category_name;
//     }
  
// }
// if (this.banners[i]["category_name"] === "Banner8") {
//   this.banner8 = this.banners[i]["category_image"];
//   if(this.banners[i]["parent"]!=null)
//   {
//   this.banner8_linkid = this.banners[i]["parent"].id;
//   this.banner8_linkname= this.banners[i]["parent"].category_name;
//   }

// }
// if (this.banners[i]["category_name"] === "Banner9") {
//   this.banner9 = this.banners[i]["category_image"];
//   if(this.banners[i]["parent"]!=null)
//   {
//   this.banner9_linkid = this.banners[i]["parent"].id;
//   this.banner9_linkname= this.banners[i]["parent"].category_name;
//   }

// }
      
//       }

    });
   

    

    this._category.getCategories().subscribe((data) => {

			let cat = JSON.parse(JSON.stringify(data));
			localStorage.setItem('category', JSON.stringify(cat));
		});
    this.category = JSON.parse(localStorage.getItem('category'))
  }

// ngAfterViewInit() {
  
// }


      addProduct(product) {
        if (localStorage.getItem("user_id") === null) {
          if(this._cart.addtoCart(product,0,0))
          {
            this.addBtn = "1";
        this.ngOnInit();
          }
          
      
      
        }
        else {
          
          this._cart.addtoCartLoggedIn(product).subscribe((data) => {
            this.addBtn = "1";
            if(localStorage.getItem('order_id')==null)
            {
              this.oder_details=JSON.stringify(data);
              localStorage.setItem('order_id', data[0].id);
            }
            var len=data[0].orderdetails.length;
            if (this._cart.addtoCart(product, data[0].orderdetails[len-1].id,data[0].id)) 
            {
        this.ngOnInit();
            }
          });
        }
      }
      getCount(pro)
            {
              if(localStorage.getItem("products"))
              {
              let sel = JSON.parse(localStorage.getItem("products")).filter(
                (products) => products.productId === pro.id
              );
              if(sel!=""&&sel!=null)
              {
                return sel[0].qty;
              }
              else
              {
                return 0;
              }
            }
            else
            {
              return 0;
            }
            }


            getWish(pro)
            {
              if(localStorage.getItem("wishlist_products"))
              {
              let sel = JSON.parse(localStorage.getItem("wishlist_products")).filter(
                (products) => products.productId === pro.id
              );
              if(sel!=""&&sel!=null)
              {
                return 1;
              }
              else
              {
                return 0;
              }
            }
            else
            {
              return 0;
            }
            }
            
            
      
      changeSize(val)
      {
        this.orgvr=val;
      }
      
      check_cartStatus(id)
      {
        if(this.cart_item_id.includes(id))
        {
          
          return true;
        }
        else
        {
          
          return false;
        }
      }
      addWishlist(product)
      {
        // alert("called me");
        if(this._cart.addtoWishlist(product,0,0)==0)
          {
            // alert('Item already in your wishlist');
            let msg="Item removed from your wishlist";
            document.getElementById("snackbar").innerHTML=msg;
            var x = document.getElementById("snackbar");
            x.className = "show";
           setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);

          }
        else 
        // (this._cart.addtoWishlist(product,0)==1)
          {
            let msg="Product Added to wishlist"
            this.addBtn = "1";
            document.getElementById("snackbar").innerHTML=msg;
            var x = document.getElementById("snackbar");
            x.className = "show";
           setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
          }
      }
   
}
