import { Route } from '@angular/compiler/src/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CartService } from '../services/cart.service';
import { CategoryService } from '../services/category.service';
import { ConfigService } from '../services/config.service';
import { ProductsService } from '../services/products.service';

@Component({
  selector: 'app-size-chart',
  templateUrl: './size-chart.component.html',
  styleUrls: ['./size-chart.component.css']
})
export class SizeChartComponent implements OnInit {

  constructor(private _config: ConfigService,
    private _router: Router,
    public _product:ProductsService,
   private route: ActivatedRoute,
   private router:Router,
   private _cart:CartService) { }
   img_link=this._config.config_url.slice(0, -1);
  ngOnInit() {
   
  }
 



  
}
