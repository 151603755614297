<div class="container-fluid">
    <div class="row">
        <div class="row">
            <div class="col-md-2 slider_ed1" *ngFor="let pro of product | filter:searchText">


                <div class="thumb-wrapper" *ngIf="pro.category_parent_id!=null">
                    <div class="img-box">
                        <img [src]="img_link+pro.product_image1 " [routerLink]="['/category',pro.category_parent_id,pro.category_parent ]" class="img-responsive img-fluid imgg" alt="">
                    </div>


                    <div class="thumb-content">
                        <span class="items-names" [routerLink]="['/category',pro.category_parent_id,pro.category_parent ]">{{ pro.product_name | slice:0:8}}
                        </span>
                        <div class="jsx-193278340">
                            <img class="wishlist" (click)="addWishlist(pro)" src="https://pngimage.net/wp-content/uploads/2018/06/wishlist-icon-png-3.png" />
                            <!-- <img src="https://k.nooncdn.com/s/app/2019/noon-bigalog/458fc09acbbb401ca3536a0aa1221bc4e73c7bff/static/images/noon-express-en.png" alt="noon-express" class="jsx-193278340 fbn"> -->
                        </div>
                        <p class="jsx-4251264678 price">
                            <span class="jsx-4251264678 sellingPrice">
                                <span>
                                    <span class="currency medium">₹</span>
                            <span class="value">{{ pro.sales_rate }}</span></span>
                            </span>
                            <span class="jsx-4251264678 preReductionPrice">
                                <span>
                                    <span class="currency null"> ₹</span>
                            <span class="value">{{ pro.price }}</span>
                            </span>

                            </span>
                            <span class="jsx-2467480612 discountTag"><span
                                class="jsx-2467480612 bg"></span><span class="jsx-2467480612 ">{{ pro.offer }}% Off</span></span>
                        </p>
                    </div>
                </div>
                <div class="thumb-wrapper" *ngIf="pro.category_parent_id==null&&pro.category!=null">
                    <div class="img-box">
                        <img [src]="img_link+pro.product_image1 " [routerLink]="[ '/category',pro.category,pro.category_name ] " class="img-responsive img-fluid imgg" alt="">
                    </div>


                    <div class="thumb-content">
                        <span class="items-names" [routerLink]="[ '/category',pro.category,pro.category_name ] ">{{ pro.product_name | slice:0:8}}
                        </span>
                        <div class="jsx-193278340">
                            <img class="wishlist" (click)="addWishlist(pro)" src="https://pngimage.net/wp-content/uploads/2018/06/wishlist-icon-png-3.png" />
                            <!-- <img src="https://k.nooncdn.com/s/app/2019/noon-bigalog/458fc09acbbb401ca3536a0aa1221bc4e73c7bff/static/images/noon-express-en.png" alt="noon-express" class="jsx-193278340 fbn"> -->
                        </div>
                        <p class="jsx-4251264678 price">
                            <span class="jsx-4251264678 sellingPrice">
                                <span>
                                    <span class="currency medium">₹</span>
                            <span class="value">{{ pro.sales_rate }}</span></span>
                            </span>
                            <span class="jsx-4251264678 preReductionPrice">
                                <span>
                                    <span class="currency null"> ₹</span>
                            <span class="value">{{ pro.price }}</span>
                            </span>

                            </span>
                            <span class="jsx-2467480612 discountTag"><span
                                class="jsx-2467480612 bg"></span><span class="jsx-2467480612 ">{{ pro.offer }}% Off</span></span>
                        </p>
                    </div>
                </div>
                <div class="thumb-wrapper" *ngIf="pro.category_parent_id==null&&pro.category==null">
                    <div class="img-box">
                        <img [src]="img_link+pro.product_image1 " [routerLink]="['/product-details',pro.id,pro.product_name ]" class="img-responsive img-fluid imgg" alt="">
                    </div>


                    <div class="thumb-content">
                        <span class="items-names" [routerLink]="['/product-details',pro.id,pro.product_name ]">{{ pro.product_name | slice:0:8}}
                        </span>
                        <div class="jsx-193278340">
                            <img class="wishlist" (click)="addWishlist(pro)" src="https://pngimage.net/wp-content/uploads/2018/06/wishlist-icon-png-3.png" />
                            <!-- <img src="https://k.nooncdn.com/s/app/2019/noon-bigalog/458fc09acbbb401ca3536a0aa1221bc4e73c7bff/static/images/noon-express-en.png" alt="noon-express" class="jsx-193278340 fbn"> -->
                        </div>
                        <p class="jsx-4251264678 price">
                            <span class="jsx-4251264678 sellingPrice">
                                <span>
                                    <span class="currency medium">₹</span>
                            <span class="value">{{ pro.sales_rate }}</span></span>
                            </span>
                            <span class="jsx-4251264678 preReductionPrice">
                                <span>
                                    <span class="currency null"> ₹</span>
                            <span class="value">{{ pro.price }}</span>
                            </span>

                            </span>
                            <span class="jsx-2467480612 discountTag"><span
                                class="jsx-2467480612 bg"></span><span class="jsx-2467480612 ">{{ pro.offer }}% Off</span></span>
                        </p>
                    </div>
                </div>








            </div>


        </div>
    </div>
    <div class="abc" id="snackbar"></div>
</div>