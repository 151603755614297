<div class="main_div">


    <div class="">

        <div class="cart_gallery">
            <div class="cart_close">
                <div class="cart_text">
                    <h3>Size Chart</h3>
                </div>
            </div>



            <table class="tab">
                <tr>
                    <th>Size</th>
                    <th>
                        Chest (in)
                    </th>
                    <th>Front Length (in)</th>
                    <th>Across (in)</th>
                </tr>
                <tr>
                    <td>
                        S
                    </td>
                    <td>
                        37.0
                    </td>
                    <td>
                        27.5
                    </td>
                    <td>16.8</td>
                </tr>
                <tr>
                    <td>
                        M
                    </td>
                    <td>
                        37.0
                    </td>
                    <td>
                        27.5
                    </td>
                    <td>16.8</td>
                </tr>
                <tr>
                    <td>
                        L
                    </td>
                    <td>
                        37.0
                    </td>
                    <td>
                        27.5
                    </td>
                    <td>16.8</td>
                </tr>
                <tr>
                    <td>
                        XL
                    </td>
                    <td>
                        37.0
                    </td>
                    <td>
                        27.5
                    </td>
                    <td>16.8</td>
                </tr>
                <tr>
                    <td>
                        XXL
                    </td>
                    <td>
                        37.0
                    </td>
                    <td>
                        27.5
                    </td>
                    <td>16.8</td>
                </tr>
            </table>


        </div>



    </div>


</div>