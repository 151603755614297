import { Injectable } from '@angular/core';
import{ HttpClient, HttpResponse ,HttpHeaders,HttpBackend } from '@angular/common/http';
import { HttpParams } from "@angular/common/http";
import { ConfigService } from './config.service';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private http: HttpClient;
  private _loginUrl="client_api/login";
  private _loginUrl_pass="client_api/reset-password";
  private _registration_check="client_api/check-user";
  private _registerUrl="client_api/customer_registeration";
  private _addressUrl="client_api/update/";
  private _cusUrl="client_api/customer/";
  constructor(handler: HttpBackend,private link:ConfigService) {
    this.http = new HttpClient(handler);
   }
  loginUser(user)
  {

    return this.http.post<any>(this.link.config_url + this._loginUrl,user);
   
  }
  loginUserPasswordSet(user)
  {
console.log(user+"this is user");
    return this.http.post<any>(this.link.config_url + this._loginUrl_pass,user);
   
  }
  userCheck(user)
  {

    return this.http.post<any>(this.link.config_url + this._registration_check,user);
   
  }
  addUser(user)
  {
    return this.http.post<any>(this.link.config_url+this._registerUrl,user)
  }
  
 

}
