import { PaymentService } from './../services/payment.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router,ActivatedRoute, ParamMap } from '@angular/router';
import { CategoryService } from '../services/category.service';
import { ProductsService } from '../services/products.service';
import { CartService } from '../services/cart.service';
import { ConfigService } from '../services/config.service';

@Component({
  selector: 'app-productdetails',
  templateUrl: './productdetails.component.html',
  styleUrls: ['./productdetails.component.css']
})
export class ProductdetailsComponent implements OnInit {
  @ViewChild('sizeError') sizeError: ElementRef;
  review;
  review_count=0;

  customOptionsSecond: any = {
    loop: true,
    margin: 10,
    autoplay:false,
    responsiveClass: true,

    navText: ['<i class="ion-ios-arrow-left"></i>', '<i class="ion-ios-arrow-right"></i>'],
    responsive: {
      0: {
       items: 1
     },
      480: {
       items: 2
     },
      940: {
       items: 3
     }
    },
   nav: true,

  }
  myarr=[0,0,0,0,0]
  charge=0;
  uprate=[];
  downrate=[];
  product_count=0;
 orgvr;
  countsub=0;
  oder_details="";
  productbefore=[];
  products:any;
  cat_products:any;
  product_single:any;
  private sub:any;
   p_id;
   name;
   category:any;
   pincode;
   pincode_forcheck;
   img1;
   nosize=false;
   added;
   addedno;
     addBtn;
     addedBtn;
     cart:any;
     cart_item_id=[];
     im1=false;
     pcount=[];

     im2=false;
     im3=false;
     login_status = false;
     updatedqty=1;
     slideOptions = {
      loop: true,
    };
    prodLoadflag=false;
    prodCount=0;
    prodLoadCount=0;
sel;
size_chart;
size_chartf;
local_pincode="";
   constructor(private _product:ProductsService,private myElement: ElementRef,private route: ActivatedRoute,private router:Router,private _category:CategoryService,private _cart:CartService,private _config:ConfigService) { }
   img_link=this._config.config_url.slice(0, -1);
  ngOnInit(): void {

    this._config.chack_netStatus();

    if (localStorage.getItem("user_id") === null) {
      this.login_status = false;
    } else {
      this.login_status = true;
    }
    if(localStorage.getItem('location')!==null)
    {
     this.pincode=localStorage.getItem('location');
    }

    this.sub = this.route.params.subscribe(params => {
      this.p_id = params['id'];
      this.name = params['name'];
       this._product.getSingleProducts(this.p_id).subscribe((data)=>{
        let product_s=JSON.parse(JSON.stringify(data));
        this.product_single= product_s[0];
        // this.product_single.get_avg_rating=3;
        if(this.product_single.get_avg_rating!=null)
        {
          this.uprate=[];
          this.downrate=[];
          for(let i=0;i<Math.round(this.product_single.get_avg_rating);i++)
          {
            this.uprate.push(0);
          }
          for(let i=0;i<(5-Math.round(this.product_single.get_avg_rating));i++)
          {
            this.downrate.push(0);
          }
        }
        this.sel=this.product_single.product_image1;
        this.img1=this.img_link+this.product_single.product_image1;

        if(this.product_single.footware_sizechart==true)
        {
          this._product.getSizeChart("footware_sizechart").subscribe((data)=>{
            this. size_chartf=JSON.parse(JSON.stringify(data));
            this.size_chartf=this.size_chartf.sizechart.Footwarechart
          })

        }
        if(this.product_single.dress_sizechart==true)
        {
          this._product.getSizeChart("dress_sizechart").subscribe((data)=>{
            this.size_chart=JSON.parse(JSON.stringify(data));
            this.size_chart=this.size_chart.sizechart.Dresschart;
          })
        }



    })

this._product.getSingleProductReview(this.p_id).subscribe((data)=>{

  this.review=JSON.parse(JSON.stringify(data));
  this.review_count=0;
  for(let i=0;i<this.review.length;i++)
  {
if(this.review[i].comment!=null&&this.review[i].comment!='')
{
  this.review_count++;
}
  }


});
    this._product.getAllProducts().subscribe((data)=>{

      this.products=JSON.parse(JSON.stringify(data));



for(var i=0;i<this.products.length;i++)
{

  this.prodCount=this.prodCount+1;
}
this.product_count=Math.round(this.prodCount/6);
for(let i=0;i<this.product_count;i++)
{
  this.pcount.push(i);
}
  });




   });


JSON.parse(localStorage.getItem('category'));

this.category=JSON.parse(localStorage.getItem('category'));

  this.cart_item_id=this._cart.getAddedItems();
  if(  this.cart_item_id == null){
    this.cart_item_id=[];
  }
  }
changeImage(img_src,id)
{
  this.img1=this.img_link+img_src;
  this.sel=img_src;
  if(id==1)
  {
    this.im1=true;
    this.im2=false;
    this.im3=false;

  }
  if(id==2)
  {
    this.im1=false;
    this.im2=true;
    this.im3=false;
  }
  if(id==3)
  {
    this.im1=false;
    this.im2=false;
    this.im3=true;
  }
}

checkStock(prod)
{
  if(prod.stock>0)
  {
    return true;
  }
  else
  {
    return false;
  }

}

addProduct(product) {
var size;
if(this.local_pincode!="")
{
  document.getElementById('err1').innerHTML="";
var size;
  if(product.variance!=null&&product.variance!= []&&product.variance.length>0)
{
  if(this.orgvr==""||this.orgvr==null||!this.orgvr)
  {
   this.nosize=true;
   this.myElement.nativeElement.querySelector('#errss').innerHTML="Select Your Size";
   let el = this.myElement.nativeElement.querySelector('#errss');
  //el.scrollIntoView();
  }
  else
  {
    size=this.orgvr;
    this.nosize=false;
    product.varianceOrg=size;
    this.myElement.nativeElement.querySelector('#errss').innerHTML="";
  if (localStorage.getItem("user_id") === null) {
    if(this._cart.addtoCart(product,0,0))
    {
      this.addBtn = "1";
  this.ngOnInit();
    }



  }
  else {

    this._cart.addtoCartLoggedIn(product).subscribe((data) => {
      this.addBtn = "1";
      if(localStorage.getItem('order_id')==null)
      {
        this.oder_details=JSON.stringify(data);
        localStorage.setItem('order_id', data[0].id);
      }
      var len=data[0].orderdetails.length;
      if (this._cart.addtoCart(product, data[0].orderdetails[len-1].id,data[0].id))
      {
  this.ngOnInit();
      }
    });
  }

  }

}
else
{


  if (localStorage.getItem("user_id") === null) {
    if(this._cart.addtoCart(product,0,0))
    {
      this.addBtn = "1";
  this.ngOnInit();
    }



  }
  else {

    this._cart.addtoCartLoggedIn(product).subscribe((data) => {
      this.addBtn = "1";
      if(localStorage.getItem('order_id')==null)
      {
        this.oder_details=JSON.stringify(data);
        localStorage.setItem('order_id', data[0].id);
      }
      var len=data[0].orderdetails.length;
      if (this._cart.addtoCart(product, data[0].orderdetails[len-1].id,data[0].id))
      {
  this.ngOnInit();
      }
    });
  }
}
}
else
{
  document.getElementById('err1').innerHTML="Enter pincode to check availability";
  document.getElementById('availcheck').focus();

}
}

addProductBuy(product) {
  //size selection code
  if(this.local_pincode!="")
{
  if(localStorage.getItem('user_id'))
  {


  var size;
    if(product.variance!=null&&product.variance!= []&&product.variance.length>0)
  {
    if(this.orgvr==""||this.orgvr==null||!this.orgvr)
    {
     this.nosize=true;
     this.myElement.nativeElement.querySelector('#errss').innerHTML="Select Your Size";
     let el = this.myElement.nativeElement.querySelector('#errss');
    //el.scrollIntoView();
    }
    else
    {
      size=this.orgvr;
      this.nosize=false;
      product.varianceOrg=size;
      this.myElement.nativeElement.querySelector('#errss').innerHTML="";
    if (localStorage.getItem("user_id") === null) {
      if(this._cart.addtoCart(product,0,0))
      {
        this.addBtn = "1";
    this.ngOnInit();
      }



    }
    else {

      this._cart.addtoCartLoggedIn(product).subscribe((data) => {
        this.addBtn = "1";
        if(localStorage.getItem('order_id')==null)
        {
          this.oder_details=JSON.stringify(data);
          localStorage.setItem('order_id', data[0].id);
        }
        var len=data[0].orderdetails.length;
        if (this._cart.addtoCart(product, data[0].orderdetails[len-1].id,data[0].id))
        {
    this.ngOnInit();
        }
      });
    }

    }

  }
  else
  {


    if (localStorage.getItem("user_id") === null) {
      if(this._cart.addtoCart(product,0,0))
      {
        this.addBtn = "1";
    this.ngOnInit();
      }



    }
    else {

      this._cart.addtoCartLoggedIn(product).subscribe((data) => {
        this.addBtn = "1";
        if(localStorage.getItem('order_id')==null)
        {
          this.oder_details=JSON.stringify(data);
          localStorage.setItem('order_id', data[0].id);
        }
        var len=data[0].orderdetails.length;
        if (this._cart.addtoCart(product, data[0].orderdetails[len-1].id,data[0].id))
        {
    this.ngOnInit();
        }
        this.router.navigate(['/cart']);
      });
    }
  }
}
else
{
  alert("Please Login to Continue");
  this.router.navigate(['/login']);
}
}
else
{
  document.getElementById('err1').innerHTML="Enter pincode to check availability";
  document.getElementById('availcheck').focus();

}
  }

check_availability(pin_array,pincode_forcheck) {
  document.getElementById('err1').innerHTML="";
  var arr = [];
  for (var i = 0; i < pin_array.length; i++) {
    arr.push(pin_array[i].pincode);
  }

  if ((arr.indexOf(pincode_forcheck) > -1)) {
    console.log('====================================');
    console.log(pincode_forcheck,">>>>>>>>");
    console.log('====================================');
    PaymentService.pincode = pincode_forcheck;
    console.log('====================================');
    console.log(pincode_forcheck,"??????????");
    console.log('====================================');
    document.getElementById('err').innerHTML='<span class="errspan" style=" color: green;font-size: 12px;"><i class="fa fa-check"></i> Available';
    this.local_pincode=pincode_forcheck;
    return true;

  }
  else {
    document.getElementById('err').innerHTML='<span class="errspan" style=" color: tomato;font-size: 12px;"><i class="fa fa-times" > </i> This product is not availble in your location';
    this.local_pincode="";
    return false;
  }
}

find_charge(pin_array)
{
  var arr = [];
  for (var i = 0; i < pin_array.length; i++) {
    arr.push(pin_array[i].pincode);
  }
  // if ((arr.indexOf(this.pincode) > -1)) {
  //   return this.charge=0;
  // }
  // else {
    let lrg=0;
    for (var i = 0; i < pin_array.length; i++) {
      if(lrg<pin_array[i].delivery_charge)
      {
        lrg=pin_array[i].delivery_charge;
      }
    }
    return this.charge=lrg



  // }
}




addQty(product)
  {

    if (localStorage.getItem("user_id") === null) {
     this.updatedqty= this._cart.addtoCartQty(product);
      // alert("item added to cart" + qty);
    }
    else
    {
      this.updatedqty= this._cart.addtoCartQty(product);
      this._cart.addtoCartLoggedInQty(product).subscribe((data)=>{

        // this.category=JSON.parse(JSON.stringify(data));
    });
    }



}

removeQty(product)
  {
    if(this.getCount(product)>1)
    {

    if (localStorage.getItem("user_id") === null) {
      this.updatedqty=this._cart.removetoCartQty(product);

    }
    else
    {
      this.updatedqty= this._cart.removetoCartQty(product);
      this._cart.addtoCartLoggedIn(product).subscribe((data)=>{

        // this.category=JSON.parse(JSON.stringify(data));
    });
    }

  }
  else
  {
    let storageProducts = JSON.parse(localStorage.getItem('products'));
    if(storageProducts.filter(product1 => product1.productId == product.id))
    {
    let removeproget = storageProducts.filter(product1 => product1.productId == product.id);
		let myId=removeproget[0].order_detail_id;
    this._cart.removeCartLogin(myId).subscribe((data) => { });
    this.removeProduct(product);
    }
  }

}
removeProduct(product) {
  // debugger;
  if (localStorage.getItem('user_id')) {
    let storageProducts = JSON.parse(localStorage.getItem('products'));
    let removeproget = storageProducts.filter(product1 => product1.productId == product.id);
    let myId=removeproget[0].order_detail_id;
    // for (let i = 0; i < JSON.parse(localStorage.getItem('orderDetailsId')).length; i++) {
    // 	if (JSON.parse(localStorage.getItem('products')).productId == JSON.parse(localStorage.getItem('orderDetailsId'))[i].orderPid) {
    // 		myId = JSON.parse(localStorage.getItem('orderDetailsId'))[i].orderDetailsId;
    // 	}
    // }

    this._cart.removeCartLogin(myId).subscribe((data) => { });

  }
  this._cart.removeCartFromHome(product).subscribe((data) => {
    // let currentUrl = this.router.url;
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    // this.router.onSameUrlNavigation = 'reload';
    // this.router.navigate([currentUrl]);
    this.ngOnInit();
    let d = data;
    // debugger;
    // this.cart=JSON.parse(JSON.stringify(data));
    // this.count=JSON.parse(localStorage.products).length;


  });
  this._cart.getCartCountSub().subscribe((data) => {

    this.countsub = JSON.parse(JSON.stringify(data));
  });
  // this.router.navigate(["product-details/"+this.p_id+"/"+this.name]).then(() => {
  //   window.location.reload();
  // });
  // this.router.navigate(["product-details/"+this.p_id+"/"+this.name]);
  this.ngOnInit();

}
prodLoaded() {
  this.prodLoadflag=true;
}

getCount(pro)
      {
        if(localStorage.getItem("products"))
        {
        let sel = JSON.parse(localStorage.getItem("products")).filter(
          (products) => products.productId === pro.id
        );
        if(sel!=""&&sel!=null)
        {
          return sel[0].qty;
        }
        else
        {
          return 0;
        }
      }
      else
      {
        return 0;
      }
      }



changeSize(val)
{
  this.nosize=false;
  this.orgvr=val;

}

check_cartStatus(id)
{
  if(this.cart_item_id.includes(id))
  {

    return true;
  }
  else
  {

    return false;
  }
}
addWishlist(product)
{
  // alert("called me");
  if(this._cart.addtoWishlist(product,0,0)==0)
    {
      // alert('Item already in your wishlist');
      let msg="Item removed from your wishlist";
      document.getElementById("snackbar").innerHTML=msg;
            var x = document.getElementById("snackbar");
            x.className = "show";
           setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
        }
  else
  // (this._cart.addtoWishlist(product,0)==1)
    {
      let msg="Product Added to wishlist"
      this.addBtn = "1";
      document.getElementById("snackbar").innerHTML=msg;
      var x = document.getElementById("snackbar");
      x.className = "show";
     setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
    }
}


getWish(pro)
{
  if(localStorage.getItem("wishlist_products"))
  {
  let sel = JSON.parse(localStorage.getItem("wishlist_products")).filter(
    (products) => products.productId === pro.id
  );
  if(sel!=""&&sel!=null)
  {
    return 1;
  }
  else
  {
    return 0;
  }
}
else
{
  return 0;
}
}


}
