<!-- Custom content-->
<div class="order_bar_container" *ngIf="order!=undefined" >
    <div class="media align-items-lg-center flex-column flex-lg-row p-3">
        <div class="inmedia" *ngFor="let pro of product; let i = index">
            <div class="media-body order-2 order-lg-1">
                <h5 class="mt-0 font-weight-bold mb-2">{{ pro.product_name }} x {{ orders_qty[i] }}
                </h5>
                <div>
                    <p *ngIf="order[0].orderdetails[0].preference">
                        {{ order[0].orderdetails[0].preference }} {{ order[0].orderdetails[0].variance }}
                    </p>
                </div>
                <p class="font-italic text-muted mb-0 small">{{ pro.product_description }}</p>
                <div class="d-flex align-items-center justify-content-between mt-1">
                    <h6 class="font-weight-bold my-2">₹{{ pro.sales_rate }}</h6>
                    <!-- <ul class="list-inline small">
                        <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                        <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                        <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                        <li class="list-inline-item m-0"><i class="fa fa-star text-success"></i></li>
                        <li class="list-inline-item m-0"><i class="fa fa-star-o text-gray"></i></li>
                    </ul> -->
                    <!-- <p>{{ getRating(pro.id) }}</p> -->
                    <!-- <div class="star-rating">
                        <input type="radio" id="5-stars" name="rating" value="5" (click)="selectRating(5,pro.id)" />
                        <label for="5-stars" class="star">&#9733;</label>
                        <input type="radio" id="4-stars" name="rating" value="4" (click)="selectRating(4,pro.id)" />
                        <label for="4-stars" class="star">&#9733;</label>
                        <input type="radio" id="3-stars" name="rating" value="3" (click)="selectRating(3,pro.id)" />
                        <label for="3-stars" class="star">&#9733;</label>
                        <input type="radio" id="2-stars" name="rating" value="2" (click)="selectRating(2,pro.id)" />
                        <label for="2-stars" class="star">&#9733;</label>
                        <input type="radio" id="1-star" name="rating" value="1" (click)="selectRating(1,pro.id)" />
                        <label for="1-star" class="star">&#9733;</label>
                    </div> -->
                    <h6>Submit Your Review and Rating</h6>
                    <div class="star-rating" *ngIf="!order[0].orderdetails[0].rating[0]">
                        <input type="radio" value="5" (click)="selectRating(5,order[0].orderdetails[0].product_id)" />
                        <label for="5-stars" (click)="selectRating(5,order[0].orderdetails[0].product_id)" class="star">&#9733;</label>

                        <input type="radio" value="4" (click)="selectRating(4,order[0].orderdetails[0].product_id)" />
                        <label for="4-stars" class="star" (click)="selectRating(4,order[0].orderdetails[0].product_id)">&#9733;</label>

                        <input type="radio" value="3" (click)="selectRating(3,order[0].orderdetails[0].product_id)" />
                        <label for="3-stars" class="star" (click)="selectRating(3,order[0].orderdetails[0].product_id)">&#9733;</label>

                        <input type="radio" value="2" (click)="selectRating(2,order[0].orderdetails[0].product_id)" />
                        <label for="2-stars" class="star" (click)="selectRating(2,order[0].orderdetails[0].product_id)">&#9733;</label>

                        <input type="radio" value="1" (click)="selectRating(1,order[0].orderdetails[0].product_id)" />
                        <label for="1-star" class="star" (click)="selectRating(1,order[0].orderdetails[0].product_id)">&#9733;</label>

                    </div>

                    <div *ngIf="order[0].orderdetails[0].rating[0]">
                        <div class="star-rating" *ngIf="order[0].orderdetails[0].rating[0].rating">
                            <input type="radio" value="5" (click)="selectRating(5,order[0].orderdetails[0].product_id)" />
                            <label for="5-stars" class="star" (click)="selectRating(5,order[0].orderdetails[0].product_id)" *ngIf="order[0].orderdetails[0].rating[0].rating > 4" style="color:#fc0 !important">&#9733;</label>
                            <label for="5-stars" class="star" (click)="selectRating(5,order[0].orderdetails[0].product_id)" *ngIf="order[0].orderdetails[0].rating[0].rating < 5" style="color:#ccc !important">&#9733;</label>
                            <input type="radio" value="4" (click)="selectRating(4,order[0].orderdetails[0].product_id)" />
                            <label for="4-stars" class="star" (click)="selectRating(4,order[0].orderdetails[0].product_id)" *ngIf="order[0].orderdetails[0].rating[0].rating > 3" style="color:#fc0 !important">&#9733;</label>
                            <label for="4-stars" class="star" (click)="selectRating(4,order[0].orderdetails[0].product_id)" *ngIf="order[0].orderdetails[0].rating[0].rating < 4" style="color:#ccc !important">&#9733;</label>
                            <input type="radio" value="3" (click)="selectRating(3,order[0].orderdetails[0].product_id)" />
                            <label for="3-stars" class="star" (click)="selectRating(3,order[0].orderdetails[0].product_id)" *ngIf="order[0].orderdetails[0].rating[0].rating > 2" style="color:#fc0 !important">&#9733;</label>
                            <label for="3-stars" class="star" (click)="selectRating(3,order[0].orderdetails[0].product_id)" *ngIf="order[0].orderdetails[0].rating[0].rating < 3" style="color:#ccc !important">&#9733;</label>
                            <input type="radio" value="2" (click)="selectRating(2,order[0].orderdetails[0].product_id)" />
                            <label for="2-stars" class="star" (click)="selectRating(2,order[0].orderdetails[0].product_id)" *ngIf="order[0].orderdetails[0].rating[0].rating > 1" style="color:#fc0 !important">&#9733;</label>
                            <label for="2-stars" class="star" (click)="selectRating(2,order[0].orderdetails[0].product_id)" *ngIf="order[0].orderdetails[0].rating[0].rating < 2" style="color:#ccc !important">&#9733;</label>
                            <input type="radio" value="1" (click)="selectRating(1,order[0].orderdetails[0].product_id)" />
                            <label for="1-star" class="star" (click)="selectRating(1,order[0].orderdetails[0].product_id)" *ngIf="order[0].orderdetails[0].rating[0].rating > 0" style="color:#fc0 !important">&#9733;</label>
                            <label for="1-star" class="star" (click)="selectRating(1,order[0].orderdetails[0].product_id)" *ngIf="order[0].orderdetails[0].rating[0].rating < 1" style="color:#ccc !important">&#9733;</label>
                        </div>
                    </div>


                    <img class="imgorder" [src]="img_link+pro.product_image1" alt="Generic placeholder image" width="10%" class="ml-lg-5 order-1 order-lg-2">
                </div>
                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">

                        <div class="review">
                            <textarea class="txtara form-control" #reviewText></textarea>
                        </div>
                        <div class="jsx-278401895 jsx-3349621030 cartCtaWrapper"><button (click)="addReview(reviewText)" aria-label="Checkout Now" class="jsx-278401895 jsx-3349621030 ripple">Submit</button></div>
                    </div>
                    <div class="col-md-4">

                    </div>
                </div>

                <!-- <button _ngcontent-yur-c24="" data-qa="btn_disabled_Save" width="190px" class="dyoqji-0 jzxnON"><span
                                    _ngcontent-yur-c24="" data-qa="btn_Save"
                                    class="dyoqji-1 jAsdLe">Buy it again</span></button> -->


                <!-- <button _ngcontent-yur-c24="" data-qa="btn_disabled_Save" width="190px" class="dyoqji-0 jzxnON"><span
                                        _ngcontent-yur-c24="" data-qa="btn_Save"
                                        class="dyoqji-1 jAsdLe">Add Review</span></button> -->
            </div>
            <!-- popup -->

        </div>
    </div>
    <div class="track" >
        <a [href]="order[0].courier_website_url">
            <p><b>Courier Name:</b><span *ngIf="order[0].courier_name">{{ order[0].courier_name }}</span><span *ngIf="!order[0].courier_name">Not Available</span></p>
        </a>

        <p><b>Tracking ID:</b><span *ngIf="order[0].delivery_tracking_id">{{ order[0].delivery_tracking_id }}</span><span *ngIf="!order[0].delivery_tracking_id">Not Available</span></p>

    </div>
    <!-- <div class="jsx-278401895 jsx-3349621030 cartCtaWrapper"><button (click)="addReview(reviewText)" aria-label="Checkout Now" class="jsx-278401895 jsx-3349621030 ripple">Submit</button></div> -->
    <button class="btn btn-dark drk" *ngIf="order[0].status=='Delivered'" (click)="changeStatus(order[0].id,'return')"> Return / Exchange </button>
    <button class="btn btn-dark drk" *ngIf="order[0].status=='Ordered'" (click)="changeStatus(order[0].id,'cancelled')"> Cancel </button>
    <button class="btn btn-dark drk" *ngIf="order[0].status=='Accepted'" (click)="changeStatus(order[0].id,'cancelled')"> Cancel </button>
    <button class="btn btn-danger dan" *ngIf="order[0].status=='return'" disabled> Return requested </button>
    <button class="btn btn-danger dan" *ngIf="order[0].status=='cancelled'" disabled> Cancellation requested </button>


    <!-- <button class="btn btn-primary" (click)="changeStatus(order[0].id,'cancelled')" *ngIf="order[0].status=='Ordered'">Cancel Order</button>
    <button class="btn btn-primary" (click)="changeStatus(order[0].id,'return')" *ngIf="order[0].status=='Delivered'">Return</button>
    <button class="btn btn-default btncancel" *ngIf="order[0].status=='cancelled'">Cancellation Requested</button> -->

</div>
<button id="openModalButton" [hidden]="true" data-toggle="modal" data-target="#myModal">Open Modal</button>
<div id="myModal" class="modal fade" role="dialog">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content">
            <!-- <div class="modal-header">


            </div> -->
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <div class="main_div">


                    <div class="">

                        <div class="cart_gallery">
                            <div class="cart_close">

                                <div class="cart_text">
                                    <hr>
                                    <h3> Thank You :)</h3>
                                    <h5>Review Submitted</h5>
                                    <hr>
                                </div>
                            </div>






                        </div>



                    </div>


                </div>
                <button type="button" class="btn btn-default alrt" data-dismiss="modal">OK</button>
            </div>
            <!-- <div class="modal-footer">

            </div> -->
        </div>

    </div>
</div>
<!-- End -->
