import { Component, OnInit } from '@angular/core';
import { FormBuilder,Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {
  registeredUser={username:'',password:'',phone:'',email:''};
  constructor(private _auth:AuthService,private _router:Router,private fb:FormBuilder) { }

  registerForm=this.fb.group(
    {
      username:['',[Validators.required]],
      password:['',[Validators.required]],
      phone:['',[Validators.required]],
      email:['',[Validators.required]]
    
    })
  ngOnInit(): void {
  }
  registerUser(){
    console.log("login details");
    console.log(this.registeredUser);
    this._auth.addUser(this.registeredUser)
  .subscribe(
    res =>
    {
      console.log(res.token);
      // localStorage.setItem('token', res.token)
      this._router.navigate(['/success'])
    },
    err=>console.log(err)
  )
  }

}
