import { Route } from '@angular/compiler/src/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CartService } from '../services/cart.service';
import { CategoryService } from '../services/category.service';
import { ConfigService } from '../services/config.service';
import { ProductsService } from '../services/products.service';
@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.css']
})
export class WishlistComponent implements OnInit {
  wishlist: any;
  addBtn;
  oder_details="";
  wishpro;
  product;
  constructor(private _config: ConfigService,
    private _router: Router,
    public _product:ProductsService,
   private route: ActivatedRoute,
   private router:Router,
   private _cart:CartService) { }
   img_link=this._config.config_url.slice(0, -1);
  ngOnInit() {
    this._product.getAllProducts().subscribe((data) => {
      this.product = JSON.parse(JSON.stringify(data));
    });
     //wishlist code
     this._cart.viewWishlistLogin(localStorage.getItem('user_id')).subscribe((data)=>{
      var productdb =  JSON.parse(JSON.stringify(data));
      console.log(productdb)
      console.log(this.product);
      for(let i=0;i<productdb.length;i++)
      {
        for(let j=0;j<this.product.length;j++)
        {
          if(productdb[i].product==this.product[j].id)
          {

            this.wishpro.push({
              productId: productdb[i].product,
              product_name: this.product[i].product_name,
              price: this.product[i].sales_rate,
              product_image1: this.product[i].product_image1
    
            });
            
          }
        }
        

      }
      localStorage.setItem(
        "wishlist_products",
        JSON.stringify(this.wishpro)
      );

    })
    //wishlist code ends
    this.wishlist=JSON.parse(localStorage.getItem('wishlist_products'));
    console.log(JSON.stringify(this.wishlist)+"this is wishlist");



  }
  addProduct(product) {
    if (localStorage.getItem("user_id") === null) {
      if(this._cart.addWishlisttoCart(product,0,0))
      {
        this.addBtn = "1";
        if(this._cart.removeWishlist(product)){
          let currentUrl = this.router.url;
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate([currentUrl]);
      }
       
      }
      
  
  
    }
    else {
      
      this._cart.addtoCartLoggedInFromWish(product).subscribe((data) => {
        this.addBtn = "1";
        if(localStorage.getItem('order_id')==null)
        {
          this.oder_details=JSON.stringify(data);
          localStorage.setItem('order_id', data[0].id);
        }
        if(this._cart.addWishlisttoCart(product,data[0].orderdetails[0].id,data[0].id))
        {
         if( this._cart.removeWishlist(product)) {
            let currentUrl = this.router.url;
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.onSameUrlNavigation = 'reload';
            this.router.navigate([currentUrl]);
        }
          
        }
      });
    }
  
  }
  removeWishlistProduct(product)
  {
    if(this._cart.removeWishlist(product)) {
			let currentUrl = this.router.url;
			this.router.routeReuseStrategy.shouldReuseRoute = () => false;
			this.router.onSameUrlNavigation = 'reload';
			this.router.navigate([currentUrl]);
  }
}
  
}
