<section class="log"></section>
<div class="container">
    <div class="login-form mx-auto d-block w-100">
        <div class="page-header text-center">
            <h1 class="logh1"><strong>Registered Successfully

			</strong></h1>
        </div>
        <div class="text-center mb-30">
            Please <a routerLink="/login"> Sign In</a> to Continue
        </div>


    </div>
</div>