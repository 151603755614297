<div class="container-fluid">
    <div class="row">
        <aside class="col-lg-12">
            <div class="heading">
                <h4>About Us</h4>
            </div>
            <div class="card">

                <p>Flublet was established in 2021 with a vision to get the fashion & Lifestyle at the doors of every person.</p>
                <p>Fashion accessories is our prime category We are happy to build a family of loyal customers from India. We do have an option for footwear and fashion accessories as well to meet everyone’s need.We always look forward for the customer satisfaction</p>



            </div>
        </aside>

    </div>
</div>