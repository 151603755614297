<div class="container-fluid">
    <div class="row">
        <aside class="col-lg-12">
            <div class="heading">
                <h4>Terms of use</h4>
            </div>
            <div class="card">
                <p>This document is an electronic record in terms of Information Technology Act, 2000 and rules there under as applicable and the amended provisionspertaining to electronic records in various statutes as amended by the Information Technology
                    Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.</p>
                <p>This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and Terms of Use for access
                    or usage of our website and Android App based platform Flublet.</p>
                <p>This Website is Owned by <b>Mr NIZAMUDEEN.K, Kattoor House, Beach Road Puthiyangadi, PO Madayi Kannur, Kerala PIN 670304</b>, he is Sole Owner of this Business.</p>
                <p>When you are using this website ,you shall be contacting with the Owner of flublet Wherever the context so requires "You" or "User" shall mean any natural or legal person who has agreed to become a buyer on the flublet Platform by providing
                    Registration Data while registering on the flublet website or Application on Android phone.</p>
                <ul>
                    <li>
                        <h6>USER ELIGIBILITY</h6>
                        <p>Use of the Website is available only to persons who can form legally binding contracts under Indian Contract Act, 1872. Persons who are “incompetent to contract” within the meaning of the Indian Contract Act, 1872 including minors,
                            un-discharged insolvents etc. are not eligible to use the Website.</p>
                    </li>
                    <li>
                        <h6>User Account, Password, and Security:</h6>
                        <p>If You use the flublet, You shall be responsible for maintaining the confidentiality of your Display Name and Password and You shall be responsible for all activities that occur under your Display Name and Password. You agree that
                            if You provide any information that is untrue, inaccurate, not current or incomplete, We shall have the right to indefinitely suspend or terminate or block access of your membership on the flublet. ensure that you exit from
                            your account at the end of each session.</p>
                    </li>
                    <li>
                        <h6>Contents Posted on the platform:</h6>
                        <p>All text, graphics, user interfaces, visual interfaces, photographs, trademarks, logos, sounds, music and artwork (collectively, "Content"), is a third party user generated content and the platform has no control over such third
                            party user generated content as we are a marketplace intermediary for the purposes of this Terms of Use.</p>
                    </li>

                    <li>
                        <h6>Cookie Policy.</h6>
                        <p>A cookie is a small text file that we place on your mobile device to enable various features of Platform. "Cookies" are used to store user preferences and to track user trends, so as to enhance your interactive experience and generally
                            improve our Services to you. You can change your cookie settings to accept or not accept cookies in your browser settings. If you do accept a "cookie", you thereby agree to our use of any Personal Information collected by us
                            using that Cookie. If you do accept a "cookie", you thereby agree to our use of any Personal Information collected by us using that Cookie.</p>
                    </li>
                    <li>

                        <p>All material on RapidBox, including images, illustrations, audio clips, and video clips, are protected by copyrights, trademarks, and other intellectual property rights.</p>
                    </li>
                    <li>
                        <p>Visitors may not use the Web Site in order to transmit, distribute, store or destroy material (a) that could constitute or encourage conduct that would be considered a criminal offence or violate any applicable law or regulation,
                            (b) in a manner that will infringe the copyright, trademark, trade secret or other intellectual property rights of others or violate the privacy or publicity of other personal rights of others, or (c) that is libellous, defamatory,
                            pornographic, profane, obscene, threatening, abusive or hateful.</p>
                    </li>
                    <li>
                        <p>
                            Visitors are prohibited from violating or attempting to violate the security of the Web site, including, without limitation, (1) accessing data not intended for such user or logging into a server or account which the user is not authorized to access,
                            (2) attempting to probe, scan or test the vulnerability of a system or network or to breach security or authentication measures without proper authorization, (3) attempting to interfere with service to any user, host or network,
                            including, without limitation, via means of submitting a virus or "Trojan horse" to the Website, overloading, "flooding", "mail bombing" or "crashing", or (4) sending unsolicited electronic mail, including promotions and/or
                            advertising of products or services. Violations of system or network security may result in civil or criminal liability. The business and / or its associate entities will have the right to investigate occurrences that they
                            suspect as involving such violations and will have the right to involve, and cooperate with, law enforcement authorities in prosecuting users who are involved in such violations
                        </p>
                    </li>

                </ul>
                <p>platform, You shall be contracting with flublet and these terms and conditions including the policies constitute Your binding obligations, with flublet.For the purpose of these Terms of Use.</p>





            </div>
        </aside>

    </div>
</div>