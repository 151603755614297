<!--footer starts from here-->
<footer class="footer">

    <div class="container bottom_border">
        <div class="row">

            <div class=" col-sm-4 col-md col-sm-4  col-12 col">
                <h5 class="headin5_amrc col_white_amrc pt2">Find us</h5>
                <!--headin5_amrc-->
                <!-- <i class="fa fa-instagram"></i> -->
                <p><i class="fa fa-location-arrow"></i> Madayi PO</p>
                <p>Kannur, Kerala </p>
                <p>PIN 670304</p>
                <!-- <p>Flublet was established in 2021 with a vision to get the fashion & Lifestyle at the doors of every person.</p>
                <p>Fashion accessories is our prime category We are happy to build a family of loyal customers from India. We do have an option for footwear and fashion accessories as well to meet everyone’s need.We always look forward for the customer satisfaction</p> -->



            </div>


            <div class=" col-sm-4 col-md  col-6 col">
                <!-- <h5 class="headin5_amrc col_white_amrc pt2">ONLINE SHOPPING</h5>
               
                <ul class="footer_ul_amrc">
                    <li *ngFor="let cat of category| slice:0:6; let i=index"><a [routerLink]="['/category',cat.id,cat.category_name]">{{ cat.category_name }}</a></li>

                </ul> -->
                <!-- <p><i class="fa fa-location-arrow"></i> 9878/25 sec 9 rohini 35 </p> -->
                <h5 class="headin5_amrc col_white_amrc pt2">Contact Us</h5>
                <p><i class="fa fa-phone"></i> +91-9562008131 </p>
                <p><i class="fa fa fa-envelope"></i> flubletbusiness@gmail.com </p>

            </div>


            <div class=" col-sm-4 col-md  col-6 col">
                <h5 class="headin5_amrc col_white_amrc pt2">Useful Links</h5>
                <!--headin5_amrc-->
                <ul class="footer_ul_amrc">

                    <li><a routerLink="/about">About Us </a></li>
                    <li><a routerLink="/shipping_policy">Shipping Policy</a></li>
                    <li><a routerLink="/return">Return & Exchange Policy</a></li>
                    <li><a routerLink="/terms_of_use">Terms of use</a></li>
                </ul>
                <!--footer_ul_amrc ends here-->
            </div>


            <div class=" col-sm-4 col-md  col-12 col">
                <h5 class="headin5_amrc col_white_amrc pt2">Follow us</h5>
                <!--headin5_amrc ends here-->

                <ul class="footer_ul2_amrc">
                    <li style="cursor: pointer;"><a href="#"><i class="fa fa-twitter fleft padding-right" style="color:#1DA1F2;"></i> 
                        <p>Twitter</p></a>
                    </li>
                    <li style="cursor: pointer;"><a href="https://facebook.com/flublet"><i class="fa fa-facebook fleft padding-right" style="color:#3b5998;"></i> 
                        <p>Facebook</p></a>
                    </li>
                    <li style="cursor: pointer;"><a href="https://instagram.com/flubletindia?utm_medium=copy_link"><i class="fa fa-instagram fleft padding-right" style="color:#e95950;"></i> 
                        <p>Instagram</p></a>
                    </li>
                </ul>
                <!--footer_ul2_amrc ends here-->
            </div>
        </div>
    </div>


    <div class="container">
        <ul class="foote_bottom_ul_amrc">
            <li><a href="/home">Home</a></li>
            <li><a routerLink="/about">About</a></li>
            <li><a routerLink="/shipping_policy">Shipping Policy</a></li>
            <li><a routerLink="/return">Return & Exchange Policy</a></li>
            <li><a routerLink="/terms_of_use">Terms of use</a></li>
            <!-- <li><a href="#">Services</a></li> -->

        </ul>
        <!--foote_bottom_ul_amrc ends here-->
        <p class="text-center">Copyright @2020 | Designed With by <a href="#">Cydez</a></p>

        <ul class="social_footer_ul">
            <li><a href="https://facebook.com/flublet"><i class="fa fa-facebook-f"></i></a></li>
            <li><a href="#"><i class="fa fa-twitter"></i></a></li>
            <li><a href="#"><i class="fa fa-linkedin"></i></a></li>
            <li><a href="https://instagram.com/flubletindia?utm_medium=copy_link"><i class="fa fa-instagram"></i></a></li>
        </ul>
        <!--social_footer_ul ends here-->
    </div>

</footer>