import { Injectable } from '@angular/core';
import{ HttpClient, HttpResponse ,HttpHeaders,HttpBackend } from '@angular/common/http';
import { HttpParams } from "@angular/common/http";
import { ConfigService } from './config.service';
import { shareReplay, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
const CACHE_SIZE = 1;
@Injectable({
  providedIn: 'root'
})
export class OrderService {
  public responseCache = new Map();
  private cache$: Observable<any>;
  private cacheSingleProduct$: Observable<any>;
  private _cusUrl="client_api/customer/";
  private _review="client_api/rating";
  private _userreview="client_api/product_rate_user?";
  constructor(private http: HttpClient, private _url: ConfigService, private link: ConfigService) { }
  rate;
  addr={rating:'',product_ID:'',user_ID:'',comment:''}

  public customerData(id) {
    if (!this.cache$) {
      this.cache$ = this.requestCustomer(id).pipe(
        shareReplay(CACHE_SIZE)
      );
    }

    return this.cache$;

   
  }
  private requestCustomer(id) {
    return this.http.get<any>(this.link.config_url+this._cusUrl+id);
  }
  // addReview(rating,id)
  // {
  //   this.addr.rating=rating;
  //   this.addr.product_ID=id;
  //   this.addr.user_ID=localStorage.getItem("user_id");
  //   let params = new HttpParams();
  //   params = params.append('rating', this.addr.rating);
  //   params = params.append('product_ID', this.addr.product_ID);
  //   params = params.append('user_ID', this.addr.user_ID);
  //   return this.http.post<any>(this.link.config_url + this._review, this.addr);

  // }
  addReview(rating,id)
  {
    this.addr.rating=rating;
    this.addr.product_ID=id;
    this.addr.user_ID=localStorage.getItem("user_id");
    let params = new HttpParams();
    params = params.append('rating', this.addr.rating);
    params = params.append('product_ID', this.addr.product_ID);
    params = params.append('user_ID', this.addr.user_ID);
    return this.http.post<any>(this.link.config_url + this._review, this.addr);

  }
  addReviewComment(rating,id,cmt)
  {
    this.addr.rating=rating;
    this.addr.product_ID=id;
    this.addr.comment=cmt;
    this.addr.user_ID=localStorage.getItem("user_id");
    let params = new HttpParams();
    params = params.append('rating', this.addr.rating);
    params = params.append('product_ID', this.addr.product_ID);
    params = params.append('user_ID', this.addr.user_ID);
    return this.http.post<any>(this.link.config_url + this._review, this.addr);

  }
  singleUserRating(pid,userid)
  {
    let dlink=this.link.config_url + this._userreview+'pid='+pid+'&'+'userid='+userid;
    let abc= this.http.get("https://admin.flublet.com/client_api/product_rate_user?pid=1&userid=2");
    return this.http.get<any>(this.link.config_url+this._userreview+'pid='+pid+'&'+'userid='+userid)
   
    // /product_rate_user?pid=21&userid=5
  }
}

