import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CategoryService } from '../services/category.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  category: any;
  constructor(
    private _category: CategoryService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this._category.getCategories().subscribe((data) => {
      this.category = JSON.parse(JSON.stringify(data));
    });
  }

}
