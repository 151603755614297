import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CartService } from '../services/cart.service';
import { ConfigService } from '../services/config.service';
import { ProductsService } from '../services/products.service';


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  public filter: string;
  searchText;
  first_time=true;
    countsub=0;
  category;
  search_key;
  isItemAvailable = false;
       items = [];
       product=[];
  searchTerm;
  
  
  productbefore=[];
  private sub:any;
   p_id;
   name;
   cart:any;
   cart_item_id=[];
   pincode;
   oder_details="";
   orderId="";
  
  added;
  addedno;
  addBtn;
  addedBtn;
  updatedqty = 1;
  img_link = this._config.config_url.slice(0, -1);
    constructor(
      private _config: ConfigService,
      private _router: Router,
      public _product:ProductsService,
     private route: ActivatedRoute,
     private router:Router,
     private _cart:CartService) { }
  
   
  
    ngOnInit() {

      this._product.observableEx.subscribe(res=>{
        this.searchText=res;
      })
      this.sub = this.route.params.subscribe(params => {
        this.p_id = params['id']; 
      
        this._product.getAllProducts().subscribe((data)=>{
          this.product=JSON.parse(JSON.stringify(data));
        
      })
     });
     this.cart_item_id = this._cart.getAddedItems();
     if (this.cart_item_id == null) {
       this.cart_item_id = [];
     }
  
      
      this.category = JSON.parse(localStorage.getItem('category'));
      this._product.getAllProducts().subscribe((data)=>{
         let pro=JSON.parse(JSON.stringify(data));
         this.product=JSON.parse(JSON.stringify(data));
      
    });
  
  
  
    
    }

  
    addQty(product) {
  
      if (localStorage.getItem("user_id") === null) {
        this.updatedqty = this._cart.addtoCartQty(product);
        // alert("item added to cart" + qty);
      }
      else {
        this.updatedqty = this._cart.addtoCartQty(product);
        this._cart.addtoCartLoggedInQty(product).subscribe((data) => {
        if(localStorage.getItem('order_id')==null)
        {
          this.oder_details=JSON.parse(JSON.stringify(data));
          localStorage.setItem('order_id', data[0].id);
        }
          
        
        });
      }
    
    
    
    }
  
    removeQty(product) {
      if(this.getCount(product)>1)
      {
  
      if (localStorage.getItem("user_id") === null) {
        this.updatedqty = this._cart.removetoCartQty(product);
    
      }
      else {
        this.updatedqty = this._cart.removetoCartQty(product);
        this._cart.addtoCartLoggedIn(product).subscribe((data) => {
          if(localStorage.getItem('order_id')==null)
          {
            this.oder_details=JSON.stringify(data);
            localStorage.setItem('order_id', data[0].id);
          }
          // this.category=JSON.parse(JSON.stringify(data));
        });
      }
    
      }
      else
      {
        let storageProducts = JSON.parse(localStorage.getItem('products'));
      if(storageProducts.filter(product1 => product1.productId == product.id))
      {
      let removeproget = storageProducts.filter(product1 => product1.productId == product.id);
      let myId=removeproget[0].order_detail_id;
      this._cart.removeCartLogin(myId).subscribe((data) => { });
      this.removeProduct(product);
      }
      }
    
    }
    removeProduct(product) {
      // debugger;
      if (localStorage.getItem('user_id')) {
        let storageProducts = JSON.parse(localStorage.getItem('products'));
        let removeproget = storageProducts.filter(product1 => product1.productId == product.id);
        let myId=removeproget[0].order_detail_id;
        // for (let i = 0; i < JSON.parse(localStorage.getItem('orderDetailsId')).length; i++) {
        // 	if (JSON.parse(localStorage.getItem('products')).productId == JSON.parse(localStorage.getItem('orderDetailsId'))[i].orderPid) {
        // 		myId = JSON.parse(localStorage.getItem('orderDetailsId'))[i].orderDetailsId;
        // 	}
        // }
  
        this._cart.removeCartLogin(myId).subscribe((data) => { });
  
      }
      this._cart.removeCartFromHome(product).subscribe((data) => {
        // let currentUrl = this.router.url;
        // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        // this.router.onSameUrlNavigation = 'reload';
        // this.router.navigate([currentUrl]);
        this.ngOnInit();
        let d = data;
        // debugger;
        // this.cart=JSON.parse(JSON.stringify(data));
        // this.count=JSON.parse(localStorage.products).length;
        
        
      });
      this._cart.getCartCountSub().subscribe((data) => {
  
        this.countsub = JSON.parse(JSON.stringify(data));
      });
      this.router.navigate([""]).then(() => {
        window.location.reload();
      });
      this.router.navigate(['']);
    
    }
  
    check_availability(pin_array) {
      
      var arr = [];
      for (var i = 0; i < pin_array.length; i++) {
        arr.push(pin_array[i].pincode);
      }
      if ((arr.indexOf(this.pincode) > -1)) {
        return true;
      }
      else {
        return false;
      }
      
    
    }
  
    filterItems() {
  
      return this.product.filter(product => {
        return this.product['product_name'].toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1;
       
      });
  
  
    }
    initializeItems(){
      this._product.getAllProducts().subscribe((data)=>{
        this.product=JSON.parse(JSON.stringify(data));
        this.items = this.product;
    })
    
  }
  
  getItems(ev: any) {
    
   
      // Reset items back to all of the items
      this.initializeItems();
  
      // set val to the value of the searchbar
      const val = ev.target.value;
  
      // if the value is an empty string don't filter the items
      if (val && val.trim() !== '') {
          this.isItemAvailable = true;
          this.items=JSON.parse(JSON.stringify(this.items));
          this.items = this.items.filter((item) => {
              return (item.product_name.toLowerCase().indexOf(val.toLowerCase()) > -1);
          })
      } else {
          this.isItemAvailable = false;
      }
  
      if(this.isItemAvailable==true)
      {
        this.first_time=false;
      }
  }
  
  getCount(pro)
      {
        if(localStorage.getItem("products"))
        {
        let sel = JSON.parse(localStorage.getItem("products")).filter(
          (products) => products.productId === pro.id
        );
        if(sel!=""&&sel!=null)
        {
          return sel[0].qty;
        }
        else
        {
          return 0;
        }
      }
      else
        {
          return 0;
        }
      }
  
  
      check_cartStatus(id)
      {
        if(this.cart_item_id.includes(id))
        {
          
          return true;
        }
        else
        {
          
          return false;
        }
      }

      addWishlist(product)
      {
        // alert("called me");
        if(this._cart.addtoWishlist(product,0,0)==0)
          {
            // alert('Item already in your wishlist');
            let msg="Item already in your wishlist";
            document.getElementById("snackbar").innerHTML=msg;
            var x = document.getElementById("snackbar");
            x.className = "show";
           setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
          }
        else 
        // (this._cart.addtoWishlist(product,0)==1)
          {
            let msg="Product Added to wishlist"
            this.addBtn = "1";
            document.getElementById("snackbar").innerHTML=msg;
            var x = document.getElementById("snackbar");
            x.className = "show";
           setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
          }
      }
  
  }
  
    
  
  
  