<div>
    <div class="container-fluid">

        <div class="row">
            <owl-carousel *ngIf="isLoadedBanner" id="sld" [options]="{items: 1, dots: true, navigation: true,autoplay:5000,loop:true }" [items]="product" [carouselClasses]="['owl-theme', 'row', 'sliding']">
                <div class="col-lg-12 slider_ed">
                    <article class="single_blog">
                        <figure>
                            <div class="blog_thumb" *ngIf="lbanner1_slider1[0]">
                                <a *ngIf="lbanner1_slider1[0].parent"><img [src]="img_link+lbanner1_slider1[0].category_image" alt="" [routerLink]="['/category',lbanner1_slider1[0].parent.id,lbanner1_slider1[0].parent.category_name]"></a>
                                <a *ngIf="!lbanner1_slider1[0].parent"><img [src]="img_link+lbanner1_slider1[0].category_image" alt=""></a>
                            </div>
                        </figure>
                    </article>
                </div>
                <div class="col-lg-12 slider_ed">
                    <article class="single_blog">
                        <figure>
                            <div class="blog_thumb" *ngIf="lbanner1_slider2[0]">
                                <a *ngIf="lbanner1_slider2[0].parent"><img [src]="img_link+lbanner1_slider2[0].category_image" alt="" [routerLink]="['/category',lbanner1_slider2[0].parent.id,lbanner1_slider2[0].parent.category_name]"></a>
                                <a *ngIf="!lbanner1_slider2[0].parent"><img [src]="img_link+lbanner1_slider2[0].category_image" alt=""></a>
                            </div>
                        </figure>
                    </article>
                </div>
                <div class="col-lg-12 slider_ed">
                    <article class="single_blog">
                        <figure>
                            <div class="blog_thumb" *ngIf="lbanner1_slider3[0]">
                                <a *ngIf="lbanner1_slider3[0].parent"><img [src]="img_link+lbanner1_slider3[0].category_image" alt="" [routerLink]="['/category',lbanner1_slider3[0].parent.id,lbanner1_slider3[0].parent.category_name]"></a>
                                <a *ngIf="!lbanner1_slider3[0].parent"><img [src]="img_link+lbanner1_slider3[0].category_image" alt=""></a>
                            </div>
                        </figure>
                    </article>
                </div>
            </owl-carousel>
            <div class="abc" id="snackbar"></div>
            <div class="col-md-12">
                <div class="container csheader">
                    <header class="jsx-3400163498">
                        <h3 class="jsx-3400163498">Recommended For You</h3>
                    </header>
                </div>
                <!-- new block -->

                <div class="row product_row">
                    <div class="col-md-2  slider_ed1" *ngFor="let pro of product | slice:0:10">
                        <div class="thumb-wrapper">
                            <div class="img-box">
                                <a [routerLink]="[ '/product-details',pro.id,pro.product_name ] ">
                                    <img [src]="img_link+pro.product_image1 " class="img-responsive img-fluid imgg" alt="">
                                </a>
                            </div>
                            <div class="jsx-2061857635 discountFulfilmentRow">
                                <div class="jsx-2061857635 discountFulfilmentContent">
                                    <div class="jsx-2061857635 fulfilmentContainer">
                                        <div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="thumb-content">
                                <span class="items-names" [routerLink]="[ '/product-details',pro.id,pro.product_name ] ">{{ pro.product_name }}</span>
                                <div class="jsx-193278340 container" *ngIf="getWish(pro)<1">
                                    <img class="wishlist" (click)="addWishlist(pro)" src="../../assets/img/wishblack.png" />

                                </div>
                                <div class="jsx-193278340 container" *ngIf="getWish(pro)>0">
                                    <img class="wishlist" (click)="addWishlist(pro)" src="../../assets/img/wishred.png" />

                                </div>
                                <p class="jsx-4251264678 price">
                                    <span class="jsx-4251264678 sellingPrice">
                                <span>
                                    <span class="currency medium">₹</span>
                                    <span class="value">{{ pro.sales_rate }}</span></span>
                                    </span>
                                    <span class="jsx-4251264678 preReductionPrice">
                                <span>
                                    <span class="currency null"> ₹</span>
                                    <span class="value">{{ pro.price }}</span>
                                    </span>

                                    </span>
                                    <span class="jsx-2467480612 discountTag"><span
                                class="jsx-2467480612 bg"></span><span class="jsx-2467480612 ">{{ pro.offer }}% Off</span></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- new block -->



                <!-- <owl-carousel class="rec" [options]="{items: 6, dots: true, navigation: true}" [items]="product" [carouselClasses]="['owl-theme', 'row', 'sliding']">
                <div class="col-lg-12 slider_ed1" *ngFor="let pro of product">
                    <div class="thumb-wrapper">
                        <div class="img-box">
                            <img [src]="img_link+pro.product_image1 " [routerLink]="[ '/product-details',pro.id,pro.product_name ] " class="img-responsive img-fluid imgg" alt="">
                        </div>
                        <div class="jsx-2061857635 discountFulfilmentRow">
                            <div class="jsx-2061857635 discountFulfilmentContent">
                                <div class="jsx-2061857635 fulfilmentContainer">
                                    <div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="thumb-content">
                            <span class="items-names" [routerLink]="[ '/product-details',pro.id,pro.product_name ] ">{{ pro.product_name }}</span>
                            <div class="jsx-193278340 container" *ngIf="getWish(pro)<1">
                                <img class="wishlist" (click)="addWishlist(pro)" src="../../assets/img/wishblack.png" />

                            </div>
                            <div class="jsx-193278340 container" *ngIf="getWish(pro)>0">
                                <img class="wishlist" (click)="addWishlist(pro)" src="../../assets/img/wishred.png" />

                            </div>
                            <p class="jsx-4251264678 price">
                                <span class="jsx-4251264678 sellingPrice">
                                    <span>
                                        <span class="currency medium">₹</span>
                                <span class="value">{{ pro.sales_rate }}</span></span>
                                </span>
                                <span class="jsx-4251264678 preReductionPrice">
                                    <span>
                                        <span class="currency null"> ₹</span>
                                <span class="value">{{ pro.price }}</span>
                                </span>

                                </span>
                                <span class="jsx-2467480612 discountTag"><span
                                    class="jsx-2467480612 bg"></span><span class="jsx-2467480612 ">{{ pro.offer }}% Off</span></span>
                            </p>
                        </div>
                    </div>
                </div>
            </owl-carousel> -->
            </div>
        </div>
    </div>


    <section class="ad">
        <div class="container-fluid">
            <div class="row" *ngIf="lbanner2[0]">
                <div class="col-sm-12" *ngIf="lbanner2[0].parent">
                    <img [src]="img_link+lbanner2[0].category_image" width="100%" [routerLink]="['/category',lbanner2[0].parent.id,lbanner2[0].parent.category_name]">
                </div>
                <div class="col-sm-12" *ngIf="!lbanner2[0].parent">
                    <img [src]="img_link+lbanner2[0].category_image" width="100%">
                </div>
            </div>
        </div>
    </section>
    <div style="background:#ffffff" class="jsx-1481647590 componentArea multiComponentArea">
        <div style="padding-top:10px;padding-bottom:35px" class="jsx-1481647590 componentRow bannerModule">
            <div class="jsx-1481647590 siteWidthContainer">
                <div class="jsx-16778177 container-fluid">
                    <div class="jsx-16778177 bannerWrapper">
                        <div style="flex:0 0 100%" class="jsx-16778177 bannerContainer bannerModule ">
                            <div class="jsx-1280509355">
                                <div class="jsx-1280509355 banner bannerModule featureBanner">
                                    <div class="jsx-2714670158 container undefined loaded headtext">
                                        <div class="jsx-2714670158 mediaContainer ">
                                            <header _ngcontent-kvq-c21="" class="jsx-3400163498">
                                                <h3 _ngcontent-kvq-c21="" class="jsx-3400163498">Top Categories</h3>
                                            </header>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="padding-top:15px;padding-bottom:35px" class="jsx-1481647590 forback componentRow bannerModule">
            <div class="jsx-1481647590 siteWidthContainer">
                <div class="jsx-16778177 container-fluid">
                    <div class="jsx-16778177 bannerWrapper">
                        <div style="flex:0 0 20%" class="jsx-16778177 bannerContainer bannerModule " *ngFor="let cat of category">
                            <div class="jsx-1280509355">
                                <a [routerLink]="[ '/category',cat.id,cat.category_name ] ">
                                    <div class="jsx-1280509355 banner bannerModule standardBanner">
                                        <div class="jsx-2714670158 container undefined loaded " style="padding-bottom: 122.753%;">
                                            <div class="jsx-2714670158 mediaContainer catsec">
                                                <img [src]="img_link+cat.category_image" alt="{{ cat.category_name }}" class="jsx-1280509355 imgc">
                                                <div class="cat_name">{{ cat.category_name }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <section class="ad">
        <div class="container-fluid">
            <div class="row" *ngIf="lbanner3[0]">

                <div class="col-sm-12" *ngIf="lbanner3[0].parent">
                    <img [src]="img_link+lbanner3[0].category_image" width="100%" [routerLink]="['/category',lbanner3[0].parent.id,lbanner3[0].parent.category_name]">
                </div>
                <div class="col-sm-12" *ngIf="!lbanner3[0].parent">
                    <img [src]="img_link+lbanner3[0].category_image" width="100%">
                </div>

            </div>
        </div>
    </section>
    <div class="col-md-12">
        <div class="container csheader">
            <header class="jsx-3400163498">
                <h3 class="jsx-3400163498">Explore Top Brands</h3>
            </header>
        </div>

        <owl-carousel *ngIf="isLoadedProduct" class="rec" [options]="{items: 6, dots: true,navigation: true}" [items]="product" [carouselClasses]="['owl-theme', 'row', 'sliding']">
            <div class="col-lg-12 slider_ed1" *ngFor="let pro of product | reverse">
                <div class="thumb-wrapper">
                    <div class="img-box">
                        <img [src]="img_link+pro.product_image1 " [routerLink]="[ '/product-details',pro.id,pro.product_name ] " class="img-responsive img-fluid imgg" alt="">
                    </div>
                    <div class="jsx-2061857635 discountFulfilmentRow">
                        <div class="jsx-2061857635 discountFulfilmentContent">
                            <div class="jsx-2061857635 fulfilmentContainer">
                                <div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="thumb-content">
                        <span class="items-names" [routerLink]="[ '/product-details',pro.id,pro.product_name ] ">{{ pro.product_name }}</span>

                        <div class="jsx-193278340 container" *ngIf="getWish(pro)<1">
                            <img class="wishlist" (click)="addWishlist(pro)" src="../../assets/img/wishblack.png" />

                        </div>
                        <div class="jsx-193278340 container" *ngIf="getWish(pro)>0">
                            <img class="wishlist" (click)="addWishlist(pro)" src="../../assets/img/wishred.png" />

                        </div>
                        <p class="jsx-4251264678 price">
                            <span class="jsx-4251264678 sellingPrice">
                            <span>
                                <span class="currency medium">₹</span>
                            <span class="value">{{ pro.sales_rate }}</span></span>
                            </span>
                            <span class="jsx-4251264678 preReductionPrice">
                            <span>
                                <span class="currency null"> ₹</span>
                            <span class="value">{{ pro.price }}</span>
                            </span>

                            </span>
                            <span class="jsx-2467480612 discountTag"><span
                            class="jsx-2467480612 bg"></span><span class="jsx-2467480612 ">{{ pro.offer }}% Off</span></span>
                        </p>
                    </div>
                </div>
            </div>
        </owl-carousel>
    </div>

    <section class="ad">
        <div class="container-fluid">
            <div class="row" *ngIf="lbanner4[0]">
                <div class="col-sm-12" *ngIf="lbanner4[0].parent">
                    <img [src]="img_link+lbanner4[0].category_image" width="100%" [routerLink]="['/category',lbanner4[0].parent.id,lbanner4[0].parent.category_name]">
                </div>
                <div class="col-sm-12" *ngIf="!lbanner4[0].parent">
                    <img [src]="img_link+lbanner4[0].category_image" width="100%">
                </div>
            </div>
        </div>
    </section>
    <section class="ad doublebanner">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-6" *ngIf="lbanner5[0]">
                    <div *ngIf="lbanner5[0].parent">
                        <img [src]="img_link+lbanner5[0].category_image" width="100%" [routerLink]="['/category',lbanner5[0].parent.id,lbanner5[0].parent.category_name]">
                    </div>
                    <div *ngIf="!lbanner5[0].parent">
                        <img [src]="img_link+lbanner5[0].category_image" width="100%">
                    </div>
                </div>
                <div class="col-sm-6" *ngIf="lbanner6[0]">
                    <div *ngIf="lbanner6[0].parent">
                        <img [src]="img_link+lbanner6[0].category_image" width="100%" [routerLink]="['/category',lbanner6[0].parent.id,lbanner6[0].parent.category_name]">
                    </div>
                    <div *ngIf="!lbanner6[0].parent">
                        <img [src]="img_link+lbanner6[0].category_image" width="100%">
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="ad">
        <div class="container-fluid">
            <div class="row" *ngIf="lbanner7[0]">
                <div class="col-sm-12" *ngIf="lbanner7[0].parent">
                    <img [src]="img_link+lbanner7[0].category_image" width="100%" [routerLink]="['/category',lbanner7[0].parent.id,lbanner7[0].parent.category_name]">
                </div>
                <div class="col-sm-12" *ngIf="!lbanner7[0].parent">
                    <img [src]="img_link+lbanner7[0].category_image" width="100%">
                </div>
            </div>
        </div>
    </section>
</div>