import { Injectable } from '@angular/core';
import{ HttpClient,HttpBackend } from '@angular/common/http';
import { HttpParams } from "@angular/common/http";
import { ConfigService } from './config.service';
import { Observable } from 'rxjs';
import { shareReplay, map } from 'rxjs/operators';
const CACHE_SIZE = 1;
@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  pcat="";
  private cacheCategory$: Observable<any>;
  private cacheBanner$: Observable<any>;
  private http: HttpClient;
  constructor(handler: HttpBackend,private _url:ConfigService) { 
    this.http = new HttpClient(handler);
  }
  // getCategories()
  // {
  //   return this.http.get(this._url.config_url+'client_api/categorylist');
  // }
  public getCategories() {
    if (!this.cacheCategory$) {
      this.cacheCategory$ = this.requestCategory().pipe(
        shareReplay(CACHE_SIZE)
      );
    }

    return this.cacheCategory$;  
  }
  private requestCategory() {
    return this.http.get<any>(this._url.config_url+'client_api/categorylist');
  }
  public getAllBanners() {
    // if (!this.cacheBanner$) {
    //   this.cacheBanner$ = this.requestBanner().pipe(
    //     shareReplay(CACHE_SIZE)
    //   );
    // }

    // return this.cacheBanner$;  
    return this.http.get<any>(this._url.config_url+'client_api/bannerlist');
  }
  private requestBanner() {
    return this.http.get<any>(this._url.config_url+'client_api/bannerlist');
  }
  getSubCategories(id)
  {
 return this.http.get(this._url.config_url+'client_api/retrievecategory/'+id);
  }
  getSingleCategory(id)
  {
 return this.http.get(this._url.config_url+'client_api/retrievesubcategory/'+id);
  }
}
