<section class="bill">
    <div class="container mt-8">
        <form action="#" class="form">
            <div class="row gutter-lg">
                <div class="col-lg-7 mb-6">
                    <h3 class="title title-simple text-left">Billing Details</h3>
                    <div class="row">
                        <div class="col-sm-6">
                            <div id="paymentsel" class="card " (click)="setRazor()">
                                <div class="card-body">
                                    <div class="text-center">
                                        <h6><img src="../../assets/img/Razorpay-the-new-epayment-that-will-break-everything-in-2019.png" width="15%"> Razorpay</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- {{ codavailable }} -->
                        <div class="col-sm-6" *ngIf="codavailable">
                            <div id="paymentselcod" class="card " (click)="setCOD()">
                                <div class="card-body">
                                    <div class="text-center">
                                        <h6><i class="fa fa-credit-card-alt cardicon" aria-hidden="true"></i> Pay with cash</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 nocod" *ngIf="!codavailable">
                            <div id="paymentselcod" class="card ">
                                <div class="card-body">
                                    <div class="text-center">
                                        <h6><i class="fa fa-credit-card-alt cardicon" aria-hidden="true"></i> Pay with cash</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12">
                            <div class="jsx-598876803 optionWrapper  ">
                                <div class="jsx-598876803 contentWrapper"><img src="https://k.nooncdn.com/s/app/2019/noon-checkout/dd264daf2d711456256f29b6233c4547742cb459/static/images/option_empty.svg" class="jsx-598876803 check">
                                    <div class="jsx-598876803 content">
                                        <div class="jsx-942564997 wrapper"><img src="https://k.nooncdn.com/s/app/2019/noon-checkout/dd264daf2d711456256f29b6233c4547742cb459/static/images/shipmentMethod_dd.svg" class="jsx-942564997 icon">
                                            <div class="jsx-942564997 title">Leave At My Door</div>
                                        </div>
                                        <div class="jsx-598876803 description">Your delivery will be left at your door and a photo will be sent to you as confirmation. Valid on prepaid orders only
                                        </div>
                                    </div>
                                </div>
                                <div class="jsx-598876803 price">Free</div>
                            </div>
                        </div>

                        <BR/>
                        <span id="payerror"></span>
                        <div class="col-sm-12">
                            <div class="pok text-center" (click)="pay()">
                                <div class="card-body">
                                    Place Order
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <aside class="col-lg-5 sticky-sidebar-wrapper">
                    <div class="pin-wrapper" style="height: 1035.4px;">
                        <div class="sticky-sidebar" data-sticky-options="{'bottom': 50}" style="border-bottom: 0px none rgb(102, 102, 102); width: 474.163px;">
                            <h3 class="title title-simple text-left">Your Order</h3>
                            <div class="summary mb-4">
                                <table class="order-table">
                                    <thead>
                                        <tr>
                                            <th>Product</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let crt of cart; let i=index">
                                            <td class="product-name">{{ crt.product_name}} <strong class="product-quantity">×&nbsp;{{ getCount(crt) }}</strong></td>
                                            <td class="product-total">₹ {{ crt.price }}</td>
                                            <td>{{ getCODData(crt) }}</td>
                                        </tr>


                                        <tr class="summary-subtotal">
                                            <td>
                                                <h4 class="summary-subtitle">Subtotal</h4>
                                            </td>
                                            <td class="summary-subtotal-price">₹ {{ sum }}
                                            </td>
                                        </tr>
                                        <tr class="summary-subtotal">
                                            <td>
                                                <h4 class="summary-subtitle">Shipping Charge</h4>
                                            </td>
                                            <td class="summary-subtotal-price">₹ {{ countdelivery }}
                                            </td>
                                        </tr>

                                        <tr class="summary-subtotal">
                                            <td>
                                                <h4 class="summary-subtitle">Total</h4>
                                            </td>
                                            <td>
                                                <p class="summary-total-price">₹ {{ sum+countdelivery }}</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>


                            </div>
                        </div>
                    </div>
                </aside>
            </div>
        </form>
    </div>
</section>