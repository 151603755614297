import { Injectable } from '@angular/core';
import{ HttpClient,HttpBackend  } from '@angular/common/http';
import { HttpParams } from "@angular/common/http";
import { ConfigService } from './config.service';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { shareReplay, map } from 'rxjs/operators';
const CACHE_SIZE = 1;

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  public responseCache = new Map();
  private cache$: Observable<any>;
  private cacheSingleProduct$: Observable<any>;
  observableEx = new Subject();
  observableEx$ = this.observableEx.asObservable();
  private http: HttpClient;
  constructor(handler: HttpBackend,private _url:ConfigService) {
    this.http = new HttpClient(handler);
  }
  getProducts(loc)
  {

    return this.http.get(this._url.config_url+'client_api/productfilterbycatid/'+loc);

  }
  getCatProducts(loc)
  {

    return this.http.get(this._url.config_url+'client_api/retrievesub/'+loc);

  }

  public getAllProducts() {
    // const productsFromCache = this.responseCache.get(URL);
    // if (productsFromCache) {
    //   return of(productsFromCache);
    // }
    // const response = this.http.get<any>(this._url.config_url+'client_api/listofproducts').pipe(shareReplay(1));
    // response.subscribe(products => this.responseCache.set(this._url.config_url+'client_api/listofproducts', products));
    // return response;
    if (!this.cache$) {
      this.cache$ = this.requestPro().pipe(
        shareReplay(CACHE_SIZE)
      );
    }

    return this.cache$;


  }
  private requestPro() {
    return this.http.get<any>(this._url.config_url+'client_api/listofproducts');
  }
  // getAllProducts()
  // {

  //   return this.http.get(this._url.config_url+'client_api/listofproducts');

  // }
  getSingleProducts(id)
  {
    return this.http.get(this._url.config_url+'client_api/retrieveproduct/'+id);
  }
  getSizeChart(id)
  {
    return this.http.get(this._url.config_url+'client_api/sizechart/'+id);
  }
  getSearchProducts(id)
  {
    return this.http.get(this._url.config_url+'client_api/searchproduct/'+id);
  }
  getSingleProductReview(id)
  {
    return this.http.get(this._url.config_url+'client_api/product_rating?pid='+id)
  }
}
