<div class="container-fluid">
    <div class="row">
        <aside class="col-lg-12">
            <div class="heading">
                <h4>Return & Exchange Policy</h4>
            </div>
            <div class="card">

                <p>Flublet offers a very flexible Return & Exchange policy. If customer is not satisfied with the order,customer can easily return/exchange the product . However, there are a few basic rules to be eligible: </p>
                <ul>
                    <li>Product has to be in original & unused ,All original tags should not be removed &The original invoice should be returned along with the product</li>
                    <li>Product should be received by us in sealed condition</li>
                    <li>Request has to be raised within 5 days of delivery through website or by Mail :- <a href="flubletbusiness@gmail.com">flubletbusiness@gmail.com</a> . </li>
                </ul>
                <p><b>NOTE:</b> For special category Products (Refer retun policy of each product) we support 2 days return policy. In case you have any issue with the product received, the same must be informed to us through Returns page or by mail with
                    in 2 days of order receipt. Kindly mention your order ID in Mail.</p>
                <ul>
                    <li>We accept the request if there is a mismatch in quality, size, colour or design or in case an item is missing/wrong in a combo order</li>
                    <li>We will issue selling price of the product after deducting shipping charges if any.(full refund will issue if Free shipping orders.)</li>
                    <li>Please note the shipping charge and the COD convenience fee (applicable for COD orders) will be non-refundable. If reverse pick up will be arranged by us, there is an additional charge of Rs 50/- and Rs.50 for every additional item.</li>
                </ul>
                <p><b>***NOTE: </b> We do not offer returns on Some products which are alredy mentioned under the product Page of each product. (refer Return policy)*</p>
                <h6>WHEN WILL I GET MY REFUND?</h6>
                <hr>
                <p>It will take 3-5 working days to process your refund once the returned product has been received back and approved by our quality check team. We will issue Refund amount after deducting shipping charges & COD convience Fee if any. For
                    any other queries, please feel free to contact us on <a href="flubletbusiness@gmail.com">flubletbusiness@gmail.com</a></p>


            </div>
        </aside>

    </div>
</div>